import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import {
  CancelButton,
  PrimaryLoadingButton,
} from "../../css components/Button";

export default function SubmitButton({ formikProps, setAddSample }) {
  const navigate = useNavigate();

  return (
    <Fragment>
      <CancelButton
        onClick={() => {
          navigate(-1);
          formikProps.handleReset();
        }}
        color="primary"
        variant="outlined"
      >
        Back
      </CancelButton>

      <PrimaryLoadingButton
        disabled={
          formikProps.dirty
            ? formikProps.isSubmitting || !formikProps.isValid
            : !formikProps.dirty
        }
        onClick={formikProps.handleSubmit}
        color="primary"
        variant="contained"
      >
        Create Template
      </PrimaryLoadingButton>
    </Fragment>
  );
}
