import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";

// Layouts
import BlankLayout from "../layouts/BlankLayout";
import AuthLayout from "../layouts/AuthLayout";
import EmptyLayout from "../layouts/EmptyLayout";
import DashboardLayout from "../layouts/DashboardLayout";

import dashboardLayoutRoutes from "./router components/dashboardLayoutRoutes";
import authLayoutRoutes from "./router components/authLayoutRoutes";
import emptyLayoutRoutes from "./router components/emptyLayoutRoutes";
import blankLayoutRoutes from "./router components/blankLayoutRoutes";

import pages from "../constants/pages";
import RoleBaseNavigation from "./RoleBaseNavigation";

export default function Router() {
  const { dashboardState } = useSelector((state) => state.memberDetails);

  const allowedPaths = [
    "/cam",
    pages.signIn.route,
    pages.signUp.route,
    pages.welcomePage.route,
    pages.brandDetailEmpty.route,
    pages.permissionDenied.route,
    pages.createWelcomePage.route,
    pages.companyDetailsEmpty.route,
    pages.resetEmailPasswordPage.route,
    pages.brandDetailCreateEmpty.route,
    pages.companyDetailsCreateEmpty.route,
    pages.createWelcomePage.route,
    pages.verifyAccount.route + ":code",
    pages.forgotPasswordPage.route + ":code",
  ];

  const allRoutes = useRef([
    {
      ID: "dashboardLayoutRoutes",
      path: "/",
      layout: DashboardLayout,
      children: allChildrenRoutes(dashboardLayoutRoutes),
    },

    {
      ID: "authLayoutRoutes",
      path: "/",
      layout: AuthLayout,
      children: allChildrenRoutes(authLayoutRoutes),
    },

    {
      ID: "emptyLayoutRoutes",
      path: "/",
      layout: EmptyLayout,
      children: allChildrenRoutes(emptyLayoutRoutes),
    },

    {
      ID: "blankLayoutRoutes",
      path: "/",
      layout: BlankLayout,
      children: allChildrenRoutes(blankLayoutRoutes),
    },
  ]);

  return (
    <Routes>
      {allRoutes.current.map(
        ({ ID: layoutID, path, layout: Layout, children }) =>
          layoutID && (
            <Route key={layoutID} path={path} element={<Layout />}>
              {children.map(
                ({
                  ID: pageID,
                  index,
                  path,
                  element: Element,
                  disableAuth,
                  role = [],
                }) =>
                  pageID && allowedPaths.includes(path) ? (
                    <Route
                      key={pageID}
                      index={!!index}
                      path={path}
                      element={
                        <Element pageID={pageID} disableAuth={!!disableAuth} />
                      }
                    />
                  ) : (
                    <Route
                      element={
                        <RoleBaseNavigation
                          role={dashboardState}
                          roles={role}
                        />
                      }
                    >
                      <Route
                        key={pageID}
                        index={!!index}
                        path={path}
                        element={
                          <Element
                            pageID={pageID}
                            disableAuth={!!disableAuth}
                          />
                        }
                      />
                    </Route>
                  )
              )}
            </Route>
          )
      )}
    </Routes>
  );
}

function allChildrenRoutes(parentObject) {
  const childrenArray = [];

  for (var key in parentObject) {
    childrenArray.push(parentObject[key]);
  }

  return childrenArray;
}
