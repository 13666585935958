import * as React from "react";
import Select from "react-select";
import { Grid } from "@mui/material";

export default function Categorize({ brands, setBrands, setSearchBrands }) {
  let options = [
    { value: "", label: "Brands", color: "#00EFC4" },
    { value: "3", label: "Promotion", color: "#34B53A" },
    { value: "2", label: "Monetization", color: "#02A0FC" },
  ];

  const style = {
    control: (base) => ({
      ...base,
      border: 0,
      height: "50px",
      p: "15px 18px",
      color: "#000000",
      fontWeight: 400,
      display: "flex",
      width: "inherit",
      fontsize: "18px",
      lineHeight: "20px",
      boxShadow: "none",
      alignItems: "center",
      borderRadius: "10px",
      justifyContent: "space-between",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: " #000000",
    }),
    singleValue: (styles, { data }) => ({
      ...styles,
      fontWeight: 600,
      color: data.color,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: data.color,
        fontWeight: isSelected ? 600 : 400,
        backgroundColor: "white",
        fontSize: "18px",
        lineHeight: "20px",
        mixBlendMode: "normal",
        cursor: isDisabled ? "not-allowed" : "default",
      };
    },
  };

  const handleTypeSelect = (e) => {
    setBrands(e.value);

    setSearchBrands("");
  };

  return (
    <Grid item xs={3}>
      <Select
        styles={style}
        options={options}
        onChange={handleTypeSelect}
        isSearchable={false}
        components={{
          IndicatorSeparator: () => null,
        }}
        value={options.filter(function (option) {
          return option.value === brands;
        })}
      />
    </Grid>
  );
}
