import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import FormDisplay from "./FormDisplay";
import { useAxios, useAlert } from "../../../hooks";
import { validationSchema, objectFromFormData } from "./brandDetailObject";
import pages from "../../../constants/pages";

export default function Form({ list, formData, editState, setEditState }) {
  const axios = useAxios();
  const navigate = useNavigate();
  const { alert } = useAlert();
  const dispatch = useDispatch();

  const businessEntityID =
    useSelector((state) => state.memberDetails?.businessEntityID) ||
    sessionStorage.getItem("businessEntityId");

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={(formData, { setSubmitting }) => {
        let url = "/user/brand";
        let method = "POST";

        if (formData.brandID) {
          url = "/user/brand/" + formData.brandID;
          method = "PUT";
        }

        axios({
          url: url,
          method: method,
          data: objectFromFormData(formData, businessEntityID),
          disableRedirect: true,
        })
          .then((res) => {
            if (res.status) {
              if (!formData.brandID) {
                sessionStorage.setItem("brandID", res.data?.brand_id);
                dispatch(
                  alert({
                    message: "Brand created successfully!",
                    type: "success",
                  })
                );

                navigate(pages.createWelcomePage.route);
              }

              if (!!formData.brandID) {
                dispatch(
                  alert({
                    message: "Brand updated successfully!",
                    type: "success",
                  })
                );
                setEditState(false);
              }
            }
            setSubmitting(false);
          })
          .catch((err) => {
            console.error(err);
          });
      }}
    >
      {(formikProps) => (
        <FormDisplay
          list={list}
          editState={editState}
          formikProps={formikProps}
          setEditState={setEditState}
        />
      )}
    </Formik>
  );
}
