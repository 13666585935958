import { Grid, FormControlLabel, Checkbox } from "@mui/material";

import { Label, Paper, Autocomplete, TextField } from "../../../components";
import { colors } from "../../css components/Style";

export default function FormOne({ formikProps, brands, editState }) {
  return (
    <Paper>
      <Grid item xs={10}>
        <Label
          title={"Brand Name"}
          formikProps={formikProps}
          required={true}
          name={"brand_id"}
        />
      </Grid>

      <Grid item xs={10}>
        {!!formikProps.values.connectionID ? (
          <TextField
            name="brandName"
            formikProps={formikProps}
            disabled={!!formikProps.values.connectionID}
          />
        ) : (
          <Autocomplete
            name="brandID"
            forcePopupIcon={!editState ? false : true}
            placeholder="Select Brand Name"
            defaults={{
              primaryKey: "brand_id",
              displayLabel: "brand_name",
            }}
            options={brands}
            disabled={!!formikProps.values.connectionID}
            onChange={(_, value, type) => handleChangeBrand(value, type)}
            formikProps={formikProps}
          />
        )}

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                disabled={!editState}
                onChange={(e) => {
                  formikProps.setValues((prev) => ({
                    ...prev,
                    csvUpload: e.target.checked,
                  }));
                }}
                checked={formikProps.values.csvUpload}
                sx={{
                  color: colors.info,
                  "&.Mui-checked": {
                    color: colors.info,
                  },
                }}
              />
            }
            label="Onboarding with CSV"
          />
        </Grid>
      </Grid>

      <Grid item xs={10}>
        <Label title={"IP/Domain"} />
      </Grid>

      <Grid item xs={10}>
        <TextField
          name="connectionUrl"
          placeholder="IP/Domain"
          disabled={!editState}
          formikProps={formikProps}
          inputProps={{ "aria-label": "Without label" }}
        />
      </Grid>

      <Grid item xs={10}>
        <Label title={"Port"} />
      </Grid>

      <Grid item xs={10}>
        <TextField
          name="port"
          placeholder="Port"
          disabled={!editState}
          formikProps={formikProps}
          inputProps={{ "aria-label": "Without label" }}
        />
      </Grid>

      <Grid item xs={10}>
        <Label title={"Token"} />
      </Grid>

      <Grid item xs={10}>
        <TextField
          multiline
          name="token"
          placeholder="Token"
          disabled={!editState}
          formikProps={formikProps}
          inputProps={{ "aria-label": "Without label" }}
        />
      </Grid>
    </Paper>
  );

  function handleChangeBrand(value, type) {
    if (type === "selectOption") {
      formikProps.setValues((prevVal) => ({
        ...prevVal,
        brandID: value.brand_id,
      }));
    } else {
      value = {
        brand_id: "",
        brand_name: "",
      };

      formikProps.setValues((prevVal) => ({
        ...prevVal,
        brandID: value.brandID,
      }));
    }
  }
}
