import React from "react";
import styled from "styled-components/macro";
import { Avatar, Grid, Typography } from "@mui/material";

import { FileUpload, Label, Paper, TextField } from "../../../components";

const MainHeading = styled(Typography)`
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  color: #02a0fc;
`;

const ImageAvatar = styled(Avatar)`
  border: 1px solid #999999;
  height: 120px;
  width: 120px;
  border-radius: 50%;
`;

export default function FormOne({ formikProps, editState }) {
  return (
    <React.Fragment>
      <Paper>
        <Grid item xs={12}>
          <MainHeading>Your Brand Profile</MainHeading>
        </Grid>

        <Grid item xs={12} textAlign="center" display="flex">
          <ImageAvatar src={formikProps.values.brandAvatar} />
        </Grid>

        <Grid item xs={12}>
          <Label title={"Brand Logo"} />
        </Grid>

        <Grid item xs={10}>
          <FileUpload
            required
            disabled={!editState}
            name="brandAvatar"
            formikProps={formikProps}
            placeholder="Select Brand Logo"
            allowedExtension=".jpg,.jpeg,.png,.gif"
            folderPath={"public/logo/" + new Date().getTime() + "_"}
          />
        </Grid>

        <Grid item xs={12}>
          <Label
            title={"Brand Name"}
            formikProps={formikProps}
            required={true}
            name={"brandName"}
          />
        </Grid>

        <Grid item xs={10}>
          <TextField
            disabled={!editState}
            formikProps={formikProps}
            placeholder="Brand Name"
            name="brandName"
            inputProps={{ "aria-label": "Without label" }}
          />
        </Grid>

        <Grid item xs={12}>
          <Label
            title="About"
            formikProps={formikProps}
            required={true}
            name={"about"}
          />
        </Grid>

        <Grid item xs={10}>
          <TextField
            disabled={!editState}
            formikProps={formikProps}
            placeholder="About"
            name="about"
            rows={5}
            multiline
            inputProps={{ "aria-label": "Without label" }}
          />
        </Grid>

        <Grid item xs={12}>
          <Label title={"Online Store"} />
        </Grid>

        <Grid item xs={10}>
          <TextField
            disabled={!editState}
            formikProps={formikProps}
            name="onlineStore"
            placeholder="Online Store"
            inputProps={{ "aria-label": "Without label" }}
          />
        </Grid>

        <Grid item xs={12}>
          <Label title={"Website"} />
        </Grid>

        <Grid item xs={10}>
          <TextField
            disabled={!editState}
            formikProps={formikProps}
            name="website"
            placeholder="Website"
            inputProps={{ "aria-label": "Without label" }}
          />
        </Grid>

        <Grid item xs={12}>
          <Label title="Monthly Active Users" />
        </Grid>

        <Grid item xs={10}>
          <TextField
            disabled={!editState}
            formikProps={formikProps}
            name="monthlyActiveUsers"
            placeholder="Monthly Active Users"
            inputProps={{ "aria-label": "Without label" }}
          />
        </Grid>
      </Paper>
    </React.Fragment>
  );
}
