import { TbExternalLink } from "react-icons/tb";
import { Grid, IconButton } from "@mui/material";

import { FileUpload, Label, Paper, TextField } from "../../../components";

export default function FormTwo({ formData }) {
  return (
    <Paper>
      <Grid item xs={12}>
        <Label title="Company Directors Name" />
      </Grid>

      <Grid item xs={10}>
        <TextField
          name="companyDirectorsName"
          placeholder="Company Directors Name"
          disabled
          value={formData.companyDirectorsName}
          inputProps={{ "aria-label": "Without label" }}
        />
      </Grid>

      <Grid item xs={12}>
        <Label title="Company PAN" />
      </Grid>

      <Grid item xs={10}>
        <FileUpload
          name="companyPanCertificate"
          placeholder="Company PAN"
          disabled
          value={formData.companyPanCertificate}
          allowedExtension=".jpg,.jpeg,.png,.gif"
          folderPath={"company_document/" + new Date().getTime() + "_"}
          inputProps={{ "aria-label": "Without label" }}
          InputProps={{
            endAdornment: !!formData.companyPanCertificate && (
              <IconButton
                onClick={() => linkOpen(formData.companyPanCertificate)}
              >
                <TbExternalLink />
              </IconButton>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Label title="Company GST Number" />
      </Grid>

      <Grid item xs={10}>
        <FileUpload
          name="gstCertificate"
          placeholder="Company GST Number"
          disabled
          value={formData.gstCertificate}
          allowedExtension=".jpg,.jpeg,.png,.gif"
          folderPath={"company_document/" + new Date().getTime() + "_"}
          inputProps={{ "aria-label": "Without label" }}
          InputProps={{
            endAdornment: !!formData.gstCertificate && (
              <IconButton
                onClick={() =>
                  linkOpen(
                    <IconButton
                      onClick={() => linkOpen(formData.gstCertificate)}
                    >
                      <TbExternalLink />
                    </IconButton>
                  )
                }
              >
                <TbExternalLink />
              </IconButton>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Label title="Company Directors PAN Number" />
      </Grid>

      <Grid item xs={10}>
        <FileUpload
          name="directorPanCertificate"
          placeholder="Company Directors PAN Number"
          disabled
          value={formData.directorPanCertificate}
          allowedExtension=".jpg,.jpeg,.png,.gif"
          folderPath={"company_document/" + new Date().getTime() + "_"}
          inputProps={{ "aria-label": "Without label" }}
          InputProps={{
            endAdornment: !!formData.directorPanCertificate && (
              <IconButton
                onClick={() => linkOpen(formData.directorPanCertificate)}
              >
                <TbExternalLink />
              </IconButton>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Label title="Company Director Aadhar Number" />
      </Grid>

      <Grid item xs={10}>
        <FileUpload
          name="directorAadharCertificate"
          placeholder="Company Directors Aadhar Number"
          disabled
          value={formData.directorAadharCertificate}
          allowedExtension=".jpg,.jpeg,.png,.gif"
          folderPath={"company_document/" + new Date().getTime() + "_"}
          inputProps={{ "aria-label": "Without label" }}
          InputProps={{
            endAdornment: !!formData.directorAadharCertificate && (
              <IconButton
                onClick={() => linkOpen(formData.directorAadharCertificate)}
              >
                <TbExternalLink />
              </IconButton>
            ),
          }}
        />
      </Grid>
    </Paper>
  );

  function linkOpen(val) {
    window.open(val, "_blank");
  }
}
