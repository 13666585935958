import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { Fragment, useState, useEffect } from "react";

import Connections from "./ConnectionList";
import ActionButton from "./ActionButtons";
import FormSixth from "../create campaign components/PreviewImageForm";
import CampaignHeader from "../create campaign components/CampaignHeader";
import FormOne from "../create campaign components/CreateCampaignFirstForm";
import FormTwo from "../create campaign components/CreateCampaignSecondForm";
import FormThird from "../create campaign components/CreateCampaignThirdForm";
import FormFifth from "../create campaign components/CreateCampaignFifthForm";
import FormFourth from "../create campaign components/CreateCampaignFouthForm";
import FormEighth from "../create campaign components/SelectCampaignChannel";
import FormSeventh from "../create campaign components/PreferredCampaignChannel";

export default function CampaignForm({
  categories,
  audiences,
  formikProps,
  component = "",
  brandDetails,
  audienceData,
  connections,
  setEditState,
  editState,
  setBrandDetails,
  setConnections,
  setRetry,
  retry,
  audienceSizing,
  setAudienceSizing,
  preferredChannels,
}) {
  const [brandIdentity, setBrandIdentity] = useState(false);
  const { activeBrand } = useSelector((state) => state.memberDetails);

  useEffect(() => {
    if (formikProps.values.brandID) {
      if (parseInt(formikProps.values.brandID) !== parseInt(activeBrand.ID)) {
        setBrandIdentity(true);
      }
    }
  }, [formikProps.values.brandID, activeBrand.ID]);

  return (
    <Fragment>
      <Grid item xs={12}>
        <CampaignHeader
          brandName={brandDetails.data.brandName}
          iconImage={brandDetails.icon}
          pageName={formikProps.values.campaign_name.value}
          buttons={[
            <ActionButton
              formikProps={formikProps}
              editState={editState}
              setEditState={setEditState}
              ID={formikProps.values.campaignID}
              audienceSizing={audienceSizing}
              setAudienceSizing={setAudienceSizing}
            />,
          ]}
        />
      </Grid>

      <Connections
        retry={retry}
        setRetry={setRetry}
        connections={connections}
        setConnections={setConnections}
      />

      <Grid container spacing={8}>
        <Grid item xs={6}>
          <FormOne
            categories={categories}
            brandDetails={brandDetails}
            formikProps={formikProps}
            editState={editState}
            component={component}
            setBrandDetails={setBrandDetails}
            brandIdentity={brandIdentity}
          />

          <FormTwo
            formikProps={formikProps}
            audiences={audiences}
            editState={editState}
            component={component}
            brandIdentity={brandIdentity}
            audienceData={audienceData}
          />
        </Grid>

        <Grid item xs={6}>
          <FormThird
            brandIdentity={brandIdentity}
            formikProps={formikProps}
            editState={editState}
            component={component}
          />

          <FormFourth
            formikProps={formikProps}
            editState={editState}
            component={component}
            brandIdentity={brandIdentity}
          />

          <FormFifth
            formikProps={formikProps}
            editState={editState}
            component={component}
            brandIdentity={brandIdentity}
          />

          <FormSeventh
            component="superadmin"
            editState={editState}
            formikProps={formikProps}
            preferredChannels={preferredChannels}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}
