import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import FormDisplay from "./FormDisplay";
import pages from "../../../constants/pages";
import { useAxios, useAlert } from "../../../hooks";

export default function Form({ formData }) {
  const axios = useAxios();

  const { alert } = useAlert();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validateOnMount
      onSubmit={(formData, { setSubmitting }) => {}}
    >
      {(formikProps) => (
        <form style={{ padding: "20px" }} onSubmit={formikProps.handleSubmit}>
          <FormDisplay formikProps={formikProps} />
        </form>
      )}
    </Formik>
  );
}
