import { useState } from "react";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Grid, IconButton, InputAdornment, Typography } from "@mui/material";

import pages from "../../../constants/pages";
import Logo from "../../../assets/auth carousel pics/verismart.png";
import { TextField, Label, Paper } from "../../../components";
import { PrimaryLoadingButton } from "../../css components/Button";

export default function FormDisplay({ formikProps }) {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Paper style={{ width: "650px" }}>
      <Grid item xs={12} textAlign={"center"}>
        <img style={{ height: "50px" }} src={Logo} alt="logo" />
        <Typography
          style={{
            fontSize: "14px",
            fontWeight: 500,
          }}
        >
          Sign in to your account to use services!
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Label title={"Email Address"} />
      </Grid>

      <Grid item xs={12}>
        <TextField
          required
          formikProps={formikProps}
          name="email"
          inputProps={{ "aria-label": "Without label" }}
          placeholder="Enter email address"
        />
      </Grid>

      <Grid item xs={12}>
        <Label title={"Password"} />
      </Grid>

      <Grid item xs={12}>
        <TextField
          required
          type={showPassword ? "text" : "password"}
          name="password"
          formikProps={formikProps}
          inputProps={{ "aria-label": "Without label" }}
          placeholder="Enter password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  style={{ padding: 0 }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {!showPassword && <VisibilityOffIcon />}
                  {showPassword && <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12} alignSelf="center">
        <Typography variant="button">
          Don't have account.{" "}
          <Typography
            variant="button"
            className="info"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/sign-up")}
          >
            Sign up now!
          </Typography>
        </Typography>
      </Grid>

      <Grid item xs={12} alignSelf="center">
        <Typography variant="button">
          <Typography
            variant="button"
            className="info"
            style={{ cursor: "pointer" }}
            onClick={() => navigate(pages.resetEmailPasswordPage.route)}
          >
            Forgot Password?
          </Typography>
        </Typography>
      </Grid>

      <Grid item xs />

      <Grid container item justifyContent={"end"} className="loginBtn">
        <Grid item>
          <PrimaryLoadingButton
            type="submit"
            variant="contained"
            onClick={formikProps.handleSubmit}
            disabled={!formikProps.dirty || !formikProps.isValid}
            loading={formikProps.isSubmitting}
          >
            Log In
          </PrimaryLoadingButton>
        </Grid>
      </Grid>
    </Paper>
  );
}
