import { useSelector } from "react-redux";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Form from "./Form";
import { Loader } from "../../../components";
import { useAxios, useComponent } from "../../../hooks";
import {
  createCampaignObject,
  getAudienceDetails,
  objectFromResponse,
} from "../create campaign components/campaignObject";
import {
  brandDetailsObject,
  objectFromBrandResponse,
} from "../brand details components/brandDetailsObject";
import { audienceObject } from "../../audience components/create audience components/audienceObjects";

export default function CampaignRequestDetails() {
  const axios = useAxios();
  const params = useParams();
  const { loader } = useComponent();

  const campaignID = params.campaignID || "";
  const { activeBrand, categories, userID, location, languages } = useSelector(
    (state) => state.memberDetails
  );
  const brandID = queryString.parse(window.location.search).brandID;
  const senderUserID = queryString.parse(window.location.search).sender_id;

  const [icon, setIcon] = useState("");
  const [liveUsers, setLiveUsers] = useState({
    liveUser: "",
    state: false,
  });
  const [audiences, setAudiences] = useState([]);
  const [formData, setFormData] = useState(createCampaignObject);
  const [audienceData, setAudienceData] = useState(audienceObject);
  const [brandDetails, setBrandDetails] = useState({
    data: brandDetailsObject,
    state: false,
  });

  const [takeUpOffer, setTakeUpOffer] = useState({
    state: false,
    action: "",
    data: {
      sender_brandId: parseInt(brandID),
      sender_campaignId: parseInt(campaignID),
      receiver_userId: parseInt(userID),
      receiver_brandId: parseInt(activeBrand.ID),
      dp_note: "",
    },
  });

  useEffect(() => {
    loader.start(5);
    axios({ url: "/brand/getAllAudiences?brandId=" + brandID }).then((res) => {
      if (res.status) {
        let arr = [];

        for (let key of res.data) {
          let data = {};
          for (let fields of key.fields) {
            data[Object.keys(fields)[0]] = Object.values(fields)[0];
          }
          arr.push({
            ...data,
            audience_id: key.audience_id,
          });
        }
        setAudiences(arr);

        loader.apiComplete();
      }
    });

    axios({
      url: "/user/getUsersCount?v=1",
      method: "POST",
      disableRedirect: true,
      data: {
        sender_brandId: parseInt(brandID),
        sender_campaignId: parseInt(campaignID),
        receiver_userId: parseInt(userID),
        receiver_brandId: parseInt(activeBrand.ID),
      },
    }).then((response) => {
      if (response.status) {
        setLiveUsers({ state: true, liveUser: response?.data.usersCount });
      }
    });

    if (!!campaignID) {
      let campaignData = {};
      axios({
        url:
          "/user/brand/getCampaign?brandId=" +
          parseInt(brandID) +
          "&campaignId=" +
          parseInt(campaignID),
        method: "GET",
      }).then(async (response) => {
        if (response.status) {
          axios({
            url:
              "/brand/promotional/getMappedAudience/?brandId=" +
              activeBrand.ID +
              "&campaignId=" +
              parseInt(campaignID),
          }).then(async (res) => {
            if (res.status) {
              await setAudienceData(getAudienceDetails(res.data));
              loader.apiComplete(1);
            }
          });

          await axios({
            url: "/user/brand/campaign/creatives?campaignId=" + campaignID,
          }).then(async (creatives) => {
            if (creatives.status) {
              let campaignStatus = "";
              if (response.data.connections.length > 0) {
                for (let connection of response.data.connections) {
                  if (connection.receiver_brandId === activeBrand.ID) {
                    campaignStatus = connection.requestStatus;
                  }
                }
              }

              campaignData = {
                ...response.data,
                creatives: creatives.data,
                campaignStatus: campaignStatus,
              };

              await setFormData(objectFromResponse(campaignData));
              loader.apiComplete(2);
            }
          });
        }
      });

      axios({
        url: "/getBrand/" + brandID,
        disableRedirect: true,
      }).then((response) => {
        if (response.status) {
          if (!!response.data.logo) {
            setIcon(response.data.logo);
          }
          setBrandDetails({
            data: objectFromBrandResponse(response.data),
            state: false,
          });
          loader.apiComplete();
        }
      });
    } else {
      loader.apiComplete(4);
    }
  }, [
    brandID,
    axios,
    activeBrand.ID,
    userID,
    loader,
    campaignID,
    senderUserID,
  ]);

  return (
    <Loader>
      <Form
        categories={categories}
        location={location}
        languages={languages}
        takeUpOffer={takeUpOffer}
        setTakeUpOffer={setTakeUpOffer}
        audiences={audiences}
        formData={formData}
        brandDetails={brandDetails}
        component={"request"}
        setBrandDetails={setBrandDetails}
        audienceData={audienceData}
        icon={icon}
        liveUsers={liveUsers}
      />
    </Loader>
  );
}
