import { Fragment } from "react";
import { useNavigate } from "react-router-dom";

import {
  CancelButton,
  PrimaryButton,
  PrimaryLoadingButton,
  Reject,
  Accept,
  Pending,
  AcceptUpload,
} from "../../css components/Button";

export default function CampaignButtons({
  ID = "",
  editState,
  liveUsers,
  setEditState,
  formikProps,
  component,
  brandIdentity,
  setTakeUpOffer,
  setDialogUploadCSV,
  setBroadcastReportDialog,
}) {
  const navigate = useNavigate();

  function handleCancel() {
    if (editState && !!ID) {
      setEditState(!editState);
      formikProps.handleReset();
    } else {
      navigate(-1);
    }
  }

  return (
    <Fragment>
      <CancelButton onClick={handleCancel} variant="outlined">
        {!editState ? "Back" : "Cancel"}
      </CancelButton>

      {component === "" &&
        (editState && formikProps.values.isEditable ? (
          <PrimaryLoadingButton
            type="submit"
            size="large"
            variant="contained"
            onClick={formikProps.handleSubmit}
            loading={formikProps.isSubmitting}
            disabled={!formikProps.dirty || !formikProps.isValid}
          >
            Save
          </PrimaryLoadingButton>
        ) : (
          formikProps.values.isEditable && (
            <PrimaryButton
              size="large"
              variant="contained"
              onClick={() => setEditState(!editState)}
              disabled={formikProps.values.campaignStatus === "ACTIVE"}
            >
              Edit
            </PrimaryButton>
          )
        ))}

      {component === "superadmin" && (
        <Accept
          size="large"
          variant="contained"
          style={{ marginRight: "15px" }}
        >
          Approve Campaign
        </Accept>
      )}

      {brandIdentity && component !== "accepted" && (
        <Fragment>
          {formikProps.values.campaignStatus !== "REJECTED" && (
            <Reject
              size="large"
              variant="contained"
              style={{ marginRight: "15px" }}
              onClick={() =>
                setTakeUpOffer((prevVal) => ({
                  ...prevVal,
                  action: "REJECTED",
                  state: true,
                }))
              }
            >
              Reject
            </Reject>
          )}

          {formikProps.values.campaignStatus === "REQUESTED" && (
            <Pending
              size="large"
              variant="contained"
              style={{ marginRight: "15px" }}
              onClick={() =>
                setTakeUpOffer((prevVal) => ({
                  ...prevVal,
                  action: "PENDING",
                  state: true,
                }))
              }
            >
              Pending
            </Pending>
          )}

          {formikProps.values.campaignStatus !== "ACCEPTED" && (
            <Accept
              size="large"
              variant="contained"
              disabled={
                parseInt(liveUsers?.liveUser) <= 0 || !liveUsers.liveUser
              }
              style={{ marginRight: "15px" }}
              onClick={() =>
                setTakeUpOffer((prevVal) => ({
                  ...prevVal,
                  action: "ACCEPTED",
                  state: true,
                }))
              }
            >
              Accept
            </Accept>
          )}
        </Fragment>
      )}

      {component === "request" &&
        formikProps.values.csvUpload &&
        (formikProps.values.campaignStatus === "REQUESTED" ||
          formikProps.values.campaignStatus === "REJECTED") && (
          <AcceptUpload
            variant="contained"
            onClick={() => setDialogUploadCSV(true)}
          >
            UPLOAD ACCEPT
          </AcceptUpload>
        )}

      {formikProps.values.isBroadcast && (
        <PrimaryButton
          variant="outlined"
          size="large"
          sx={{
            display: Boolean(component === "request") && "none",
            whiteSpace: "nowrap",
          }}
          onClick={() => setBroadcastReportDialog(true)}
        >
          Broadcast Report
        </PrimaryButton>
      )}
    </Fragment>
  );
}
