import { Fragment } from "react";
import { Grid } from "@mui/material";

import FormOne from "./FormOne";
import FormTwo from "./FormTwo";
import { PageHeader } from "../../../components";
import ActionButton from "./ActionButtons";
import AdminBrandStatus from "./BrandStatus";

export default function FormDisplay({
  formData,
  setFormData,
  location,
  languages,
  categories,
}) {
  return (
    <Fragment>
      <Grid item xs={12}>
        <PageHeader
          heading={"Brand Details"}
          twoText={true}
          firstText={formData.brandName}
          fontSize="25px"
          secondText={""}
          buttons={[
            <ActionButton
              formData={formData}
              ID={formData.brandID}
              setFormData={setFormData}
            />,
          ]}
        />
      </Grid>

      <AdminBrandStatus formData={formData} />

      <Grid container spacing={8}>
        <Grid item xs={6}>
          <FormOne formData={formData} />
        </Grid>

        <Grid item xs={6}>
          <FormTwo
            formData={formData}
            location={location}
            languages={languages}
            categories={categories}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}
