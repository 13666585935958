import { Grid } from "@mui/material";

import { Label, Paper, TextField } from "../../../components";

export default function FormOne({ formikProps, editState }) {
  return (
    <Paper>
      <Grid item xs={12}>
        <Label
          title={"Company Name (as per GST Certificate)"}
          formikProps={formikProps}
          required={true}
          name={"companyName"}
        />
      </Grid>

      <Grid item xs={10}>
        <TextField
          name="companyName"
          placeholder="Company Name"
          formikProps={formikProps}
          disabled={!editState}
          inputProps={{ "aria-label": "Without label" }}
        />
      </Grid>

      <Grid item xs={12}>
        <Label
          title="Company Address (as per GST Certificate)"
          formikProps={formikProps}
          required={true}
          name={"companyAddress"}
        />
      </Grid>

      <Grid item xs={10}>
        <TextField
          placeholder="Company Address"
          name="companyAddress"
          multiline
          disabled={!editState}
          formikProps={formikProps}
          inputProps={{ "aria-label": "Without label" }}
        />
      </Grid>

      <Grid item xs={6}>
        <Label
          title={"Company Contact"}
          formikProps={formikProps}
          required={true}
          name={"companyContact"}
        />
      </Grid>

      <Grid item xs={10}>
        <TextField
          name="companyContact"
          placeholder="Company Contact"
          formikProps={formikProps}
          disabled={!editState}
          inputProps={{ "aria-label": "Without label" }}
        />
      </Grid>

      <Grid item xs={12}>
        <Label
          title={"Business Nature"}
          formikProps={formikProps}
          name={"companyNatureBusiness"}
        />
      </Grid>

      <Grid item xs={10}>
        <TextField
          name="companyNatureBusiness"
          placeholder="Business Nature"
          formikProps={formikProps}
          disabled={!editState}
          inputProps={{ "aria-label": "Without label" }}
        />
      </Grid>
    </Paper>
  );
}
