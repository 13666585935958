import { BiWallet } from "react-icons/bi";
import styled from "styled-components/macro";
import { Avatar, Grid, Typography } from "@mui/material";

import { Paper } from "../../components";
import { TextFourteenBlack, colors } from "../css components/Style";
import convertDateTimeUTC from "../../utils/convertUTC";

export default function TransactionList({ creditInfo }) {
  const Header = styled(Typography)`
    font-size: 16px;
    font-weight: 500;
  `;

  const Image = styled(Avatar)`
    height: 55px;
    width: 55px;
    background: #fff;
    border: 1px solid;
  `;

  return (
    <Grid container spacing={3}>
      {creditInfo?.creditHistory?.map((credit) => (
        <Grid item xs={12}>
          <Paper>
            <Grid item>
              <Image
                style={{
                  borderColor:
                    credit.action === "credit" ? colors.success : colors.error,
                }}
              >
                <BiWallet
                  style={{
                    fontSize: "25px",

                    color:
                      credit.action === "credit"
                        ? colors.success
                        : colors.error,
                  }}
                />
              </Image>
            </Grid>

            <Grid item xs style={{ marginLeft: "10px" }}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Header>{credit.title}</Header>

                  {credit.action === "credit" && (
                    <TextFourteenBlack>
                      Transaction ID {credit.transaction_id}
                    </TextFourteenBlack>
                  )}

                  <TextFourteenBlack>
                    {convertDateTimeUTC(credit.created_at)}
                  </TextFourteenBlack>
                </Grid>

                <Grid item xs={2}>
                  <Header>{credit.amount} </Header>
                </Grid>

                <Grid item xs={3}>
                  <Header
                    style={{
                      color: colors.success,
                    }}
                  >
                    {credit.credit_amt > 0 && credit.credit_amt}{" "}
                  </Header>

                  <Header
                    style={{
                      color: colors.error,
                    }}
                  >
                    {credit.debit_amt > 0 && credit.debit_amt}{" "}
                  </Header>
                </Grid>

                <Grid item xs={3}>
                  <Header
                    style={{
                      color:
                        credit.action === "credit"
                          ? colors.success
                          : colors.error,
                    }}
                  >
                    {credit.action}
                  </Header>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
}
