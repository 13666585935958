import queryString from "query-string";
import { useSelector } from "react-redux";
import { People } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import { Grid, Chip, Typography } from "@mui/material";

import pages from "../../constants/pages";
import { useAxios, useComponent } from "../../hooks";
import { Loader, PageHeader } from "../../components";
import List from "./connection list components/List";
import {
  createCampaignObject,
  objectFromResponse,
} from "./connection list components/campaignObject";
import { toInternationalFormat } from "../../utils";
import { Accept, CancelButton } from "../css components/Button";
import LaunchCampaignConfirm from "./connection list components/dialog components/LaunchCampaignConfirm";

export default function Connection() {
  const axios = useAxios();
  const navigate = useNavigate();

  const { loader } = useComponent();

  const [emptyList, setEmptyList] = useState(true);
  const [connections, setConnections] = useState([]);
  const [sentChanges, setSentChanges] = useState({
    requestSent: "",
    verifiedProof: "",
  });
  const [users, setUsers] = useState({
    common: "",
    total: "",
    neededMandate: "",
    nonNeededMandate: "",
  });
  const [confirm, setConfirm] = useState({
    state: false,
  });
  const [formData, setFormData] = useState(createCampaignObject);
  const [launchCampaign, setLaunchCampaign] = useState(true);
  const { activeBrand, userID } = useSelector((state) => state.memberDetails);

  const campaignID = queryString.parse(window.location.search).campaignID;

  useEffect(() => {
    loader.start(3);

    axios({
      url:
        "/user/brand/getCampaign?brandId=" +
        parseInt(activeBrand.ID) +
        "&campaignId=" +
        parseInt(campaignID),
      method: "GET",
    }).then((response) => {
      if (response.status) {
        loader.apiComplete();
        setFormData(objectFromResponse(response.data));
      }
    });

    let arr = [];
    axios({
      url: "/user/getAllRelevantDataProviders",
      method: "POST",
      data: {
        sender_userId: parseInt(userID),
        sender_brandId: parseInt(activeBrand.ID),
        sender_campaignId: parseInt(campaignID),
      },
    }).then((response) => {
      if (response.status) {
        axios({
          url: "/user/getDistictUserCount",
          method: "POST",
          data: {
            sender_userId: parseInt(userID),
            sender_brandId: parseInt(activeBrand.ID),
            sender_campaignId: parseInt(campaignID),
          },
        }).then((user) => {
          if (user.status) {
            loader.apiComplete();

            setUsers({
              common: user.data.UserCount,
              total: response.data.totalUsers,
              neededMandate: response.data.needed_mandate,
              nonNeededMandate: response.data.needed_nonMandate,
            });
          }
        });

        for (let con of response.data?.DP) {
          if (con !== null && con.brands.length > 0) {
            arr.push({
              business_entity_id: con.business_entity_id,
              email: con.email,
              isRequested: con.isRequested,
              p_id: con.p_id,
              user_id: con.user_id,
              proof: con.proof,
              note: con.note || "",
              requestStatus: con.requestStatus,
              mandateMatched: con.matched_mandate,
              brand: {
                logo: con.brands[0]?.logo,
                mau: toInternationalFormat(con.brands[0]?.mau) || "",
                brandId: con.brands[0]?.brandId,
                category: con.brands[0]?.category,
                languages: con.brands[0]?.languages,
                locations: con.brands[0]?.locations,
                name: con.brands[0]?.name,
              },
            });
          }
        }
        setConnections(arr);
        loader.apiComplete();
      } else {
        setEmptyList(false);
        loader.stop(2);
      }
    });
  }, [
    axios,
    loader,
    campaignID,
    activeBrand.ID,
    userID,
    sentChanges.requestSent,
    sentChanges.verifiedProof,
  ]);

  let isApproved = connections.filter((items) => {
    return items.proof.isApproved === true;
  });

  return (
    <Fragment>
      <Loader>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <PageHeader
              heading={formData.campaign_name.value}
              pageName={"Recommended Connections"}
            />
          </Grid>

          <Grid item xs={12} />

          <Grid item>
            <Chip
              style={{
                color: "#fff",
                backgroundColor: "rgba(52, 181, 58, 1)",
                height: "50px",
                paddingInline: "5px",
              }}
              icon={<People style={{ color: "#fff" }} />}
              label={"Unique User Count: " + (users.total - users.common)}
            />
          </Grid>

          <Grid item alignSelf={"center"}>
            <Chip
              style={{
                color: "#fff",
                backgroundColor: "#02A0FC",
                height: "50px",
                paddingInline: "5px",
              }}
              label={"Mandate Need: " + users.neededMandate}
            />
          </Grid>

          <Grid item alignSelf={"center"}>
            <Chip
              style={{
                color: "#fff",
                backgroundColor: "#02A0FC",
                height: "50px",
                paddingInline: "5px",
              }}
              label={"Non-Mandate Need: " + users.nonNeededMandate}
            />
          </Grid>

          <Grid item xs />

          <Grid item textAlign="end">
            <CancelButton
              variant="outlined"
              onClick={() => navigate(pages.campaignDetails.route + campaignID)}
            >
              {" "}
              Back
            </CancelButton>

            {Boolean(formData?.adminCampaignStatus) &&
              formData?.campaignLaunched &&
              launchCampaign &&
              Boolean(isApproved) && (
                <Accept
                  variant="contained"
                  onClick={() => setConfirm({ state: true })}
                >
                  {" "}
                  Launch Campaign
                </Accept>
              )}
          </Grid>

          <Grid item xs={12} />
        </Grid>

        {emptyList ? (
          <List
            users={users}
            campaignID={campaignID}
            connections={connections}
            setSentChanges={setSentChanges}
          />
        ) : (
          <Grid item xs={12} textAlign="center">
            <Typography style={{ fontWeight: "500", fontSize: "30px" }}>
              No campaign request Yet!
            </Typography>
          </Grid>
        )}
      </Loader>

      <LaunchCampaignConfirm
        confirm={confirm}
        setConfirm={setConfirm}
        campaignID={campaignID}
        setLaunchCampaign={setLaunchCampaign}
      />
    </Fragment>
  );
}
