import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import styled from "styled-components/macro";

export const PrimaryButton = styled(Button)`
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  padding: 14px 30px;
  height: 50px;
  border-radius: 25px;
  min-width: 160px;
  max-width: 250px;
`;

export const PrimaryLoadingButton = styled(LoadingButton)`
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  padding: 14px 30px;
  height: 50px;
  border-radius: 25px;
  min-width: 160px;
  max-width: 250px;
`;

export const CancelButton = styled(Button)`
  border-color: #bdbdbd;
  font-size: 18px;
  padding: 14px 30px;
  font-weight: 600;
  color: #bdbdbd;
  height: 50px;
  border-radius: 25px;
  margin-right: 15px;
  min-width: 160px;
  max-width: 250px;

  &:hover {
    border: 1px solid #bdbdbd;
  }
`;

export const CancelLoadingButton = styled(LoadingButton)`
  border-color: #bdbdbd;
  font-size: 18px;
  padding: 14px 30px;
  font-weight: 600;
  color: #bdbdbd;
  height: 50px;
  border-radius: 25px;
  max-width: 250px;
  min-width: 160px;

  &:hover {
    border: 1px solid #bdbdbd;
  }
`;

export const Accept = styled(LoadingButton)`
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  padding: 14px 30px;
  height: 50px;
  border-radius: 25px;
  background: #5ac35f;
  min-width: 160px;
  max-width: 250px;

  &:hover {
    background: #5ac35f;
  }
`;

export const Reject = styled(LoadingButton)`
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  padding: 14px 30px;
  height: 50px;
  border-radius: 25px;
  background: #ff0000;
  min-width: 160px;
  max-width: 250px;

  &:hover {
    background: #ff0000;
  }
`;

export const Pending = styled(LoadingButton)`
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  padding: 14px 30px;
  height: 50px;
  border-radius: 25px;
  background: #ffbb0b;
  min-width: 160px;
  max-width: 250px;
  &:hover {
    background: #ffbb0b;
  }
`;

export const AcceptUpload = styled(LoadingButton)`
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  padding: 14px 27px;
  height: 50px;
  border-radius: 40px;
  background: #0087a8;
  min-width: 160px;
  max-width: 250px;
  margin-left:

  &:hover {
    background: #5aaec3;
  }
`;
