import styled from "styled-components";
import { useDispatch } from "react-redux";
import { Divider, Grid, Typography } from "@mui/material";

import { signOutMember } from "../../redux/slices/memberSlice";
import Logo from "../../assets/auth carousel pics/verismart-background.png";
import { Fragment } from "react";

const OuterGrid = styled(Grid)`
  padding: 10px 30px;
  height: 85px;
  margin-top: 0px;
`;

const ImageDiv = styled.div`
  width: 85px;
  margin-top: 5px;
`;

const Font = styled(Typography)`
  font-weight: 700;
  font-size: 20px;
  cursor: pointer;
`;

export function Navbar() {
  const dispatch = useDispatch();

  return (
    <Fragment>
      <OuterGrid container spacing={2}>
        <Grid item xs style={{ paddingTop: "0px" }}>
          <ImageDiv>
            <img style={{ height: "50px" }} src={Logo} alt="logo" />
          </ImageDiv>
        </Grid>

        {/* <Grid item alignSelf={"center"} fontWeight="bold" marginRight="20px">
        <Font>FAQ</Font>
      </Grid> */}

        <Grid item alignSelf={"center"} fontWeight="bold">
          <Font onClick={() => dispatch(signOutMember())}>Logout</Font>
        </Grid>
      </OuterGrid>

      <Divider />
    </Fragment>
  );
}
