import styled from "styled-components";
import { Helmet } from "react-helmet-async";

import Form from "./log in components/Form";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10%;
  margin-bottom: 10%;
`;

export default function LogIn() {
  return (
    <Wrapper>
      <Helmet title="Sign In" />

      <Form />
    </Wrapper>
  );
}
