import styled from "styled-components/macro";
import { Grid, Typography } from "@mui/material";

import { Label, Paper, TextField } from "../../../components";

const MainHeading = styled(Typography)`
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  color: #02a0fc;
`;

export default function FormThird({ formikProps, editState }) {
  return (
    <Paper>
      <Grid item xs={12}>
        <MainHeading>Budget</MainHeading>
      </Grid>

      <Grid item xs={5}>
        <Label
          title="Minimum Budget"
          formikProps={formikProps}
          required={true}
          name={"budget.value.min"}
        />
      </Grid>

      <Grid item xs={6}>
        <Label
          title="Maximum Budget"
          formikProps={formikProps}
          required={true}
          name={"budget.value.max"}
        />
      </Grid>

      <Grid item xs={5}>
        <TextField
          name="budget.value.min"
          formikProps={formikProps}
          disabled={!editState}
          inputProps={{ "aria-label": "Without label" }}
          placeholder="Minimum budget"
        />
      </Grid>

      <Grid item xs={5}>
        <TextField
          name="budget.value.max"
          formikProps={formikProps}
          disabled={!editState}
          inputProps={{ "aria-label": "Without label" }}
          placeholder="Maximum budget"
        />
      </Grid>
    </Paper>
  );
}
