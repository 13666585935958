import { Grid } from "@mui/material";
import { Fragment, useState } from "react";

import { Paper } from "../../../components";
import ShowAudienceSizeDialog from "./ShowAudienceSize";
import { ActiveTextEighteen, colors } from "../../css components/Style";

export default function AdminCampaignStatus({
  formikProps,
  setTakeUpOffer,
  audienceSize,
}) {
  const [showAudSize, setShowAudSize] = useState();

  return (
    <Fragment>
      {!!(audienceSize?.audience_params || audienceSize?.audience_size) ? (
        <Paper>
          <Grid item xs={12} textAlign={"center"} display={"flex"}>
            <ActiveTextEighteen>
              Your audience size is ready ! &nbsp;{" "}
            </ActiveTextEighteen>
            <ActiveTextEighteen
              color={colors.info}
              style={{ cursor: "pointer" }}
              onClick={() => setShowAudSize(true)}
            >
              View
            </ActiveTextEighteen>
          </Grid>
        </Paper>
      ) : (
        <Paper>
          <Grid item xs={12} textAlign={"center"} display={"flex"}>
            <ActiveTextEighteen>
              Audience sizing for the campaign is under progress. Usually takes
              48-72 hours !
            </ActiveTextEighteen>
          </Grid>
        </Paper>
      )}

      <ShowAudienceSizeDialog
        audienceSize={audienceSize}
        showAudSize={showAudSize}
        setShowAudSize={setShowAudSize}
      />
    </Fragment>
  );
}

/* {formikProps.values.campaignStatus === "REJECTED" && (
        <Grid item xs={12}>
          <ActiveTextEighteen color={colors.error}>
            Campaign Offer was rejected.
          </ActiveTextEighteen>

          <div style={{ display: "flex" }}>
            <ActiveTextEighteen>Change of mind? &nbsp; </ActiveTextEighteen>
            <ActiveTextEighteen
              color={colors.success}
              style={{ cursor: "pointer" }}
              onClick={() =>
                setTakeUpOffer((prevVal) => ({
                  ...prevVal,
                  action: "ACCEPTED",
                  state: true,
                }))
              }
            >
              Accept Now.
            </ActiveTextEighteen>
          </div>
        </Grid>
      )}

      {formikProps.values.campaignStatus === "PENDING" && (
        <Grid item xs={12}>
          <ActiveTextEighteen color={colors.warning}>
            Campaign is in pending state, Take action.
          </ActiveTextEighteen>
        </Grid>
      )}

      {formikProps.values.campaignStatus === "ACCEPTED" && (
        <Grid item xs={12}>
          <ActiveTextEighteen color={colors.success}>
            Campaign Offer was accepted.
          </ActiveTextEighteen>

          <div style={{ display: "flex" }}>
            <ActiveTextEighteen>Change of mind? &nbsp; </ActiveTextEighteen>
            <ActiveTextEighteen
              color={colors.error}
              style={{ cursor: "pointer" }}
              onClick={() =>
                setTakeUpOffer((prevVal) => ({
                  ...prevVal,
                  action: "REJECTED",
                  state: true,
                }))
              }
            >
              Reject Now.
            </ActiveTextEighteen>
          </div>
        </Grid>
      )}

      {formikProps.values.campaignStatus === "PROOF_GENERATION_FAILED" && (
        <Grid item xs={12}>
          <ActiveTextEighteen color={colors.error}>
            Campaign proof generation failed.
          </ActiveTextEighteen>

          <div style={{ display: "flex" }}>
            <ActiveTextEighteen>Accept Offer again. &nbsp; </ActiveTextEighteen>
            <ActiveTextEighteen
              color={colors.success}
              style={{ cursor: "pointer" }}
              onClick={() =>
                setTakeUpOffer((prevVal) => ({
                  ...prevVal,
                  action: "ACCEPTED",
                  state: true,
                }))
              }
            >
              Accept Now.
            </ActiveTextEighteen>
          </div>
        </Grid>
      )} */
