import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import { Grid, Typography } from "@mui/material";

import { Paper, Label, AutocompleteMultiSelect } from "../../../components";

const MainHeading = styled(Typography)`
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  color: #02a0fc;
`;

export default function FormSeventh({
  component = "",
  formikProps,
  editState,
  preferredChannels,
}) {
  const { agent } = useSelector((state) => state.memberDetails);

  function handleChange(arr, { option }, reason, name, displayName) {
    let objectName = name.substring(0, name.indexOf("."));

    formikProps.setValues((prevVal) => ({
      ...prevVal,
      [objectName]: setValuesOfDropDown(prevVal[objectName]),
    }));

    function setValuesOfDropDown(val) {
      let values = [...val.value];

      if (reason === "removeOption") {
        if (typeof option === "object") {
          values = values.filter((val) => val !== option[displayName]);
        }

        if (typeof option === "string") {
          values.pop();
        }
      }
      if (reason === "selectOption") {
        values.push(option[displayName]);
      }

      return {
        ...val,
        value: values,
      };
    }
  }

  return (
    <Paper style={{ marginTop: "25px" }}>
      <Grid item xs={12}>
        <MainHeading>Preferred Campaign Channel</MainHeading>
      </Grid>

      <Grid item xs={12}>
        <Label
          title="Preferred Campaign Channel"
          formikProps={formikProps}
          required={true}
          name={"pref_campaign_channel.value"}
        />
      </Grid>

      <Grid item xs={10}>
        <AutocompleteMultiSelect
          name="pref_campaign_channel.value"
          forcePopupIcon={!editState ? false : true}
          formikProps={formikProps}
          defaults={{
            primaryKey: "id",
            displayLabel: "channel_name",
          }}
          disabled={!editState}
          options={preferredChannels}
          placeholder="Select Preferred Channel"
          onChange={(_, arr, reason, option) =>
            handleChange(
              arr,
              option,
              reason,
              "pref_campaign_channel.value",
              "id"
            )
          }
        />

        {/* {agent?.length === 0 && component !== "superadmin" && (
          <Typography style={{ marginTop: "10px" }}>
            In case to add WhatsApp as channel, please contact to admin.
          </Typography>
        )} */}
      </Grid>
    </Paper>
  );
}
