import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import FormDisplay from "./FormDisplay";
import pages from "../../../constants/pages";
import { useAxios, useAlert } from "../../../hooks";
import { objectFromFormData, validationSchema } from "./companyDetailsObject";

export default function Form({
  formData,
  setFormData,
  editState,
  setEditState,
}) {
  const axios = useAxios();
  const { alert } = useAlert();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const brandID =
    useSelector((state) => state.memberDetails?.activeBrand?.ID) ||
    sessionStorage.getItem("brandID");

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validateOnMount
      validationSchema={validationSchema}
      onSubmit={(formData, { setSubmitting }) => {
        axios({
          url: "/user/companyProfile",
          method: "POST",
          disableRedirect: true,
          data: objectFromFormData(formData, brandID),
        })
          .then((response) => {
            if (response.status) {
              if (formData.ID) {
                setEditState(false);
              }
              if (!formData.brandID) {
                navigate(pages.createWelcomePage.route);
              }

              dispatch(
                alert({
                  type: "success",
                  message: response.message.displayMessage,
                })
              );
            } else {
              dispatch(
                alert({
                  type: "error",
                  message: response.message,
                })
              );
            }
            setSubmitting(false);
          })
          .catch((err) => {
            console.error(err);
          });
      }}
    >
      {(formikProps) => (
        <FormDisplay
          formikProps={formikProps}
          setEditState={setEditState}
          editState={editState}
        />
      )}
    </Formik>
  );
}
