import * as Yup from "yup";

export const companyObject = {
  ID: "",
  companyName: "",
  companyAddress: "",
  companyPAN: "",
  companyContact: "",
  companyNatureBusiness: "",
  companyGSTNumber: "",
  companyDirectorsName: "",
  companyDirectorsPanNumber: "",
  companyDirectorAadharNumber: "",
  gstCertificate: "",
  companyPanCertificate: "",
  directorPanCertificate: "",
  directorAadharCertificate: "",
};

export function objectFromFormData(formData, brandID) {
  return {
    brand_id: parseInt(brandID),
    company_name: formData.companyName,
    gst_no: formData.companyGSTNumber,
    contact_no: formData.companyContact,
    company_address: formData.companyAddress,
    pan_no: formData.companyPAN,
    director_name: formData.companyDirectorsName,
    director_pan: formData.companyDirectorsPanNumber,
    director_aadhar: formData.companyDirectorAadharNumber,
    nature_of_business: formData.companyNatureBusiness,
    gst_certificate: formData.gstCertificate,
    company_pan_certificate: formData.companyPanCertificate,
    director_pan_certificate: formData.directorPanCertificate,
    director_aadhar_certificate: formData.directorAadharCertificate,
  };
}

export function objectFromResponseData(responseData) {
  return {
    brandID: responseData.brand_id,
    ID: responseData.id,
    companyName: responseData.company_name,
    companyAddress: responseData.address,
    companyPAN: responseData.pan_no,
    companyContact: responseData.contact_no,
    companyNatureBusiness: responseData.nature_of_business,
    companyGSTNumber: responseData.gst,
    companyDirectorsName: responseData.director_name,
    companyDirectorsPanNumber: responseData.director_pan,
    companyDirectorAadharNumber: responseData.director_aadhar,
    gstCertificate: responseData.gst_certificate,
    companyPanCertificate: responseData.company_pan_certificate,
    directorPanCertificate: responseData.director_pan_certificate,
    directorAadharCertificate: responseData.director_aadhar_certificate,
  };
}

export const validationSchema = Yup.object().shape({
  companyName: Yup.string().required("Company Name is required"),
  companyAddress: Yup.string().required("Company Address is required"),
  companyContact: Yup.string()
    .matches(/^\d+$/, "It should contain number only")
    .length(10, "Contact number should be of 10 digits")
    .required("Contact Number is required"),
  companyDirectorsName: Yup.string().required(
    "Company Director Name is required"
  ),
  gstCertificate: Yup.mixed().required("GST Certificate is required"),
  companyPanCertificate: Yup.mixed().required(
    "Company PAN Certificate is required"
  ),
  directorPanCertificate: Yup.mixed().required(
    "Company directors PAN Certificate is required"
  ),
  directorAadharCertificate: Yup.mixed().required(
    "Company director Aadhar Certificate is required"
  ),
});
