import { useParams } from "react-router-dom";
import { Grid, Skeleton } from "@mui/material";
import React, { useState, useEffect, Fragment } from "react";

import SortBy from "./user list components/Sort";
import Search from "./user list components/Search";
import BASE_URL from "../../constants/baseURL";
import { useComponent, useAxios } from "../../hooks";
import { Loader, PageHeader } from "../../components";
import UserList from "./user list components/UserList";
import Categorize from "./user list components/Categorize";
import EmptyList from "../misc/EmptyList";

export default function SuperAdminUsers() {
  const axios = useAxios();
  const params = useParams();
  const { loader } = useComponent();

  const brandID = params.brandID || "";

  const [users, setUsers] = useState("Brands");
  const [sortBy, setSortBy] = useState("ALL");
  const [loading, setLoading] = useState(true);
  const [usersData, setUsersData] = useState({
    users: [],
    brand: {},
  });

  useEffect(() => {
    loader.start();
    axios({
      url: "/users?brandId=" + brandID,
    }).then((response) => {
      if (response.status) {
        setUsersData({
          users: response.data.users,
          brand: response.data.brand,
        });
        setLoading(false);
        loader.apiComplete();
      }
    });
  }, [loader, axios, brandID]);

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} display="flex">
          {loading ? (
            <div style={{ display: "flex", marginBottom: "20px" }}>
              <div>
                <Skeleton
                  animation="wave"
                  variant="circular"
                  height={70}
                  width={70}
                />
              </div>

              <div>
                <Skeleton
                  animation="wave"
                  style={{ marginLeft: "20px" }}
                  variant="rectangular"
                  height={23}
                  width={50}
                />

                <Skeleton
                  style={{ marginLeft: "20px", marginTop: "20px" }}
                  animation="wave"
                  variant="rectangular"
                  height={23}
                  width={80}
                />
              </div>
            </div>
          ) : (
            <Fragment>
              <PageHeader
                pageName={usersData.brand.brand_name}
                heading={"Users"}
                iconImage={usersData.brand.brand_logo}
              />
            </Fragment>
          )}
        </Grid>

        <Categorize users={users} setUsers={setUsers} />

        <SortBy sortBy={sortBy} setSortBy={setSortBy} />

        <Search />

        <Grid item xs={12} />
      </Grid>

      <Loader height="75%">
        {usersData.users.length > 0 ? (
          <UserList usersData={usersData} />
        ) : (
          <EmptyList title={"No users found."} />
        )}
      </Loader>
    </Fragment>
  );
}
