import { Grid } from "@mui/material";

import TransactionList from "./List";
import { ActiveTextEighteen } from "../css components/Style";

export default function Transaction({ creditInfo }) {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} style={{ marginTop: "12px" }}>
        <ActiveTextEighteen>All Transaction Details</ActiveTextEighteen>
      </Grid>

      {creditInfo.creditHistory.length > 0 ? (
        <Grid item xs={12}>
          <TransactionList creditInfo={creditInfo} />
        </Grid>
      ) : (
        <Grid item xs={12} textAlign={"center"}>
          <ActiveTextEighteen style={{ fontSize: "16px", marginTop: "20px" }}>
            No Transactions yet
          </ActiveTextEighteen>
        </Grid>
      )}
    </Grid>
  );
}
