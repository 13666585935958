import { Fragment } from "react";
import { CssBaseline } from "@mui/material";
import styled from "styled-components/macro";
import { Navigate, Outlet } from "react-router-dom";
import PerfectScrollBar from "react-perfect-scrollbar";

import pages from "../constants/pages";
import GlobalStyle from "../components/GlobalStyle";
import { Navbar } from "./empty layout components/Navbar";

const ScrollBar = styled(PerfectScrollBar)`
  margin: 4px;
  height: calc(100% - 8px) !important;
  display: flex;

  > * {
    flex: 1;
  }
`;

const MainContentWrapper = styled.div`
  margin: 4px;
  padding: 20px;
  height: calc(100% - 85px);
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export default function EmptyLayout() {
  const sessionStorageToken = sessionStorage.getItem("authToken");
  const businessEntityId = sessionStorage.getItem("businessEntityId");

  if (!(sessionStorageToken || businessEntityId)) {
    return <Navigate to={`${pages.signIn.route}`} />;
  }

  return (
    <Fragment>
      <CssBaseline />
      <GlobalStyle />

      <Navbar />

      <ScrollBar>
        <MainContentWrapper>
          <Outlet />
        </MainContentWrapper>
      </ScrollBar>
    </Fragment>
  );
}
