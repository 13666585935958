import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Loader } from "../../../components";

import Form from "./CreateCampaign";
import { useAxios, useComponent } from "../../../hooks";
import {
  connectionLists,
  createCampaignObject,
  getAudienceDetails,
  objectFromResponse,
} from "./campaignObject";
import { audienceObject } from "./audience details components/audienceObjects";

export default function CampaignDetails() {
  const axios = useAxios();
  const params = useParams();
  const { loader } = useComponent();

  const campaignID = params.campaignID || "";

  const { activeBrand, categories, userID, agent } = useSelector(
    (state) => state.memberDetails
  );

  if (agent?.length > 0) {
    createCampaignObject.agentId.value = agent;
  }

  const [audiences, setAudiences] = useState([]);
  const [editState, setEditState] = useState(true);
  const [audienceSize, setAudienceSize] = useState();
  const [connectionList, setConnectionList] = useState([]);
  const [audienceCreated, setAudienceCreated] = useState("");
  const [preferredChannels, setPreferedChannels] = useState([]);
  const [formData, setFormData] = useState(createCampaignObject);
  const [audienceData, setAudienceData] = useState(audienceObject);

  useEffect(() => {
    if (!!campaignID) {
      setEditState(false);
    }

    loader.start(campaignID ? 5 : 2);

    axios({ url: "/brand/getAllAudiences?brandId=" + activeBrand.ID }).then(
      (res) => {
        if (res.status) {
          let arr = [];

          for (let key of res.data) {
            let data = {};
            for (let fields of key.fields) {
              data[Object.keys(fields)[0]] = Object.values(fields)[0];
            }
            arr.push({
              ...data,
              audience_id: key.audience_id,
            });
          }
          setAudiences(arr);

          loader.apiComplete();
        } else {
          loader.apiComplete();
        }
      }
    );

    axios({
      url: "/get/campaign/preferredChannels",
    }).then((response) => {
      if (response.status) {
        setPreferedChannels(response.data);
        loader.apiComplete();
      } else {
        loader.apiComplete();
      }
    });

    if (!!campaignID) {
      let campaignData = {};
      axios({
        url:
          "/user/brand/getCampaign?brandId=" +
          parseInt(activeBrand.ID) +
          "&campaignId=" +
          parseInt(campaignID),
      }).then(async (response) => {
        if (response.status) {
          axios({
            url:
              "/brand/getDetailsOfAudience/" +
              response.data.audience.audience_id,
          }).then(async (res) => {
            if (res.status) {
              await setAudienceData(getAudienceDetails(res.data));
              loader.apiComplete(1);
            }
          });

          axios({
            url: "/get/audienceSizing",
            method: "POST",
            data: {
              brand_id: parseInt(activeBrand.ID),
              campaign_id: parseInt(campaignID),
              audience_id: parseInt(response.data.audience.audience_id),
            },
            disableRedirect: true,
            disableError: true,
          }).then((audienceSize) => {
            if (audienceSize.status) {
              setAudienceSize(audienceSize.data);
            }
          });

          await axios({
            url: "/user/brand/campaign/creatives?campaignId=" + campaignID,
          }).then(async (creatives) => {
            if (creatives.status) {
              campaignData = {
                ...response.data,
                creatives: creatives.data,
              };

              setConnectionList(
                connectionLists(response.data?.connections || [])
              );
              await setFormData(objectFromResponse(campaignData));
              loader.apiComplete(2);
            }
          });
        }
      });
    }
  }, [activeBrand.ID, axios, loader, campaignID, userID]);

  useEffect(() => {
    if (!!audienceCreated) {
      axios({ url: "/brand/getAllAudiences?brandId=" + activeBrand.ID }).then(
        (res) => {
          if (res.status) {
            let arr = [];

            for (let key of res.data) {
              let data = {};
              for (let fields of key.fields) {
                data[Object.keys(fields)[0]] = Object.values(fields)[0];
              }
              arr.push({
                ...data,
                audience_id: key.audience_id,
              });
            }
            setAudiences(arr);
          }
        }
      );
    }
  }, [audienceCreated, axios, activeBrand.ID]);

  return (
    <Loader>
      <Form
        audienceSize={audienceSize}
        formData={formData}
        audiences={audiences}
        editState={editState}
        categories={categories}
        setEditState={setEditState}
        audienceData={audienceData}
        connectionList={connectionList}
        setAudienceData={setAudienceData}
        preferredChannels={preferredChannels}
        setAudienceCreated={setAudienceCreated}
      />
    </Loader>
  );
}
