import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Grid, Avatar } from "@mui/material";

import {
  ActiveTextEighteen,
  HeadingEighteen,
  TextFourteenBlack,
  colors,
} from "../../css components/Style";
import { Paper } from "../../../components";
import { useAxios, useAlert } from "../../../hooks";
import { useNavigate } from "react-router-dom";
import pages from "../../../constants/pages";
import Dialog from "../../../components/Dialog";

export default function List({ brands, setBrandStatus }) {
  const axios = useAxios();
  const { alert } = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dialogState, setDialogState] = useState({
    state: false,
    data: {},
  });

  function handleBrandNavigation(brandID) {
    navigate(pages.unverifiedPageDetails.route + "?brandID=" + brandID);
  }

  return (
    <Grid container spacing={3}>
      {brands.map((brand) => (
        <Grid item xs={12}>
          <Paper>
            <Grid item xs={1}>
              <Avatar
                onClick={() => handleBrandNavigation(brand.brand_id)}
                src={brand.brand_logo}
                style={{ height: "60px", width: "60px", cursor: "pointer" }}
              />
            </Grid>

            <Grid item xs={3}>
              <TextFourteenBlack>Brand Name</TextFourteenBlack>
              <HeadingEighteen>
                {brand.brand_name.replace(/_/g, " ") || "-"}
              </HeadingEighteen>
            </Grid>

            <Grid item xs={3}>
              <TextFourteenBlack>Brand Status</TextFourteenBlack>
              <HeadingEighteen
                style={{
                  color:
                    brand.brand_verified_status === 0
                      ? colors.warning
                      : brand.brand_verified_status === 1
                      ? colors.success
                      : colors.error,
                }}
              >
                {brand.brand_verified_status === 0 && "Unverified"}
                {brand.brand_verified_status === 1 && "Verified"}
                {brand.brand_verified_status === 2 && "Rejected"}
              </HeadingEighteen>
            </Grid>

            <Grid item xs={2} />

            <Grid
              item
              xs={3}
              alignSelf={"end"}
              display="flex"
              justifyContent={"end"}
              sx={{
                cursor: "pointer",
              }}
            >
              <ActiveTextEighteen
                onClick={() => handleBrandNavigation(brand.brand_id)}
                color={colors.info}
                fontWeight={400}
              >
                View Brand
              </ActiveTextEighteen>
            </Grid>
          </Paper>
        </Grid>
      ))}

      <Dialog
        dialogState={dialogState}
        setDialogState={setDialogState}
        handleAgree={handleAgree}
        dialogContent={{
          title: "Do You want to reject this brand?",
          dangerButton: "Reject Brand",
        }}
      />
    </Grid>
  );
  function handleAgree(brand, status) {
    handleAction(dialogState.data?.brand, dialogState.data?.status);
  }

  function handleAction(brand, status) {
    axios({
      url: "/brand/verification/action",
      method: "POST",
      disableRedirect: true,
      data: {
        brand_id: brand.brand_id,
        brand_status: status,
      },
    }).then((response) => {
      if (response.status) {
        setBrandStatus(brand.brand_id);
        dispatch(
          alert({
            message: response.message.displayMessage,
            type: "success",
          })
        );
      }
    });
  }
}
