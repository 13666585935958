import * as Yup from "yup";

export const brandDetailsObject = {
  brandID: "",
  businessEntityId: "",
  brandName: "",
  website: "",
  profile: "",
  audienceLocation: [],
  audienceLanguages: [],
  socialMedia: {
    facebook: "",
    instagram: "",
    twitter: "",
    whatsapp: "",
  },
  brandAvatar: "",
  avatar: "",
  about: "",
  onlineStore: "",
  siteCategory: "",
  monthlyActiveUsers: "",
};

export function objectFromFormData(formData, businessEntityID) {
  let smh = {
    facebook: "",
    instagram: "",
    twitter: "",
    whatsapp: "",
  };

  let fb = formData.socialMedia?.facebook?.lastIndexOf("/");
  let ig = formData.socialMedia?.instagram?.lastIndexOf("/");
  let tw = formData.socialMedia?.twitter?.lastIndexOf("/");
  let wh = formData.socialMedia?.whatsapp?.lastIndexOf("/");

  smh.facebook = formData.socialMedia?.facebook?.substring(fb + 1);
  smh.instagram = formData.socialMedia?.instagram?.substring(ig + 1);
  smh.twitter = formData.socialMedia?.twitter?.substring(tw + 1);
  smh.whatsapp = formData.socialMedia?.whatsapp?.substring(wh + 1);

  return {
    business_entity_id: parseInt(businessEntityID),
    brand_name: formData.brandName,
    website: formData.website,
    audience_location: formData.audienceLocation,
    brandLogo: formData.brandAvatar,
    languages: formData.audienceLanguages,
    social_media_handles: smh,
    about: formData.about,
    online_store: formData.onlineStore,
    mau: parseInt(formData.monthlyActiveUsers.replace(/[,]/g, "")),
    site_category: formData.siteCategory,
    company_name: formData.companyName,
    gst: formData.GSTNumber,
    contact_no: formData.compacontactNumber,
    address: formData.companyAddress,
  };
}

export function objectFromResponse(response, memberDetails) {
  let medias = response?.social_media_handles;
  let fb = medias.facebook?.replace("@", "");
  let ig = medias.instagram?.replace("@", "");
  let tw = medias.twitter?.replace("@", "");

  return {
    brandAvatar: response.logo,
    brandID: response?.brand_id,
    businessEntityId: response.business_entity_id,
    brandName: response.brand_name,
    website: response.website,
    audienceLocation: response?.audience_location,
    audienceLanguages: response?.languages,
    siteCategory: response.site_category,
    socialMedia: {
      facebook: fb,
      instagram: ig,
      twitter: tw,
      whatsapp: medias.whatsapp,
    },
    about: response.about,
    onlineStore: response.online_store,
    monthlyActiveUsers:
      !response.mau || response.mau === "null" ? "" : response.mau,
    companyName: response?.company_details?.company_name,
    GSTNumber: response?.company_details?.gst,
    contactNumber: response?.company_details?.contact_no,
    companyAddress: response?.company_details?.address,
  };
}

export const validationSchema = Yup.object().shape({
  brandName: Yup.string().required("Brand name is required."),
  audienceLocation: Yup.array().required("Audience location is required."),
  audienceLanguages: Yup.array().required("Audience language is required."),
  brandAvatar: Yup.mixed().required("Brand Logo is required."),
  about: Yup.string().required("About is required."),
  siteCategory: Yup.array().required("Category is required."),
  mau: Yup.string().matches(/^\d+$/, "Mau should be number"),
  socialMedia: Yup.object().shape({
    facebook: Yup.string().matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.?[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Enter correct url!"
    ),
    instagram: Yup.string().matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.?[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Enter correct url!"
    ),
    twitter: Yup.string().matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.?[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Enter correct url!"
    ),
    whatsapp: Yup.string()
      .matches(/^\d+$/, "It should contain number only")
      .length(10, "Contact number should be of 10 digits"),
  }),
  website: Yup.string().matches(
    /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9:@%._?=/-]{2,}(\.[a-zA-Z0-9:@%._?=/-]{2,})(\.[a-zA-Z0-9:@%._?=/-]{2,})?/,
    "Enter correct url!"
  ),
  onlineStore: Yup.string().matches(
    /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9:@%._?=/-]{2,}(\.[a-zA-Z0-9:@%._?=/-]{2,})(\.[a-zA-Z0-9:@%._?=/-]{2,})?/,
    "Enter correct url!"
  ),
});
