import { Grid, Typography } from "@mui/material";
import styled from "styled-components/macro";
import { Fragment, useEffect, useState } from "react";

import Transaction from "./Transaction";
import { Loader, PageHeader } from "../../components";
import { Pending } from "../css components/Button";
import { colors } from "../css components/Style";
import AddMoneyDialog from "./AddMoneyDialog";
import { useAxios, useComponent } from "../../hooks";

const CreditCard = styled(Grid)`
  padding: 20px;
  background-color: #fef5e5;
  border-radius: 10px;
`;

const CreditHeading = styled(Typography)`
  font-size: 24px;
  font-weight: 600;
  color: ${colors.warning};
`;

export default function Credit() {
  const axios = useAxios();
  const { loader } = useComponent();

  const [addMoney, setAddMoney] = useState(false);
  const [creditInfo, setCreditInfo] = useState({
    coins: "0",
    creditHistory: [],
  });
  const [creditsAdded, setCreditAdded] = useState(false);

  useEffect(() => {
    loader.start();

    axios({
      url: "/GetWalletDetails",
    }).then((response) => {
      if (response.status) {
        setCreditInfo({
          coins: response.data.credits[0]?.coins || "0",
          creditHistory: response.data.credits[0]?.WalletHistory,
        });

        loader.stop();
      } else {
        loader.stop();
      }
    });
  }, [axios, loader]);

  useEffect(() => {
    if (creditsAdded) {
      axios({
        url: "/GetWalletDetails",
      }).then((response) => {
        if (response.status) {
          setCreditInfo({
            coins: response.data.credits[0]?.coins || "0",
            creditHistory: response.data.credits[0]?.WalletHistory,
          });
          setCreditAdded(false);
        }
      });
    }
  }, [creditsAdded, axios]);

  return (
    <Fragment>
      <Loader>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <PageHeader pageName={"My Credits"} />
          </Grid>

          <Grid item xs={12}>
            <CreditCard container spacing={2}>
              <Grid item xs={8}>
                <CreditHeading>{creditInfo.coins}</CreditHeading>
                <Typography style={{ color: colors.warning, fontSize: "15px" }}>
                  Current Credits
                </Typography>
              </Grid>

              <Grid item xs={4} style={{ textAlign: "end" }}>
                <Pending onClick={() => setAddMoney(true)} variant="contained">
                  Add Credits
                </Pending>
              </Grid>
            </CreditCard>
          </Grid>

          <Grid item xs={12}>
            <Transaction creditInfo={creditInfo} />
          </Grid>
        </Grid>
      </Loader>

      <AddMoneyDialog
        setCreditAdded={setCreditAdded}
        addMoney={addMoney}
        setAddMoney={setAddMoney}
      />
    </Fragment>
  );
}
