import React, { Fragment } from "react";

import { Grid, Typography } from "@mui/material";

import { TextField, Label } from "../../../../components";

export default function AddButtonVariable({ formikProps }) {
  return (
    <React.Fragment>
      {formikProps.values.templateButtons?.count_of_variable !== 0 && (
        <Grid item xs={12}>
          <Typography variant="caption1">Button Variable</Typography>
        </Grid>
      )}

      {formikProps.values.templateButtons?.buttons?.map((item, index) => (
        <React.Fragment key={index}>
          {(item.url_type === "dynamic" || item.url_type === "Dynamic") && (
            <Fragment>
              <Grid item xs={4}>
                <Label
                  title={"Body Variable Value " + (index + 1)}
                  formikProps={formikProps}
                  required={true}
                  name={"templateButtons.buttons[" + index + "].example"}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  formikProps={formikProps}
                  disabled={Boolean(formikProps.values.ID)}
                  name={"templateButtons.buttons[" + index + "].example"}
                />
              </Grid>
            </Fragment>
          )}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}
