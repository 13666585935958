import { TbExternalLink } from "react-icons/tb";
import { Grid, IconButton } from "@mui/material";

import { FileUpload, Label, Paper, TextField } from "../../../components";

export default function FormThree({ formikProps, editState }) {
  return (
    <Paper>
      <Grid item xs={12}>
        <Label
          title="Company GST Number"
          formikProps={formikProps}
          name={"companyGSTNumber"}
        />
      </Grid>

      <Grid item xs={10}>
        <TextField
          name="companyGSTNumber"
          placeholder="Company GST Number"
          formikProps={formikProps}
          disabled={!editState}
          inputProps={{ "aria-label": "Without label" }}
        />
      </Grid>

      <Grid item xs={12}>
        <Label
          title="Company GST Certificate"
          formikProps={formikProps}
          required={true}
          name={"gstCertificate"}
        />
      </Grid>

      <Grid item xs={10}>
        <FileUpload
          name="gstCertificate"
          placeholder="Upload Company GST Certificate"
          formikProps={formikProps}
          disabled={!editState}
          allowedExtension=".jpg,.jpeg,.png,.pdf"
          folderPath={"company_document/" + new Date().getTime() + "_"}
          inputProps={{ "aria-label": "Without label" }}
          previewIcon={true}
        />
      </Grid>

      <Grid item xs={12}>
        <Label
          title="Company PAN Number Certificate"
          formikProps={formikProps}
          required={true}
          name={"companyPAN"}
        />
      </Grid>

      <Grid item xs={10}>
        <FileUpload
          name="companyPanCertificate"
          placeholder="Upload Company PAN Certificate"
          formikProps={formikProps}
          disabled={!editState}
          allowedExtension=".jpg,.jpeg,.png,.pdf"
          folderPath={"company_document/" + new Date().getTime() + "_"}
          inputProps={{ "aria-label": "Without label" }}
          previewIcon={true}
        />
      </Grid>

      <Grid item xs={12}>
        <Label
          title="Company Director's PAN Certificate"
          formikProps={formikProps}
          required={true}
          name={"directorPanCertificate"}
        />
      </Grid>

      <Grid item xs={10}>
        <FileUpload
          name="directorPanCertificate"
          placeholder="Upload Company Director's PAN Certificate"
          formikProps={formikProps}
          disabled={!editState}
          allowedExtension=".jpg,.jpeg,.png,.pdf"
          folderPath={"company_document/" + new Date().getTime() + "_"}
          inputProps={{ "aria-label": "Without label" }}
          previewIcon={true}
        />
      </Grid>

      <Grid item xs={12}>
        <Label
          title="Company Director Aadhar Certificate"
          formikProps={formikProps}
          required={true}
          name={"directorAadharCertificate"}
        />
      </Grid>

      <Grid item xs={10}>
        <FileUpload
          name="directorAadharCertificate"
          placeholder="Upload Company Director Aadhar Certificate"
          formikProps={formikProps}
          disabled={!editState}
          allowedExtension=".jpg,.jpeg,.png,.pdf"
          folderPath={"company_document/" + new Date().getTime() + "_"}
          inputProps={{ "aria-label": "Without label" }}
          previewIcon={true}
        />
      </Grid>
    </Paper>
  );
}
