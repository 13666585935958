import { Grid } from "@mui/material";
import { Label, TextField } from "../../../components";
import { Fragment } from "react";

export default function AudienceSizeForm({ formikProps, component }) {
  return (
    <Fragment>
      <Grid item xs={12}>
        <Label title="Final Audience Parameters" />
      </Grid>

      <Grid item xs={12}>
        <TextField
          onChange={(e) =>
            formikProps.setValues((prev) => ({
              ...prev,
              audience_params: e.target.value,
            }))
          }
          disabled={component === "consumer"}
          minRows={5}
          formikProps={formikProps}
          name="audience_params"
          multiline
        />
      </Grid>

      <Grid item xs={12}>
        <Label title="Total Audience Size" />
      </Grid>

      <Grid item xs={12}>
        <TextField
          disabled={component === "consumer"}
          formikProps={formikProps}
          name="audience_size"
        />
      </Grid>

      <Grid item xs={12}>
        <Label title="Audience Pricing" />
      </Grid>

      <Grid item xs={12}>
        <TextField
          disabled={component === "consumer"}
          formikProps={formikProps}
          name="audience_pricing"
        />
      </Grid>
    </Fragment>
  );
}
