import React from "react";
import styled from "styled-components/macro";
import { Avatar, Grid } from "@mui/material";

import { Label, Paper, TextField } from "../../../components";

const ImageAvatar = styled(Avatar)`
  border: 1px solid #999999;
  height: 120px;
  width: 120px;
  border-radius: 50%;
`;

export default function FormOne({ formData }) {
  return (
    <React.Fragment>
      <Paper>
        <Grid item xs={12} textAlign="center" display="flex">
          <ImageAvatar src={formData.brandAvatar} />
        </Grid>

        <Grid item xs={12}>
          <Label title={"Brand Name"} />
        </Grid>

        <Grid item xs={10}>
          <TextField
            value={formData.brandName}
            disabled
            placeholder="Brand Name"
            name="brandName"
            inputProps={{ "aria-label": "Without label" }}
          />
        </Grid>

        <Grid item xs={12}>
          <Label title="About" />
        </Grid>

        <Grid item xs={10}>
          <TextField
            value={formData.about}
            disabled
            placeholder="About"
            name="about"
            rows={5}
            multiline
            inputProps={{ "aria-label": "Without label" }}
          />
        </Grid>

        <Grid item xs={12}>
          <Label title={"Online Store"} />
        </Grid>

        <Grid item xs={10}>
          <TextField
            value={formData.onlineStore}
            disabled
            name="onlineStore"
            placeholder="Online Store"
            inputProps={{ "aria-label": "Without label" }}
          />
        </Grid>

        <Grid item xs={12}>
          <Label title={"Website"} />
        </Grid>

        <Grid item xs={10}>
          <TextField
            value={formData.website}
            disabled
            name="website"
            placeholder="Website"
            inputProps={{ "aria-label": "Without label" }}
          />
        </Grid>

        <Grid item xs={12}>
          <Label title="Monthly Active Users" />
        </Grid>

        <Grid item xs={10}>
          <TextField
            disabled
            value={formData.monthlyActiveUsers}
            name="monthlyActiveUsers"
            placeholder="Monthly Active Users"
            inputProps={{ "aria-label": "Without label" }}
          />
        </Grid>
      </Paper>
    </React.Fragment>
  );
}
