import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  templateName: Yup.string().max(100).required("Enter template name."),
  templateLanguage: Yup.object({
    code_id: Yup.string().required("Select template language."),
  }),

  category: Yup.string().required("Category is required"),
  status: Yup.string().required("Select the status"),

  templateHeader: Yup.object().shape({
    type: Yup.mixed(),
    count_of_variable: Yup.number(),
    text: Yup.mixed().when("type", {
      is: (type) => type === "text",
      then: Yup.string().required("Text is required"),
    }),
    example: Yup.array(
      Yup.object().shape({
        example: Yup.string().when("count_of_variable", {
          is: (number) => number > 0,
          then: Yup.string().required("Fill sample values."),
        }),
      })
    ),
  }),

  templateBody: Yup.object().shape({
    text: Yup.string().required("Body is required"),
    count_of_variable: Yup.number(),
    example: Yup.array(
      Yup.object().shape({
        example: Yup.string().when("count_of_variable", {
          is: (number) => number > 0,
          then: Yup.string().required("Fill sample values."),
        }),
      })
    ),
  }),

  templateButtons: Yup.object().shape({
    type: Yup.mixed(),
    count_of_variable: Yup.number(),
    buttons: Yup.array(
      Yup.object().shape({
        button_type: Yup.string().when("type", {
          is: (type) => type === "call_to_action",
          then: Yup.string().required("Select the action type"),
        }),
        text: Yup.string().required("Button text is required"),
        url_type: Yup.string().when("button_type", {
          is: (type) => type === "url",
          then: Yup.string().required("Select the url type"),
        }),
        payload: Yup.string().when("url_type", {
          is: (type) => type === "static",
          then: Yup.string()
            .matches(
              /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\\?[a-zA-Z-_%\\/@?]+)*([^\\/\w\\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
              "Enter correct url."
            )
            .required("This is the required field."),
        }),
      })
    ),
    example: Yup.array(
      Yup.object().shape({
        example: Yup.string().when("count_of_variable", {
          is: (number) => number > 0,
          then: Yup.string().required("Fill sample values."),
        }),
      })
    ),
  }),
});

export default validationSchema;
