import React, { useState } from "react";
import {
  Dialog as MUIDialog,
  DialogContent,
  IconButton,
  Grid,
  Divider,
} from "@mui/material";
import { HighlightOff } from "@mui/icons-material";

import { ActiveTextEighteen, colors } from "../../css components/Style";
import AudienceSizeForm from "../superadmin campaign details component/AudienceSizeForm";

export default function ShowAudienceSizeDialog({
  showAudSize,
  audienceSize,
  setShowAudSize,
}) {
  const [audienceSizing] = useState({
    values: {
      audience_params: audienceSize?.audience_params,
      audience_size: audienceSize?.audience_size,
      audience_pricing: audienceSize?.audience_pricing,
    },
  });

  const handleClose = () => {
    setShowAudSize(false);
  };

  return (
    <MUIDialog open={showAudSize} onClose={handleClose}>
      <Grid container spacing={2} padding={"8px 24px"}>
        <Grid item xs={6} alignSelf={"center"}>
          <ActiveTextEighteen color={colors.info}>
            Audience Size
          </ActiveTextEighteen>
        </Grid>

        <Grid item xs={6} alignSelf={"center"} textAlign={"end"}>
          <IconButton>
            <HighlightOff onClick={() => handleClose()} />
          </IconButton>
        </Grid>
      </Grid>

      <Divider />

      <DialogContent style={{ padding: "24px" }}>
        <Grid container spacing={2}>
          <AudienceSizeForm formikProps={audienceSizing} component="consumer" />
        </Grid>
      </DialogContent>
    </MUIDialog>
  );
}
