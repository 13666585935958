import { Fragment } from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

import FormOne from "./FormOne";
import FormTwo from "./FormTwo";
import { PageHeader, Paper } from "../../../components";
import { ActiveTextEighteen, colors } from "../../css components/Style";
import { CancelButton } from "../../css components/Button";

export default function FormDisplay({ formData, noDetails }) {
  const navigate = useNavigate();
  return (
    <Fragment>
      <Grid item xs={12}>
        <PageHeader
          heading={"Company Profile"}
          twoText={true}
          firstText={formData.companyName}
          secondText={""}
          fontSize="25px"
          buttons={[
            <CancelButton variant="outlined" onClick={() => navigate(-1)}>
              Back
            </CancelButton>,
          ]}
        />
      </Grid>

      {noDetails && (
        <Paper>
          <Grid item xs={12} alignSelf={"center"}>
            <ActiveTextEighteen color={colors.error}>
              Brand hasn't registered it's company yet
            </ActiveTextEighteen>
          </Grid>
        </Paper>
      )}

      <Grid container spacing={8}>
        <Grid item xs={6}>
          <FormOne formData={formData} />
        </Grid>

        <Grid item xs={6}>
          <FormTwo formData={formData} />
        </Grid>
      </Grid>
    </Fragment>
  );
}
