import { TbExternalLink } from "react-icons/tb";
import { Grid, IconButton } from "@mui/material";

import { Label, Paper, TextField } from "../../../components";

export default function FormOne({ formData }) {
  return (
    <Paper>
      <Grid item xs={12}>
        <Label title="Business Category" />
      </Grid>

      <Grid item xs={10}>
        <TextField
          multiline
          name="siteCategory"
          disabled
          value={formData.siteCategory.map((cat) => cat.name).join(",")}
        />
      </Grid>

      <Grid item xs={10}>
        <Label title={"Location"} />
      </Grid>

      <Grid item xs={10}>
        <TextField
          multiline
          name="audienceLocation"
          disabled
          value={formData.audienceLocation
            .map((loc) => loc.country_name)
            .join(",")}
        />
      </Grid>

      <Grid item xs={10}>
        <Label title={"Language"} />
      </Grid>

      <Grid item xs={10}>
        <TextField
          multiline
          name="audienceLanguages"
          disabled
          value={formData.audienceLanguages
            .map((lan) => lan.language)
            .join(",")}
        />
      </Grid>

      <Grid item xs={12}>
        <Label title="Social Media Handles" />
      </Grid>

      <Grid item xs={12}>
        <Label title={"Facebook"} />
      </Grid>

      <Grid item xs={10}>
        <TextField
          disabled
          name="socialMedia.facebook"
          inputProps={{ "aria-label": "Without label" }}
          value={formData.socialMedia.facebook}
          InputProps={{
            endAdornment: !!formData.socialMedia.facebook && (
              <IconButton
                onClick={() =>
                  linkOpen(
                    "https://facebook.com/" +
                      !!formData.socialMedia.facebook.replace(
                        /(?:https?|ftp):\/\/[\n\S]+/g,
                        ""
                      )
                  )
                }
              >
                <TbExternalLink />
              </IconButton>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Label title={"Instagram"} />
      </Grid>

      <Grid item xs={10}>
        <TextField
          disabled
          name="socialMedia.instagram"
          value={formData.socialMedia.instagram}
          inputProps={{ "aria-label": "Without label" }}
          InputProps={{
            endAdornment: !!formData.socialMedia.instagram && (
              <IconButton
                onClick={() =>
                  linkOpen(
                    "https://instagram.com/" +
                      !!formData.socialMedia.instagram.replace(
                        /(?:https?|ftp):\/\/[\n\S]+/g,
                        ""
                      )
                  )
                }
              >
                <TbExternalLink />
              </IconButton>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Label title={"Twitter"} />
      </Grid>

      <Grid item xs={10}>
        <TextField
          disabled
          name="socialMedia.twitter"
          value={formData.socialMedia.twitter}
          inputProps={{ "aria-label": "Without label" }}
          InputProps={{
            endAdornment: !!formData.socialMedia.twitter && (
              <IconButton
                onClick={() =>
                  linkOpen(
                    "https://twitter.com/" +
                      !!formData.socialMedia.twitter.replace(
                        /(?:https?|ftp):\/\/[\n\S]+/g,
                        ""
                      )
                  )
                }
              >
                <TbExternalLink />
              </IconButton>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Label title={"Whatsapp"} />
      </Grid>

      <Grid item xs={10}>
        <TextField
          disabled
          value={formData.socialMedia.whatsapp}
          name="socialMedia.whatsapp"
          inputProps={{ "aria-label": "Without label" }}
          InputProps={{
            endAdornment: !!formData.socialMedia.whatsapp && (
              <IconButton
                onClick={() =>
                  linkOpen("https://wa.me/" + !!formData.socialMedia.whatsapp)
                }
              >
                <TbExternalLink />
              </IconButton>
            ),
          }}
        />
      </Grid>
    </Paper>
  );

  function linkOpen(val) {
    window.open(val, "_blank");
  }
}
