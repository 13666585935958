import { Fragment, useState } from "react";
import styled from "styled-components/macro";
import { Grid, Typography } from "@mui/material";
import { IoIosArrowForward } from "react-icons/io";

import { Paper, TextField } from "../../../components";
import CreativeDialog from "./creative components/CreativeDialog";
import { PrimaryButton } from "../../css components/Button";
import { HeadingEighteen, colors } from "../../css components/Style";

const MainHeading = styled(Typography)`
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  color: #02a0fc;
`;

const Link = styled(Typography)`
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #02a0fc;
  cursor: pointer;
`;

const ArrowIcon = styled(IoIosArrowForward)`
  position: relative;
  top: 6px;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #02a0fc;
  cursor: pointer;
`;

export default function FormFifth({ formikProps, component }) {
  const [editState, setEditState] = useState(true);

  const [detail, setDetail] = useState({
    state: false,
    campaignDetails: formikProps.values,
    data: {
      format: "",
      creative: "",
      fileUrl: "",
      friendlyName: "",
      fileName: "",
      adSize: "",
      adTag: "",
    },
    id: "",
  });

  return (
    <Fragment>
      <Paper style={{ marginTop: "25px" }}>
        <Grid item xs={12}>
          <MainHeading>Creative</MainHeading>
        </Grid>

        {formikProps.values.creatives.length === 0 ? (
          <Grid item xs={12}>
            <HeadingEighteen color={colors.info}>
              No creative yet!
            </HeadingEighteen>
          </Grid>
        ) : (
          formikProps.values.creatives.map((creative) => (
            <Fragment key={creative.creativeID}>
              <Grid item xs={8}>
                <TextField value={creative.friendlyName} disabled />
              </Grid>

              <Grid
                item
                xs={2}
                display="flex"
                alignSelf="center"
                justifyContent="end"
              >
                <Link onClick={() => viewCreative(creative)}>View</Link>{" "}
                <ArrowIcon />
              </Grid>
            </Fragment>
          ))
        )}

        {component !== "superadmin" && component !== "request" && (
          <Grid item xs={12}>
            <PrimaryButton variant="contained" onClick={() => addCreative()}>
              Add Creative
            </PrimaryButton>
          </Grid>
        )}
      </Paper>

      <CreativeDialog
        formikProps={formikProps}
        detail={detail}
        setDetail={setDetail}
        editState={editState}
        setEditState={setEditState}
        component={component}
      />
    </Fragment>
  );

  function addCreative() {
    setEditState(true);
    setDetail((prev) => ({
      ...prev,
      data: {
        format: "",
        creative: "",
        fileUrl: "",
        friendlyName: "",
        fileName: "",
        adSize: "",
        adTag: "",
      },
      state: true,
      campaignDetails: formikProps.values,
      id: "",
    }));
  }

  function viewCreative(creatives) {
    setEditState(false);
    setDetail((prev) => ({
      ...prev,
      state: true,
      campaignDetails: formikProps.values,
      id: creatives.creativeID.toString(),
      data: {
        creativeID: creatives.creativeID,
        format: creatives.format || "",
        creative: creatives.creative || "",
        friendlyName: creatives.friendlyName || "",
        adSize: creatives.adSize || "",
        adTag: creatives.adTag || "",
        sourceType: creatives.sourceType || "",
      },
    }));
  }
}
