import React from "react";
import { Grid, InputAdornment } from "@mui/material";
import "../../whatsapp interface/WhatsappPreview.css";

import {
  ComponentHeader,
  Paper,
  TextField,
  Label,
} from "../../../../components";

export default function TemplateFooter({ formikProps, addSample }) {
  return (
    <Paper>
      <Grid style={{ alignSelf: "center" }} item xs={12}>
        <ComponentHeader
          title={"Template Footer"}
          subtitle={
            " Add a short line of text to the bottom of your template message(Optional)."
          }
        />
      </Grid>

      <Grid item xs={6}>
        <Label
          title="Footer Text"
          formikProps={formikProps}
          required={true}
          name={"templateFooter.text"}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          name="templateFooter.text"
          formikProps={formikProps}
          disabled={Boolean(formikProps.values.ID) || Boolean(addSample)}
          inputProps={{
            maxLength: 60,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {formikProps.values?.templateFooter?.text?.length}/60
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Paper>
  );
}
