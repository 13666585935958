import { Fragment } from "react";
import styled from "styled-components/macro";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { MenuItem } from "@mui/material";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { TextField } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { signInMember } from "../../redux/slices/memberSlice";
import pages from "../../constants/pages";
import { useNavigate } from "react-router-dom";
import { CancelButton, PrimaryLoadingButton } from "../css components/Button";

const Image = styled.div`
  width: 120px;
  height: 120px;
  align-self: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 10px;
`;

export default function SelectRole({
  businessEntityID,
  brands,
  authToken,
  userID,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { dashboardState, brandType, activeBrand } = useSelector(
    (state) => state.memberDetails
  );

  const [currentBrand, setCurrentBrand] = useState({
    ID: activeBrand.ID,
    name: activeBrand.name,
    type: activeBrand.brandType || brandType,
  });

  const [userDashboardState, setUserDashboardState] = useState(
    dashboardState || "monetization"
  );

  return (
    <Fragment>
      <div className="select-brand-div">
        <Swiper
          slidesPerView={3}
          spaceBetween={1}
          navigation={true}
          modules={[Navigation]}
          className="mySwiper"
        >
          {brands.map((brand) => (
            <SwiperSlide key={brand.ID}>
              <Image
                style={{
                  cursor: "pointer",
                  border: currentBrand.ID === brand.ID && "3px solid #02a0fc",
                }}
                onClick={() =>
                  setBrand(brand.ID, brand.brandName, brand.brandType)
                }
              >
                <img className="img-logo" src={brand.logo} alt="logo" />
              </Image>
            </SwiperSlide>
          ))}
        </Swiper>

        <div className="choose-ds">
          <TextField
            select
            placeholder="Select Role"
            inputProps={{ "aria-label": "Without label" }}
            name="role"
            value={userDashboardState}
            onChange={setDashboardUser}
            disabled={currentBrand.type !== 3}
          >
            <MenuItem value={`promotion`}>I am a Promotion Partner</MenuItem>
            <MenuItem value={`monetization`}>
              I am a Monetization Partner
            </MenuItem>
          </TextField>
        </div>
      </div>

      <div className="bottom-div">
        {/* <div>
          <CancelButton style={{ float: "left" }} variant="outlined">
            Logout
          </CancelButton>
        </div> */}

        <div style={{ gap: 5 }}>
          {/* <PrimaryButton
            style={{ float: "left", marginRight: "20px" }}
            variant="outlined"
          >
            Add New Brand
          </PrimaryButton> */}

          <PrimaryLoadingButton
            style={{ float: "right" }}
            type="submit"
            variant="contained"
            disabled={!currentBrand.ID}
            onClick={async () => {
              localStorage.setItem(
                "dashboardState" + currentBrand.ID,
                userDashboardState
              );
              await dispatch(
                signInMember({
                  authToken: authToken,
                  userID: userID,
                  businessEntityId: businessEntityID,
                  brandType: currentBrand.type,
                })
              );

              navigate(pages.brandDetails.route);
            }}
          >
            Let's Go
          </PrimaryLoadingButton>
        </div>
      </div>
    </Fragment>
  );

  // async function addNewBrand() {
  //   // localStorage.setItem("dashboardState" + currentBrand.ID, "monetization");
  //   // dispatch(setDashboardState("monetization"));
  //   // navigate(pages.newBrandCreate.route);
  // }

  function setBrand(ID, name, type) {
    if (type === 2) {
      setUserDashboardState("monetization");
    }
    setCurrentBrand({
      ID: ID,
      name: name,
      type: type,
    });
    sessionStorage.setItem("activeBrand", ID);
    sessionStorage.setItem("brandType", type);
  }

  function setDashboardUser(e) {
    setUserDashboardState(e.target.value);
  }
}
