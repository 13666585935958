import { Fragment, useRef, useState } from "react";

import { Grid, Button, Typography } from "@mui/material";

import FormOne from "./FormOne";
import FormTwo from "./FormTwo";
import { PageHeader } from "../../../components";
import Buttons from "../../../components/Buttons";
import FormThree from "./FormThree";

export default function FormDisplay({
  edit,
  fields,
  setEdit,
  editState,
  setEditState,
  formikProps,
  component,
  categories,
  location,
  languages,
  sliderValue,
  setSliderValue,
  dialogDetails,
  percentageSliderValue,
  setPercentageSliderValue,
}) {
  let mandatoryFalseCount = 0;

  for (const key in formikProps.values) {
    if (
      formikProps.values.hasOwnProperty(key) &&
      typeof formikProps.values[key] === "object" &&
      formikProps.values[key].hasOwnProperty("mandatory") &&
      formikProps.values[key]?.value !== undefined &&
      formikProps.values[key]?.value !== null &&
      formikProps.values[key]?.value !== "" &&
      formikProps.values[key]?.value.length !== 0 &&
      formikProps.values[key]?.value.min !== ""
    ) {
      if (
        key !== "audience_group" &&
        key !== "percentage" &&
        key !== "approx_mau"
      ) {
        if (formikProps.values[key].mandatory === false) {
          mandatoryFalseCount++;
        }
      }
    }
  }

  let count = useRef(true);
  let [oldMandatoryFalseCount, setOldMandatoryFalseCount] = useState(0);

  return (
    <Fragment>
      <Grid item xs={12}>
        {component !== "dialog" && (
          <PageHeader
            subHeading={"My Audience"}
            twoText={!formikProps.values.ID && true}
            firstText={"Create Audience. "}
            secondText={"Get to them."}
            pageName={
              !!formikProps.values.ID && formikProps.values.audience_group.value
            }
            buttons={[
              component !== "dialog" && formikProps.values.isEditable && (
                <Buttons
                  ID={formikProps.values.ID}
                  formikProps={formikProps}
                  editState={editState}
                  setEditState={setEditState}
                  condition={edit}
                />
              ),
            ]}
          />
        )}
      </Grid>

      <Grid container spacing={8}>
        {component === "dialog" && (
          <Fragment>
            <Grid item xs={6}>
              <Typography
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "#02a0fc",
                }}
              >
                Audience Details
              </Typography>
            </Grid>

            <Grid item xs={6} textAlign="end">
              {dialogDetails.open === "create" && (
                <Button
                  size="large"
                  variant="contained"
                  style={{ borderRadius: "40px" }}
                  onClick={formikProps.handleSubmit}
                  disabled={!formikProps.isValid}
                >
                  Save
                </Button>
              )}
            </Grid>
          </Fragment>
        )}
        <Grid item xs={6}>
          <FormOne
            fields={fields}
            formikProps={formikProps}
            editState={editState}
            setEditState={setEditState}
            edit={edit}
            setEdit={setEdit}
            mandatoryFalseCount={mandatoryFalseCount}
            percentageSliderValue={percentageSliderValue}
            count={count}
            setPercentageSliderValue={setPercentageSliderValue}
            oldMandatoryFalseCount={oldMandatoryFalseCount}
            setOldMandatoryFalseCount={setOldMandatoryFalseCount}
          />
        </Grid>

        <Grid item xs={6}>
          <FormTwo
            fields={fields}
            categories={categories}
            location={location}
            languages={languages}
            formikProps={formikProps}
            editState={editState}
            setEditState={setEditState}
            setEdit={setEdit}
            sliderValue={sliderValue}
            setSliderValue={setSliderValue}
            mandatoryFalseCount={mandatoryFalseCount}
            percentageSliderValue={percentageSliderValue}
            count={count}
            setPercentageSliderValue={setPercentageSliderValue}
            oldMandatoryFalseCount={oldMandatoryFalseCount}
            setOldMandatoryFalseCount={setOldMandatoryFalseCount}
          />

          <FormThree formikProps={formikProps} editState={editState} />
        </Grid>
      </Grid>
    </Fragment>
  );
}
