import { Fragment } from "react";
import { titleCase } from "change-case-all";
import styled from "styled-components/macro";
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";

import pages from "../../../constants/pages";
import { Paper, TextAvatar } from "../../../components";
import { HeadingEighteen, TextFourteenBlack } from "../../css components/Style";

const Link = styled(Typography)`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #02a0fc;
`;

const ArrowIcon = styled(IoIosArrowForward)`
  position: relative;
  top: 6px;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #02a0fc;
  cursor: pointer;
`;

export default function List({ audiences }) {
  const navigate = useNavigate();

  return (
    <Grid container spacing={3}>
      {audiences?.map((audience, index) => (
        <Grid item xs={12} key={index}>
          <Paper>
            <Grid item alignSelf="center">
              <TextAvatar
                size={{ height: "50px", width: "50px" }}
                userName={audience?.audience_group}
              />
            </Grid>

            <Grid item xs>
              <Grid container spacing={3} display={"flex"} gap={"4%"}>
                <Grid item xs={2}>
                  <TextFourteenBlack>Audience Group</TextFourteenBlack>

                  <HeadingEighteen>{audience.audience_group}</HeadingEighteen>
                </Grid>

                {Object.keys(audience).map(
                  (aud, index) =>
                    index < 4 &&
                    aud !== "audience_group" &&
                    aud !== "audience_id" && (
                      <Fragment>
                        <Grid item xs={2}>
                          <TextFourteenBlack>
                            {titleCase(aud.replace(/_/g, " "))}
                          </TextFourteenBlack>
                          <HeadingEighteen>
                            {Array.isArray(audience[aud])
                              ? audience[aud]
                                  .slice(0, 3)
                                  .map((item, index) => item)
                                  .join(", ")
                              : typeof audience[aud] === "object" &&
                                !Array.isArray(audience[aud])
                              ? `${audience[aud].min} -  ${audience[aud].max}`
                              : audience[aud]}
                          </HeadingEighteen>
                        </Grid>
                      </Fragment>
                    )
                )}
              </Grid>
            </Grid>

            <Grid
              item
              xs={2}
              textAlign={"end"}
              alignSelf={"end"}
              display={"flex"}
              justifyContent={"end"}
            >
              <Link
                style={{ cursor: "pointer", paddingRight: "0px" }}
                onClick={() =>
                  navigate(pages.audienceDetails.route + audience.audience_id)
                }
              >
                View
              </Link>
              <ArrowIcon />
            </Grid>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
}
