import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Loader } from "../../components";
import { useAxios, useComponent } from "../../hooks";
import Form from "./connection details components/Form";
import {
  connectionObject,
  objectFromResponse,
} from "./connection details components/connectionObject";

export default function ConnectionDetails() {
  const axios = useAxios();
  const params = useParams();
  const { loader } = useComponent();

  const connectionID = params.connectionID || "";
  const [editState, setEditState] = useState(true);
  const [formData, setFormData] = useState(connectionObject);
  const { categories, location, languages } = useSelector(
    (state) => state.memberDetails
  );

  useEffect(() => {
    loader.start(1);

    // if (!!connectionID) {
    //   setEditState(false);

    //   axios({
    //     url: "/brand/getDetailsOfAudience/" + connectionID,
    //   }).then((response) => {
    //     if (response.status) {
    //       loader.apiComplete();
    //       setFormData(objectFromResponse(response.data));
    //     }
    //   });
    // } else {
    //   loader.stop();
    // }
  }, [axios, loader, connectionID]);

  return (
    <Loader>
      <Form
        formData={formData}
        editState={editState}
        setEditState={setEditState}
      />
    </Loader>
  );
}
