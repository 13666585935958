import { TextField as MuiTextField } from "@mui/material";
import styled from "styled-components/macro";
import { get } from "lodash";

const TextFieldWithCSS = styled(MuiTextField)`
  .MuiOutlinedInput-notchedOutline {
    border-color: #02a0fc;

    border-radius: 5px;
  }

  .MuiOutlinedInput-root {
    font-size: 18px;
  }

  .Mui-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.7);
    background-color: #f5f5f5;
    font-size: 18px;
    border-radius: 5px;
  }

  .Mui-disabled .MuiOutlinedInput-notchedOutline {
    border-color: #f5f5f5;
  }

  .MuiInputLabel-asterisk {
    color: ${(props) => (Boolean(props.required && !props.value) ? "red" : "")};
  }
`;

export default function TextField({ formikProps, ...props }) {
  if (!!formikProps) {
    function handleTouch() {
      if (Object.keys(formikProps?.touched).length > 0) {
        for (let field in formikProps.touched) {
          if (field !== props.name) {
            formikProps.setTouched({
              ...formikProps.touched,
              [props.name]: true,
            });
          } else {
            formikProps.setTouched({ [props.name]: true });
          }
        }
      } else {
        formikProps.setTouched({ [props.name]: true });
      }
    }

    return (
      <TextFieldWithCSS
        onClick={handleTouch}
        onChange={formikProps.handleChange}
        error={
          get(formikProps.touched, props.name) && props.select
            ? Boolean(
                !get(formikProps.values, props.name) &&
                  get(formikProps.errors, props.name)
              )
            : !!get(formikProps.touched, props.name) &&
              get(formikProps.errors, props.name)
        }
        helperText={
          get(formikProps.touched, props.name) && props.select
            ? !get(formikProps.values, props.name) &&
              get(formikProps.errors, props.name)
            : !!get(formikProps.touched, props.name) &&
              get(formikProps.errors, props.name)
        }
        onBlur={formikProps.handleBlur}
        value={get(formikProps.values, props.name, "")}
        fullWidth
        variant="outlined"
        {...props}
      />
    );
  }

  return <TextFieldWithCSS variant="outlined" fullWidth {...props} />;
}
