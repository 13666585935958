import { TbExternalLink } from "react-icons/tb";
import { Grid, IconButton } from "@mui/material";

import {
  Label,
  Paper,
  TextField,
  AutocompleteMultiSelect,
} from "../../../components";

export default function FormOne({ list, formikProps, editState }) {
  return (
    <Paper>
      <Grid item xs={12}>
        <Label
          title="Business Category"
          formikProps={formikProps}
          required={true}
          name={"siteCategory"}
        />
      </Grid>

      <Grid item xs={10}>
        <AutocompleteMultiSelect
          name="siteCategory"
          disabled={!editState}
          forcePopupIcon={!editState ? false : true}
          formikProps={formikProps}
          defaults={{
            primaryKey: "name",
            displayLabel: "name",
          }}
          options={list?.categories}
          placeholder="Select Category"
          onChange={(_, arr, reason, option) => {
            handleChange(arr, option, reason, "siteCategory", "name");
          }}
        />
      </Grid>

      <Grid item xs={10}>
        <Label
          title={"Location"}
          formikProps={formikProps}
          required={true}
          name={"audienceLocation"}
        />
      </Grid>

      <Grid item xs={10}>
        <AutocompleteMultiSelect
          name="audienceLocation"
          disabled={!editState}
          forcePopupIcon={!editState ? false : true}
          formikProps={formikProps}
          defaults={{
            primaryKey: "country_code",
            displayLabel: "country_name",
          }}
          options={list?.locations}
          placeholder="Select Location"
          onChange={(_, arr, reason, option) =>
            handleChange(
              arr,
              option,
              reason,
              "audienceLocation",
              "country_code"
            )
          }
        />
      </Grid>

      <Grid item xs={10}>
        <Label
          title={"Language"}
          formikProps={formikProps}
          required={true}
          name={"audienceLanguages"}
        />
      </Grid>

      <Grid item xs={10}>
        <AutocompleteMultiSelect
          name="audienceLanguages"
          disabled={!editState}
          forcePopupIcon={!editState ? false : true}
          formikProps={formikProps}
          defaults={{
            primaryKey: "code",
            displayLabel: "language",
          }}
          options={list?.languages}
          placeholder="Select Language"
          onChange={(_, arr, reason, option) =>
            handleChange(arr, option, reason, "audienceLanguages", "code")
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Label title="Social Media Handles" />
      </Grid>

      <Grid item xs={12}>
        <Label title={"Facebook"} />
      </Grid>

      <Grid item xs={10}>
        <TextField
          disabled={!editState}
          formikProps={formikProps}
          name="socialMedia.facebook"
          placeholder={"Enter your facebook handle"}
          inputProps={{ "aria-label": "Without label" }}
          InputProps={{
            endAdornment: !!formikProps.values.socialMedia.facebook &&
              !editState && (
                <IconButton
                  onClick={() =>
                    linkOpen(
                      "https://facebook.com/" +
                        formikProps.values.socialMedia.facebook.replace(
                          /(?:https?|ftp):\/\/[\n\S]+/g,
                          ""
                        )
                    )
                  }
                >
                  <TbExternalLink />
                </IconButton>
              ),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Label title={"Instagram"} />
      </Grid>

      <Grid item xs={10}>
        <TextField
          disabled={!editState}
          formikProps={formikProps}
          name="socialMedia.instagram"
          placeholder={"Enter your instagram handle"}
          inputProps={{ "aria-label": "Without label" }}
          InputProps={{
            endAdornment: !!formikProps.values.socialMedia.instagram &&
              !editState && (
                <IconButton
                  onClick={() =>
                    linkOpen(
                      "https://instagram.com/" +
                        formikProps.values.socialMedia.instagram.replace(
                          /(?:https?|ftp):\/\/[\n\S]+/g,
                          ""
                        )
                    )
                  }
                >
                  <TbExternalLink />
                </IconButton>
              ),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Label title={"Twitter"} />
      </Grid>

      <Grid item xs={10}>
        <TextField
          disabled={!editState}
          formikProps={formikProps}
          name="socialMedia.twitter"
          placeholder={"Enter your twitter handle"}
          inputProps={{ "aria-label": "Without label" }}
          InputProps={{
            endAdornment: !!formikProps.values.socialMedia.twitter &&
              !editState && (
                <IconButton
                  onClick={() =>
                    linkOpen(
                      "https://twitter.com/" +
                        formikProps.values.socialMedia.twitter.replace(
                          /(?:https?|ftp):\/\/[\n\S]+/g,
                          ""
                        )
                    )
                  }
                >
                  <TbExternalLink />
                </IconButton>
              ),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Label title={"Whatsapp"} />
      </Grid>

      <Grid item xs={10}>
        <TextField
          disabled={!editState}
          formikProps={formikProps}
          name="socialMedia.whatsapp"
          placeholder={"Enter your whatsapp number"}
          inputProps={{ "aria-label": "Without label" }}
          InputProps={{
            endAdornment: !!formikProps.values.socialMedia.whatsapp &&
              !editState && (
                <IconButton
                  onClick={() =>
                    linkOpen(
                      "https://wa.me/" + formikProps.values.socialMedia.whatsapp
                    )
                  }
                >
                  <TbExternalLink />
                </IconButton>
              ),
          }}
        />
      </Grid>
    </Paper>
  );

  function handleChange(arr, { option }, reason, name, displayName) {
    formikProps.setValues((prevVal) => ({
      ...prevVal,
      [name]: setValuesOfDropDown(prevVal[name]),
    }));

    function setValuesOfDropDown(val) {
      let values = [...val];

      if (reason === "removeOption") {
        if (typeof option === "object") {
          values = values.filter((val) => val !== option[displayName]);
        }

        if (typeof option === "string") {
          values.pop();
        }
      }
      if (reason === "selectOption") {
        values.push(option[displayName]);
      }

      return values;
    }
  }

  function linkOpen(val) {
    window.open(val, "_blank");
  }
}
