import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RiMenuFoldFill, RiMenuUnfoldFill } from "react-icons/ri";
import { Avatar, Divider, Grid, IconButton, Typography } from "@mui/material";

import pages from "../../constants/pages";
import { IoHomeOutline, IoLogOutOutline } from "react-icons/io5";
import { signOutMember } from "../../redux/slices/memberSlice";
import Logo from "../../assets/auth carousel pics/verismart-background.png";

const Wrapper = styled.div`
  min-height: 64px;
  border-radius: 0px;
  background: #fff;
  padding: 10px;
  border-bottom: 1px solid #cbcbcb5e;
`;

const GridContainer = styled(Grid)`
  height: 100%;
  align-items: center;
  padding-inline: 10px;
  gap: 6px;
`;

const Text = styled(Typography)`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  cursor: pointer;
`;

const ImageDiv = styled.div`
  width: 150px;
  height: 40px;
`;

const Image = styled.img`
  height: inherit;
  width: inherit;
`;

export default function Navbar({
  sidebarState,
  toggleSideBar,
  setSelectedMenu,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { activeBrand, role, dashboardPermissions } = useSelector(
    (state) => state.memberDetails
  );

  function handleHomeNavigation() {
    if (role === "SUPER_ADMIN") {
      navigate(pages.accountVerification.route);

      setSelectedMenu(pages.accountVerification.route);
    } else {
      navigate(pages.brandDetails.route);

      setSelectedMenu(pages.brandDetails.route);
    }
  }

  return (
    <Wrapper elevation={4}>
      <GridContainer container>
        <Grid item alignSelf={"center"} textAlign={"center"}>
          <IconButton onClick={toggleSideBar} className="info">
            {sidebarState.static ? <RiMenuFoldFill /> : <RiMenuUnfoldFill />}
          </IconButton>
        </Grid>

        <Grid
          item
          alignSelf={"center"}
          textAlign={"center"}
          style={{ cursor: "pointer" }}
        >
          <ImageDiv
            onClick={() => {
              const isAdminBrand = dashboardPermissions.some(
                (page) => page.ID === "administrator-brands"
              );

              const path = isAdminBrand
                ? pages.accountVerification.route
                : pages.brandDetails.route;

              navigate(path);
              setSelectedMenu(path);
            }}
          >
            <Image src={Logo} alt="!" />
          </ImageDiv>
        </Grid>

        <Grid item xs />

        <Grid
          item
          paddingRight={"41px"}
          textAlign={"center"}
          onClick={handleHomeNavigation}
        >
          <IoHomeOutline
            style={{
              height: "22px",
              width: "20px",
              cursor: "pointer",
            }}
          />
          <Text> Home </Text>
        </Grid>

        <Grid item>
          <Divider orientation="vertical" />
        </Grid>

        <Grid item textAlign={"center"} paddingRight={"41px"} cursor="pointer">
          <IoLogOutOutline
            style={{
              height: "22px",
              width: "20px",
              cursor: "pointer",
            }}
            onClick={() => dispatch(signOutMember())}
          />
          <Text onClick={() => dispatch(signOutMember())}> Logout </Text>
        </Grid>

        <Grid item>
          <Divider orientation="vertical" />
        </Grid>

        <Grid item display="flex" cursor="pointer">
          <Avatar style={{ cursor: "pointer" }} />
          {role === "NORMAL_USER" && (
            <Avatar
              onClick={() => navigate(pages.welcomePage.route)}
              style={{
                position: "relative",
                right: "13%",
                top: "3.5%",
                cursor: "pointer",
              }}
              src={activeBrand.logo}
            />
          )}
        </Grid>
      </GridContainer>
    </Wrapper>
  );
}
