import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { Fragment, useEffect, useState } from "react";

import { useAxios } from "../../hooks";
import pages from "../../constants/pages";
import EmptyList from "../misc/EmptyList";
import Table from "./template list component/Table";
import { Loader, PageHeader } from "../../components";
import { PrimaryButton } from "../css components/Button";
import { startLoader, apiComplete } from "../../redux/slices/loaderSlice";

export default function TemplateList() {
  const axios = useAxios({
    baseURL: "cpass_api",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { agent } = useSelector((state) => state.memberDetails);

  const [templates, setTemplates] = useState([]);
  const [templateDelete, setTemplateDelete] = useState("");

  useEffect(() => {
    dispatch(startLoader());

    if (agent?.length > 0) {
      axios({
        url: "/template/" + agent[0].agent_id,
      }).then((response) => {
        if (response.status) {
          setTemplates(response.data);
          dispatch(apiComplete());
        }
      });
    }
  }, [axios, templateDelete, agent, dispatch]);

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PageHeader pageName={"My Templates"} />
        </Grid>
      </Grid>

      {agent?.length > 0 ? (
        <Fragment>
          <Grid container spacing={3}>
            <Grid item xs />

            <Grid item alignSelf="center" textAlign="right">
              <PrimaryButton
                variant="contained"
                size="large"
                onClick={() => navigate(pages.createTemplate.route)}
              >
                Create Template
              </PrimaryButton>
            </Grid>

            <Grid item xs={12} />
          </Grid>

          <Loader height="75%">
            <Table
              templates={templates}
              setTemplateDelete={setTemplateDelete}
            />
          </Loader>
        </Fragment>
      ) : (
        <Grid item xs={12}>
          <EmptyList
            button={"Contact Admin"}
            route={pages.createCampaign.route}
            title={"Contact admin to link numbers"}
          />
        </Grid>
      )}
    </Fragment>
  );
}
