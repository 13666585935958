import queryString from "query-string";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import pages from "../../constants/pages";
import { Loader } from "../../components";
import { useAxios, useComponent } from "../../hooks";
import FormDisplay from "./unverified details components/FormDisplay";
import { brandDetailsObject } from "./unverified details components/brandObject";
import { signOutMember } from "../../redux/slices/memberSlice";
import { alert } from "../../redux/slices/alertSlice";

export default function UnverifiedBrandDetails() {
  const axios = useAxios();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loader } = useComponent();

  const [formData, setFormData] = useState(brandDetailsObject);

  const brandID = queryString.parse(window.location.search).brandID;

  const { location, languages, categories } = useSelector(
    (state) => state.memberDetails
  );

  useEffect(() => {
    loader.start(1);

    if (!!brandID) {
      axios({
        url: "/getBrand/" + brandID,
        method: "GET",
      }).then((res) => {
        const response = res.data;

        if (res.status) {
          if (res.data.brand?.is_brand_verified === 2) {
            dispatch(
              alert({
                type: "info",
                message: "Brand has been rejected by the admin!",
              })
            );
            dispatch(signOutMember());
            navigate(pages.signIn.route);
          }

          let medias = response?.social_media_handles;
          let fb = medias.facebook?.replace("@", "");
          let ig = medias.instagram?.replace("@", "");
          let tw = medias.twitter?.replace("@", "");
          setFormData({
            brandAvatar: response.logo,
            brandID: response?.brand_id,
            businessEntityId: response.business_entity_id,
            brandName: response.brand_name,
            website: response.website,
            audienceLocation: location.filter((rl) =>
              response?.audience_location.includes(rl.country_code)
            ),
            audienceLanguages: languages.filter((rl) =>
              response?.languages.includes(rl.code)
            ),
            siteCategory: categories.filter((rl) =>
              response?.site_category.includes(rl.name)
            ),
            status:
              response?.is_brand_verified === 0
                ? false
                : response?.is_brand_verified === 1
                ? "APPROVE"
                : "REJECTED",
            socialMedia: {
              facebook: fb,
              instagram: ig,
              twitter: tw,
              whatsapp: medias.whatsapp,
            },
            companyDetails: response.company_details,
            about: response.about,
            onlineStore: response.online_store,
            monthlyActiveUsers:
              !response.mau || response.mau === "null" ? "" : response.mau,
          });
          loader.apiComplete(1);
        }
      });
    } else {
      loader.apiComplete();
    }
  }, [
    axios,
    brandID,
    loader,
    location,
    languages,
    categories,
    navigate,
    dispatch,
  ]);

  return (
    <Loader>
      <FormDisplay formData={formData} setFormData={setFormData} />
    </Loader>
  );
}
