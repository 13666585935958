import styled from "styled-components/macro";
import { HighlightOff } from "@mui/icons-material";
import { Grid, IconButton, Typography } from "@mui/material";

import {
  ComponentHeader,
  TextField,
  AutocompleteMultiSelect,
} from "../../../components";

const MainHeading = styled(Typography)`
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  color: #02a0fc;
`;

export default function FormDisplay({
  handleClose,
  formikProps,
  editState,
  languages,
  location,
  categories,
}) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <MainHeading>Brand Profile</MainHeading>
      </Grid>

      <Grid item xs={4} textAlign="end">
        <IconButton padding="0px" onClick={handleClose}>
          <HighlightOff />
        </IconButton>
      </Grid>

      <Grid item xs={6}>
        <ComponentHeader title={"Display Picture"} />
      </Grid>

      <Grid item xs={6}>
        <ComponentHeader title={formikProps.values.brandName} />
      </Grid>

      <Grid item xs={6} textAlign="center" display="flex">
        <img
          alt="1"
          style={{ height: "100px" }}
          src={formikProps.values.brandAvatar}
        />
      </Grid>

      <Grid item xs={6}>
        <Typography>{formikProps.values.about}</Typography>
      </Grid>

      <Grid item xs={6}>
        <ComponentHeader title={"Online Store"} />
      </Grid>

      <Grid item xs={6}>
        <ComponentHeader title={"Website"} />
      </Grid>

      <Grid item xs={6}>
        <TextField
          disabled={!editState}
          formikProps={formikProps}
          name="onlineStore"
          placeholder="Online Store"
          inputProps={{ "aria-label": "Without label" }}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          disabled={!editState}
          formikProps={formikProps}
          name="website"
          placeholder="Website"
          inputProps={{ "aria-label": "Without label" }}
        />
      </Grid>

      <Grid item xs={6}>
        <ComponentHeader title="Monthly Active User" />
      </Grid>

      <Grid item xs={6}>
        <ComponentHeader title={"Location"} />
      </Grid>

      <Grid item xs={6}>
        <TextField
          disabled={!editState}
          formikProps={formikProps}
          name="monthlyActiveUsers"
          placeholder="Monthly Active Users"
          inputProps={{ "aria-label": "Without label" }}
        />
      </Grid>

      <Grid item xs={6}>
        <AutocompleteMultiSelect
          name="audienceLocation"
          disabled={!editState}
          forcePopupIcon={!editState ? false : true}
          formikProps={formikProps}
          defaults={{
            primaryKey: "country_code",
            displayLabel: "country_name",
          }}
          options={location}
          placeholder="Select Location"
          onChange={(_, arr, r, option) =>
            handleChange(arr, option, "audienceLocation", "country_code")
          }
        />
      </Grid>

      <Grid item xs={6}>
        <ComponentHeader title={"Language"} />
      </Grid>

      <Grid item xs={6}>
        <ComponentHeader title="Business Category" />
      </Grid>

      <Grid item xs={6}>
        <AutocompleteMultiSelect
          name="audienceLanguages"
          disabled={!editState}
          forcePopupIcon={!editState ? false : true}
          formikProps={formikProps}
          defaults={{
            primaryKey: "code",
            displayLabel: "language",
          }}
          options={languages}
          placeholder="Select Language"
          onChange={(_, arr, r, option) =>
            handleChange(arr, option, "audienceLanguages", "code")
          }
        />
      </Grid>

      <Grid item xs={6}>
        <AutocompleteMultiSelect
          name="siteCategory"
          disabled={!editState}
          forcePopupIcon={!editState ? false : true}
          formikProps={formikProps}
          defaults={{
            primaryKey: "name",
            displayLabel: "name",
          }}
          options={categories}
          placeholder="Select Category"
          onChange={(_, arr, r, option) =>
            handleChange(arr, option, "siteCategory", "name")
          }
        />
      </Grid>

      <Grid item xs={12}>
        <ComponentHeader title="Social Media Handles" />
      </Grid>

      <Grid item xs={6}>
        <Typography variant="caption1">Facebook</Typography>
      </Grid>

      <Grid item xs={6}>
        <Typography variant="caption1">Instagram</Typography>
      </Grid>

      <Grid item xs={6}>
        <TextField
          disabled={!editState}
          formikProps={formikProps}
          name="socialMedia.facebook"
          inputProps={{ "aria-label": "Without label" }}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          disabled={!editState}
          formikProps={formikProps}
          name="socialMedia.instagram"
          inputProps={{ "aria-label": "Without label" }}
        />
      </Grid>

      <Grid item xs={6}>
        <Typography variant="caption1">Twitter</Typography>
      </Grid>

      <Grid item xs={6}>
        <Typography variant="caption1">Whatsapp</Typography>
      </Grid>

      <Grid item xs={6}>
        <TextField
          disabled={!editState}
          formikProps={formikProps}
          name="socialMedia.twitter"
          inputProps={{ "aria-label": "Without label" }}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          disabled={!editState}
          formikProps={formikProps}
          name="socialMedia.whatsapp"
          inputProps={{ "aria-label": "Without label" }}
        />
      </Grid>
    </Grid>
  );

  function handleChange(event) {
    const {
      target: { value },
    } = event;

    formikProps.setValues((prevVal) => ({
      ...prevVal,
      [event.target.name]: setValuesOfDropDown(prevVal.name),
    }));

    function setValuesOfDropDown(values) {
      values = typeof value === "string" ? value.split(",") : value;
      return values;
    }
  }
}
