/* Routes for sidebar menu
  For a single entry: {
    name: Entry Display Name,
    icon: Entry Icon,
    ID: dashboardRoutes.[route name].ID,
    path: dashboardRoutes.[route name].path,
  }

  For a folder: {
    name: Folder Display Name,
    icon: Folder Icon,
    ID: random ID for key for component in the loop,
    children: [{
      name: Sub entry name,
      path: dashboardRoutes.[route name].path,
    }],
  }
*/
import { HiTemplate } from "react-icons/hi";
import { VscVerified } from "react-icons/vsc";
import { BsBootstrap } from "react-icons/bs";
import { BiBriefcase, BiWallet } from "react-icons/bi";

import { CgProfile } from "react-icons/cg";
import { MdOutlineCampaign, MdPeopleOutline } from "react-icons/md";

import dashboardLayoutRoutes from "../../../router/router components/dashboardLayoutRoutes";

const routes = [
  {
    name: "Profile",
    icon: CgProfile,
    ID: dashboardLayoutRoutes.brandDetailsRoute.ID,
    children: [
      {
        ID: dashboardLayoutRoutes.profileDetailsRoute.ID,
        name: "Profile",
        path: dashboardLayoutRoutes.profileDetailsRoute.path,
      },
      {
        ID: dashboardLayoutRoutes.brandDetailsRoute.ID,
        name: "Brand",
        path: dashboardLayoutRoutes.brandDetailsRoute.path,
      },
      {
        ID: dashboardLayoutRoutes.companyDetailsRoute.ID,
        name: "Company",
        path:
          dashboardLayoutRoutes.companyDetailsRoute.path +
          "?withCompany=" +
          true,
      },
    ],
  },

  {
    name: "Accounts",
    icon: VscVerified,
    ID: dashboardLayoutRoutes.accountVerificationRoute.ID,
    path: dashboardLayoutRoutes.accountVerificationRoute.path,
  },

  {
    name: "Brands",
    icon: BsBootstrap,
    ID: dashboardLayoutRoutes.unverifiedRoute.ID,
    path: dashboardLayoutRoutes.unverifiedRoute.path,
  },

  {
    name: "Campaigns",
    icon: MdOutlineCampaign,
    ID: dashboardLayoutRoutes.superadminAllCampaignsRoute.ID,
    path: dashboardLayoutRoutes.superadminAllCampaignsRoute.path,
  },

  {
    name: "Campaigns",
    icon: MdOutlineCampaign,
    ID: dashboardLayoutRoutes.myCampaignRoute.ID,
    path: dashboardLayoutRoutes.myCampaignRoute.path,
  },

  {
    name: "Campaigns",
    icon: MdOutlineCampaign,
    ID: dashboardLayoutRoutes.campaignRequestRoute.ID,
    path: dashboardLayoutRoutes.campaignRequestRoute.path,
  },

  {
    name: "Audience",
    icon: MdPeopleOutline,
    ID: dashboardLayoutRoutes.audienceListRoute.ID,
    path: dashboardLayoutRoutes.audienceListRoute.path,
  },

  // {
  //   name: "Templates",
  //   icon: HiTemplate,
  //   ID: dashboardLayoutRoutes.templateListRoute.ID,
  //   path: dashboardLayoutRoutes.templateListRoute.path,
  // },

  {
    name: "Users",
    icon: BiBriefcase,
    ID: dashboardLayoutRoutes.brandListRoute.ID,
    path: dashboardLayoutRoutes.brandListRoute.path,
  },

  {
    name: "On-Boarding",
    icon: BiBriefcase,
    ID: dashboardLayoutRoutes.superadminDPOnBoardingListRoute.ID,
    path: dashboardLayoutRoutes.superadminDPOnBoardingListRoute.path,
  },

  // {
  //   name: "Credits",
  //   icon: BiWallet,
  //   ID: dashboardLayoutRoutes.creditRoute.ID,
  //   path: dashboardLayoutRoutes.creditRoute.path,
  // },
];

export default routes;
