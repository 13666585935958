import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { Fragment, useEffect, useState } from "react";

import EmptyList from "../../misc/EmptyList";
import List from "../campaign list components/List";
import { useAxios, useComponent } from "../../../hooks";
import { Loader, PageHeader } from "../../../components";
import RequestedList from "../campaign list components/CampaignRequestList";
import SortBy from "../campaign list components/campaign dp list components/SortBy";
import Search from "../campaign list components/campaign dp list components/Search";
import Categorize from "../campaign list components/campaign dp list components/Categorize";
import { toInternationalFormat } from "../../../utils";

export default function CampaignDP() {
  const axios = useAxios();
  const { loader } = useComponent();

  const options = [
    { value: "ACTIVE", label: "Active Campaigns", color: "#34B53A" },
    { value: "REQUESTED", label: "Campaign Requests", color: "#02A0FC" },
  ];

  const [sortBy, setSortBy] = useState("ALL");
  const [campaigns, setCampaigns] = useState([]);
  const [categorize, setCategorize] = useState("REQUESTED");

  const { activeBrand, userID } = useSelector((state) => state.memberDetails);

  let sortList = [
    { value: "ALL", label: "All" },
    { value: "INPROGRESS", label: "In Progress", color: "#5AC35F" },
    { value: "EXPIRING", label: "Expiring Soon", color: "#FFBB0B" },
    { value: "EXPIRED", label: "Expired", color: "#FF3A29" },
  ];

  if (categorize === "REQUESTED") {
    sortList = [
      { value: "ALL", label: "All" },
      { value: "REQUESTED", label: "Requested", color: "#FFBB0B" },
      { value: "ACCEPTED", label: "Accepted", color: "#5AC35F" },
      { value: "PENDING", label: "Pending", color: "#FFBB0B" },
      { value: "REJECTED", label: "Rejected", color: "#FF3A29" },
    ];
  }

  useEffect(() => {
    loader.start(1);

    if (categorize === "ACTIVE") {
      axios({
        url: "/user/acceptedCampaigns",
        method: "POST",
        data: {
          provider_brandId: activeBrand.ID,
          provider_userId: userID,
        },
      }).then((response) => {
        if (response.status) {
          let arr = [];

          for (let key of response.data) {
            let data = {};
            for (let fields of key.fields) {
              if (
                Object.keys(fields)[0] === "budget_min" ||
                Object.keys(fields)[0] === "budget_max" ||
                Object.keys(fields)[0] === "monthly_active_user" ||
                Object.keys(fields)[0] === "volume"
              ) {
                data[Object.keys(fields)[0]] = toInternationalFormat(
                  Object.values(fields)[0]
                );
              } else {
                data[Object.keys(fields)[0]] = Object.values(fields)[0];
              }
            }
            arr.push({
              ...data,
              previewImage: !!key.preview_image
                ? key.preview_image
                : "https://dc-chat-media.s3.ap-south-1.amazonaws.com/NaN_placeholder.png",
              status: key.status,
              audience_id: key.audience_id,
              brand_id: key.brand_id,
              campaign_id: key.campaign_id,
              brand: key.brand_info,
            });
          }

          setCampaigns(arr);
          loader.apiComplete();
        } else {
          loader.apiComplete();
        }
      });
    }

    if (categorize === "REQUESTED") {
      axios({
        url:
          "/user/showCampaignRequestsDP?brandId=" +
          activeBrand.ID +
          "&requestStatus=" +
          sortBy,
      }).then((response) => {
        if (response.status) {
          let campaign = [];

          for (let key of response.data) {
            let data = {};
            for (let fields of key.campaign_fields) {
              if (
                Object.keys(fields)[0] === "budget_min" ||
                Object.keys(fields)[0] === "budget_max" ||
                Object.keys(fields)[0] === "monthly_active_user" ||
                Object.keys(fields)[0] === "volume"
              ) {
                data[Object.keys(fields)[0]] = toInternationalFormat(
                  Object.values(fields)[0]
                );
              } else {
                data[Object.keys(fields)[0]] = Object.values(fields)[0];
              }
            }

            campaign.push({
              ...data,
              brand: key.brand_info,
              previewImage: !!key.preview_image
                ? key.preview_image
                : "https://dc-chat-media.s3.ap-south-1.amazonaws.com/NaN_placeholder.png",
              status: key.requestStatus,
              request_id: key.request_id,
              sender_brandId: key.sender_brandId,
              sender_userId: key.sender_userId,
              sender_campaignId: key.sender_campaignId,
              receiver_brandId: key.receiver_brandId,
              receiver_userId: key.receiver_userId,
              isAccepted: key.isAccepted,
            });
          }

          setCampaigns(campaign);
          loader.apiComplete();
        } else {
          loader.apiComplete();
        }
      });
    }
  }, [axios, sortBy, categorize, userID, activeBrand.ID, loader]);

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PageHeader
            pageName={
              categorize === "REQUESTED"
                ? "Campaigns Requests"
                : "Active Campaigns"
            }
          />
        </Grid>

        <Categorize
          options={options}
          categorize={categorize}
          setCategorize={setCategorize}
          setSortBy={setSortBy}
        />

        <SortBy
          sortList={sortList}
          sortBy={sortBy}
          setSortBy={setSortBy}
          categorize={categorize}
        />

        <Search />

        <Grid item xs={12} />
      </Grid>

      <Loader height="75%">
        {campaigns.length > 0 ? (
          categorize === "REQUESTED" ? (
            <RequestedList campaigns={campaigns} component="request" />
          ) : (
            <List campaigns={campaigns} component="accepted" />
          )
        ) : (
          <EmptyList title={"No Request Yet."} />
        )}
      </Loader>
    </Fragment>
  );
}
