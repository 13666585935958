import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Grid, Typography, InputAdornment, IconButton } from "@mui/material";

import { Paper, TextField, Label } from "../../../components";
import { PrimaryLoadingButton } from "../../css components/Button";
import Logo from "../../../assets/auth carousel pics/verismart.png";

export default function FormDisplay({ formikProps }) {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);

  return (
    <Paper style={{ width: "650px", margin: "auto" }}>
      <Grid item xs={12} textAlign={"center"}>
        <img style={{ height: "50px" }} src={Logo} alt="logo" />
        <Typography
          style={{
            fontSize: "14px",
            fontWeight: 500,
          }}
        >
          Your journey begins with signing up!
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Label title={"First Name"} />
      </Grid>

      <Grid item xs={12}>
        <TextField
          required
          name="givenName"
          formikProps={formikProps}
          inputProps={{ "aria-label": "Without label" }}
          placeholder="Enter first name"
        />
      </Grid>

      <Grid item xs={12}>
        <Label title={"Last Name"} />
      </Grid>

      <Grid item xs={12}>
        <TextField
          required
          name="familyName"
          inputProps={{ "aria-label": "Without label" }}
          placeholder="Enter last name"
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={12}>
        <Label title={"Display Name"} />
      </Grid>

      <Grid item xs={12}>
        <TextField
          required
          name="displayName"
          inputProps={{ "aria-label": "Without label" }}
          placeholder="Enter display name"
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={12}>
        <Label title={"Email Address"} />
      </Grid>

      <Grid item xs={12}>
        <TextField
          required
          name="email"
          inputProps={{ "aria-label": "Without label" }}
          placeholder="Enter email address"
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={12}>
        <Label title={"Password"} />
      </Grid>

      <Grid item xs={12}>
        <TextField
          required
          type={showPassword ? "text" : "password"}
          name="password"
          inputProps={{ "aria-label": "Without label" }}
          placeholder="Enter password"
          formikProps={formikProps}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  style={{ padding: 0 }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {!showPassword && <VisibilityOff />}
                  {showPassword && <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Label title={"Confirm Password"} />
      </Grid>

      <Grid item xs={12}>
        <TextField
          required
          type={confirmPassword ? "text" : "password"}
          name="confirmPassword"
          inputProps={{ "aria-label": "Without label" }}
          placeholder="Confirm password"
          formikProps={formikProps}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  style={{ padding: 0 }}
                  onClick={() => setConfirmPassword(!confirmPassword)}
                >
                  {!confirmPassword && <VisibilityOff />}
                  {confirmPassword && <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="caption1">
          Already have an account?{" "}
          <Typography
            variant="button"
            className="info"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/sign-in")}
          >
            Sign In!
          </Typography>
        </Typography>
      </Grid>

      <Grid item xs />

      <Grid item>
        <PrimaryLoadingButton
          type="submit"
          variant="contained"
          fullWidth
          onClick={formikProps.handleSubmit}
          disabled={!formikProps.dirty || !formikProps.isValid}
          loading={formikProps.isSubmitting}
        >
          Sign Up
        </PrimaryLoadingButton>
      </Grid>
    </Paper>
  );
}
