import * as Yup from "yup";

export const audienceObject = {
  ID: "",
  brandID: "",
  additionalFields: [],
  percentage: {
    value: 0,
  },
  isEditable: true,
  audience_group: "",
};

export function objectFromResponse(response, fields) {
  let data = {};
  data = {
    ...audienceObject,
  };

  let responseData = {};
  let additionalFields = [];

  for (let key of response.fields) {
    if (!key?.is_additional) {
      if (Array.isArray(Object.values(key)[0])) {
        data[Object.keys(key)[0]] = {
          value: Object.values(key)[0]?.length > 0 ? Object.values(key)[0] : [],
          mandatory: Object.values(key)[1] || false,
        };
      }

      if (typeof Object.values(key)[0] === "string") {
        data[Object.keys(key)[0]] = {
          value: !!Object.values(key)[0] ? Object.values(key)[0] : "",
          mandatory: Object.values(key)[1] || false,
        };
      }

      if (
        typeof Object.values(key)[0] === "object" &&
        !Array.isArray(Object.values(key)[0])
      ) {
        data[Object.keys(key)[0]] = {
          value: key[Object.keys(key)[0]],
          mandatory: Object.values(key)[1] || false,
        };
      }

      if (typeof Object.values(key)[0] === "number") {
        data[Object.keys(key)[0]] = {
          value: Object.values(key)[0] !== 0 ? Object.values(key)[0] : 0,
          mandatory: Object.values(key)[1] || false,
        };
      }
    } else {
      additionalFields.push({
        key: Object.keys(key)[0],
        value: Object.values(key)[0],
        mandatory: key.mandatory,
      });
    }
  }

  for (let field of fields) {
    if (field.control_id === 2 && field.multi_select) {
      responseData = {
        ...responseData,
        [field.name]: {
          value: data[field.name]?.value ? data[field.name]?.value : [],
          mandatory: data[field.name]?.mandatory
            ? data[field.name]?.mandatory
            : false,
          userCount: 0,
        },
      };
    }

    if (field.control_id === 1) {
      responseData = {
        ...responseData,
        [field.name]: {
          value: data[field.name]?.value ? data[field.name]?.value : {},
          mandatory: data[field.name]?.mandatory
            ? data[field.name]?.mandatory
            : false,
          userCount: 0,
        },
      };
    }

    if (
      (field.control_id === 2 || field.control_id === 3) &&
      !field.multi_select
    ) {
      responseData = {
        ...responseData,
        [field.name]: {
          value: data[field.name]?.value ? data[field.name]?.value : "",
          mandatory: data[field.name]?.mandatory
            ? data[field.name]?.mandatory
            : false,
          userCount: 0,
        },
      };
    }

    if (field.control_id === 4) {
      responseData = {
        ...responseData,
        [field.name]: {
          value: data[field.name]?.value ? data[field.name]?.value : null,
          mandatory: data[field.name]?.mandatory
            ? data[field.name]?.mandatory
            : false,
          userCount: 0,
        },
      };
    }
  }
  return {
    ...responseData,
    percentage: data.percentage,
    ID: response.audience_id,
    brandID: response.brand_id,
    audience_group: data.audience_group,
    additionalFields: additionalFields,
    isEditable: response.is_editable,
  };
}

export function objectFromFormData(formData, details) {
  let fields = [];

  for (let field in formData) {
    if (
      field !== "ID" &&
      field !== "brandID" &&
      field !== "isEditable" &&
      field !== "additionalFields" &&
      field !== "dirty"
    ) {
      if (formData[field]?.value === null) {
        fields.push({
          [field]: {
            value: "",
            mandatory: false,
          },
        });
      } else {
        fields.push({ [field]: formData[field] });
      }
    }
  }

  for (let addedFields of formData.additionalFields) {
    fields.push({
      [addedFields.key]: {
        value: addedFields.value,
        mandatory: addedFields.mandatory || false,
        is_additional: true,
      },
    });
  }

  return {
    brand_id: parseInt(details.activeBrand.ID),
    fields: fields,
  };
}

export const validationSchema = Yup.object().shape({
  additionalFields: Yup.array().of(
    Yup.object().shape({
      key: Yup.string().required(),
      value: Yup.string().required(),
    })
  ),
  audience_group: Yup.object().shape({
    value: Yup.string()
      .min(3, "Greater than 3 letters")
      .required("Audience Group is required"),
  }),
});
