import { Fragment, useState } from "react";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { IoIosArrowForward } from "react-icons/io";

import { Paper } from "../../../components";
import pages from "../../../constants/pages";
import {
  HeadingEighteen,
  TextFourteenBlack,
  TextFifteenYellow,
  colors,
} from "../../css components/Style";
import brandIcon from "../../../assets/brandIcon.png";
import LOGO from "../../../assets/default_logo.webp";
import {
  brandDetailsObject,
  objectFromResponse,
} from "../../brand components/brand details components/brandDetailObject";
import BrandDetailsDialog from "../../campaign components/brand details components/BrandDetailsDialog";

const Link = styled(Typography)`
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #02a0fc;
  cursor: pointer;
`;

const ImageDiv = styled.div`
  height: 120px;
  width: 120px;
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: 0.3px solid #808080;
`;

const ArrowIcon = styled(IoIosArrowForward)`
  position: relative;
  top: 6px;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #02a0fc;
  cursor: pointer;
`;

function convertDate(d) {
  let date = new Date(d);

  return (
    date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear()
  );
}

export default function BrandList({ brandList, component }) {
  const navigate = useNavigate();
  const [brandDetails, setBrandDetails] = useState({
    data: brandDetailsObject,
    state: false,
  });

  const onImageError = (e) => {
    e.target.src = LOGO;
  };

  function handleNavigation(brand) {
    if (component === "users") {
      navigate(pages.usersList.route + brand.brand_id);
    }

    if (component === "campaign") {
      navigate(pages.superAdminBrandCampaigns.route + brand.brand_id);
    }
  }

  return (
    <Fragment>
      <Grid container spacing={3}>
        {brandList.map((brand) => (
          <Grid item xs={12} key={brand.brand_id}>
            <Paper>
              <Grid item alignSelf="center" paddingRight="59px">
                <ImageDiv>
                  <Image
                    src={brand.logo ? brand.logo : LOGO}
                    alt="!"
                    onError={onImageError}
                  />
                </ImageDiv>
              </Grid>

              <Grid item xs>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <div style={{ display: "flex" }}>
                      <TextFourteenBlack> Brand </TextFourteenBlack>
                      <img
                        alt="!"
                        src={brandIcon}
                        onClick={() => handleBrandDetails(brand)}
                        style={{
                          marginLeft: "10px",
                          width: "19px",
                          cursor: "pointer",
                        }}
                      />
                    </div>

                    <HeadingEighteen>{brand.brand_name}</HeadingEighteen>
                  </Grid>

                  <Grid item xs={6} justifyContent={"end"} display="flex">
                    {brand.new_user && (
                      <Fragment>
                        <svg
                          style={{ position: "relative", margin: "9px 9px" }}
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="5" cy="5" r="5" fill={"#FFBB0B"} />
                        </svg>
                        <TextFifteenYellow color={colors.warning}>
                          New Users Pending Approval
                        </TextFifteenYellow>
                      </Fragment>
                    )}
                  </Grid>

                  <Grid item xs={12} />

                  <Grid item xs={3}>
                    <TextFourteenBlack>Partner Type</TextFourteenBlack>
                    <HeadingEighteen>
                      {brand.business_entity_type_name}
                    </HeadingEighteen>
                  </Grid>

                  <Grid item xs={3}>
                    <TextFourteenBlack>Date Joined</TextFourteenBlack>
                    <HeadingEighteen>
                      {convertDate(brand.created_date)}
                    </HeadingEighteen>
                  </Grid>

                  <Grid item xs={2}>
                    <TextFourteenBlack>Total Users</TextFourteenBlack>
                    <HeadingEighteen>{brand.total_users}</HeadingEighteen>
                  </Grid>

                  <Grid item xs={2}>
                    <TextFourteenBlack>Total Credits</TextFourteenBlack>
                    <HeadingEighteen>
                      {brand.credit_details?.coins || 0}
                    </HeadingEighteen>
                  </Grid>

                  <Grid
                    item
                    xs={2}
                    alignSelf={"end"}
                    display="flex"
                    justifyContent="end"
                  >
                    <Link onClick={() => handleNavigation(brand)}>
                      {component === "campaign" ? "Campaigns" : "View Users"}
                    </Link>
                    <ArrowIcon />
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <BrandDetailsDialog details={brandDetails} setDetails={setBrandDetails} />
    </Fragment>
  );

  function handleBrandDetails(brandData) {
    setBrandDetails({
      data: objectFromResponse(brandData),
      state: true,
      component: "connection",
    });
  }
}
