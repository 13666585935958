import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components/macro";
import { Replay } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";

import { Paper } from "../../../components";
import { toInternationalFormat } from "../../../utils";
import { useAxios, useComponent } from "../../../hooks";
import { HeadingEighteen, colors } from "../../css components/Style";
import { brandDetailsObject } from "../../brand components/brand details components/brandDetailObject";
import BrandDetailsDialog from "../brand details components/BrandDetailsDialog";

const Subtitle = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  mix-blend-mode: normal;
  opacity: 0.4;
`;

const Link = styled(Typography)`
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #02a0fc;
  cursor: pointer;
`;

const ActiveHeading = styled(Typography)`
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
`;

export default function Connections({
  retry = {},
  setRetry,
  connections,
  setConnections,
}) {
  const axios = useAxios();
  const dispatch = useDispatch();

  const { alert } = useComponent();

  const [brandDetails, setBrandDetails] = useState({
    data: brandDetailsObject,
    state: false,
  });

  return (
    <Fragment>
      <Grid container spacing={3}>
        {connections?.map((connection, index) => (
          <Grid item xs={12} key={connection?.brand_id}>
            <Paper>
              <Grid item xs={6}>
                <Link>Connection</Link>
              </Grid>

              <Grid
                item
                xs={6}
                display="flex"
                alignSelf={"center"}
                justifyContent="end"
                style={{ cursor: "pointer" }}
              >
                {(connection.requestStatus === "ACCEPTED" ||
                  connection.requestStatus === "PID FETCHED FAILED") &&
                  (connection.pidStatus === "FAILED" ||
                    connection.dbStatus === "FAILED") &&
                  (!retry[connection.senderBrandId] ? (
                    <HeadingEighteen
                      onClick={() => handleRetry(connection)}
                      style={{ padding: "0", color: colors.info }}
                    >
                      <Replay
                        style={{ top: "2px", position: "relative" }}
                        color={colors.info}
                        fontSize="35px"
                      />{" "}
                      Retry
                    </HeadingEighteen>
                  ) : (
                    <HeadingEighteen
                      style={{ padding: "0", color: colors.info }}
                    >
                      Retrying.....
                    </HeadingEighteen>
                  ))}
                &nbsp; &nbsp;
                <svg
                  style={{ position: "relative", margin: "9px 9px" }}
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="5"
                    cy="5"
                    r="5"
                    fill={
                      connection.requestStatus !== "REQUESTED"
                        ? connection.requestStatus === "ACCEPTED"
                          ? colors.success
                          : colors.error
                        : colors.warning
                    }
                  />
                </svg>
                <ActiveHeading
                  color={
                    connection.requestStatus !== "REQUESTED"
                      ? connection.requestStatus === "ACCEPTED"
                        ? colors.success
                        : colors.error
                      : colors.warning
                  }
                  variant="button"
                >
                  {connection.requestStatus?.replace(/_/g, " ") || "N.A"}
                </ActiveHeading>
              </Grid>

              <Grid item xs={2}>
                <div style={{ display: "flex" }}>
                  <Subtitle>Partner</Subtitle>

                  {/* <img
                    alt="!"
                    src={brandIcon}
                    onClick={() =>
                      handleBrandDetails(connection?.receiverBrandId)
                    }
                    style={{ marginLeft: "10px", cursor: "pointer" }}
                  /> */}
                </div>
                <HeadingEighteen>
                  {`Partner ${index + 1}` || "N.A"}
                </HeadingEighteen>
              </Grid>

              <Grid item xs={2} style={{ paddingLeft: "0px" }}>
                <Subtitle>MAU</Subtitle>
                <HeadingEighteen>
                  {connection?.monthly_active_user || "N.A"}
                </HeadingEighteen>
              </Grid>

              <Grid item xs={2} style={{ paddingLeft: "0px" }}>
                <Subtitle> Location</Subtitle>
                <HeadingEighteen sx={{ textTransform: "uppercase" }}>
                  {connection?.locations?.length > 1
                    ? connection?.locations
                        .map((loc) => loc?.substr(0, 2))
                        .join(", ")
                    : connection?.locations[0]?.substr(0, 2)}
                </HeadingEighteen>
              </Grid>

              <Grid item xs={2} style={{ paddingLeft: "0px" }}>
                <Subtitle> Languages</Subtitle>
                <HeadingEighteen sx={{ textTransform: "uppercase" }}>
                  {connection.languages?.length > 1
                    ? connection?.languages
                        ?.map((lan) => lan?.substr(0, 2))
                        .join(", ")
                    : connection?.languages[0]?.substr(0, 2)}
                </HeadingEighteen>
              </Grid>

              <Grid item xs={3} style={{ paddingLeft: "0px" }}>
                <Subtitle> Category</Subtitle>
                <HeadingEighteen sx={{ textTransform: "uppercase" }}>
                  {connection?.category?.length > 1
                    ? connection?.category?.map((lan) => lan).join(", ")
                    : connection?.category[0]}
                </HeadingEighteen>
              </Grid>

              <Grid item xs={1} style={{ paddingLeft: "0px" }}>
                <Subtitle> User Count</Subtitle>
                <HeadingEighteen
                  sx={{ textTransform: "uppercase" }}
                  style={{ color: "#4339F2" }}
                >
                  {toInternationalFormat(connection?.usersCount) || "N.A"}
                </HeadingEighteen>
              </Grid>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <BrandDetailsDialog details={brandDetails} setDetails={setBrandDetails} />
    </Fragment>
  );

  function handleRetry(connection) {
    if (connection.pidStatus === "FAILED") {
      axios({
        url: "/user/takeUpOffer?v=1&requestStatus=ACCEPTED",
        method: "POST",
        data: {
          sender_brandId: parseInt(connection.senderBrandId),
          sender_campaignId: parseInt(connection.senderCampaignId),
          receiver_userId: parseInt(connection.receiverUserId),
          receiver_brandId: parseInt(connection.receiverBrandId),
        },
      }).then((response) => {
        if (response.status) {
          setRetry((prev) => ({
            prev,
            [connection.senderBrandId]: true,
          }));

          dispatch(alert.success(response.message));
        }
      });
    }

    if (connection.dbStatus === "FAILED") {
      axios({
        url: "/adserver/retryUserDbUpload",
        method: "POST",
        data: {
          sender_brand_id: parseInt(connection.senderBrandId),
          sender_campaign_id: parseInt(connection.senderCampaignId),
          receiver_brand_id: parseInt(connection.receiverBrandId),
        },
      }).then((response) => {
        if (response.status) {
          setRetry((prev) => ({
            prev,
            [connection.senderBrandId]: true,
          }));

          dispatch(alert.success(response.message));
        }
      });
    }
  }

  // function handleBrandDetails(brandID) {
  //   axios({
  //     url: "/getBrand/" + brandID,
  //     disableRedirect: true,
  //   }).then((response) => {
  //     if (response.status) {
  //       setBrandDetails({
  //         data: objectFromResponse(response.data),
  //         state: true,
  //         component: "connection",
  //       });
  //     }
  //   });
  // }
}
