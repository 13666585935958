import React from "react";
import { Helmet } from "react-helmet-async";
import styled from "styled-components/macro";

import Form from "./sign up components/Form";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
`;

function SignUp() {
  return (
    <Wrapper>
      <Helmet title="Sign Up" />

      <Form />
    </Wrapper>
  );
}

export default SignUp;
