import { Grid } from "@mui/material";
import { Fragment, useEffect, useState } from "react";

import List from "./user list components/List";
import { Loader, PageHeader } from "../../components";
import { useAxios, useComponent } from "../../hooks";
import EmptyList from "../misc/EmptyList";

export default function UsersList() {
  const axios = useAxios();
  const { loader } = useComponent();

  const [usersList, setUserList] = useState([]);
  const [userStatus, setUserStatus] = useState("");

  useEffect(() => {
    loader.start();
    axios({
      url: "/users/unverified",
    }).then((response) => {
      if (response.status) {
        setUserList(response.data);
        loader.apiComplete();
      }
    });
  }, [loader, axios]);

  useEffect(() => {
    if (userStatus) {
      axios({
        url: "/users/unverified",
      }).then((response) => {
        if (response.status) {
          setUserList(response.data);
        }
      });
    }
  }, [userStatus, axios]);

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PageHeader pageName={"New Accounts"} heading={"Accounts"} />
        </Grid>
      </Grid>

      <Loader height="75%">
        {usersList.length > 0 ? (
          <List usersList={usersList} setUserStatus={setUserStatus} />
        ) : (
          <EmptyList title={"No new accounts"} />
        )}
      </Loader>
    </Fragment>
  );
}
