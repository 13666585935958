import { Grid } from "@mui/material";
import React, { useState, useEffect, Fragment } from "react";

import SortBy from "./brand list components/Sort";
import Search from "./brand list components/Search";
import { Loader, PageHeader } from "../../components";
import { useAxios, useComponent } from "../../hooks";
import BrandList from "./brand list components/BrandList";
import Categorize from "./brand list components/Categorize";
import EmptyList from "../misc/EmptyList";

export default function SuperAdminBrand() {
  const axios = useAxios();
  const { loader } = useComponent();

  const [sortBy, setSortBy] = useState("ALL");
  const [brands, setBrands] = useState();
  const [brandList, setBrandList] = useState([]);
  const [searchBrands, setSearchBrands] = useState();

  useEffect(() => {
    loader.start();

    let url = "/getAllBrands";

    if (brands) {
      url = url + "?brand_type=" + brands;
    }

    if (searchBrands) {
      url = url + "?search=" + searchBrands;
    }

    axios({
      url: url,
    }).then((response) => {
      if (response.status) {
        setBrandList(response.data);

        loader.apiComplete();
      }
    });
  }, [loader, axios, brands, searchBrands]);

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PageHeader pageName={"All Brands"} heading={"Users"} />
        </Grid>

        <Categorize
          brands={brands}
          setBrands={setBrands}
          setSearchBrands={setSearchBrands}
        />

        {/* <SortBy sortBy={sortBy} setSortBy={setSortBy} /> */}

        <Search setSearchBrands={setSearchBrands} setBrands={setBrands} />

        <Grid item xs={12} />
      </Grid>

      <Loader height="75%">
        {brandList.length > 0 ? (
          <BrandList brandList={brandList} component={"users"} />
        ) : (
          <EmptyList title={"No new brand"} />
        )}
      </Loader>
    </Fragment>
  );
}
