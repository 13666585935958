import queryString from "query-string";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { useAxios, useComponent } from "../../hooks";
import {
  companyObject,
  objectFromResponseData,
} from "./company details components/companyDetailsObject";
import Form from "./company details components/Form";
import { Loader } from "../../components";

export default function CompanyDetails() {
  const axios = useAxios();
  const { loader } = useComponent();

  const brandID =
    useSelector((state) => state.memberDetails?.activeBrand.ID) ||
    queryString.parse(window.location.search).brandID;
  const details = queryString.parse(window.location.search).withCompany;

  const [formData, setFormData] = useState(companyObject);
  const [editState, setEditState] = useState(brandID ? false : true);

  useEffect(() => {
    loader.start(1);
    if (details) {
      axios({
        url: "/getCompany/" + brandID,
      }).then((response) => {
        if (response.status) {
          setFormData(objectFromResponseData(response.data));
          loader.stop();
        } else {
          loader.stop();
        }
      });
    } else {
      loader.stop();
    }
  }, [axios, details, brandID, loader]);

  return (
    <Loader>
      <Form
        formData={formData}
        setFormData={setFormData}
        setEditState={setEditState}
        editState={editState}
      />
    </Loader>
  );
}
