import { useNavigate } from "react-router-dom";
import React, { useState, Fragment } from "react";

import pages from "../../../constants/pages";
import { useAxios, useComponent } from "../../../hooks";
import {
  CancelButton,
  PrimaryLoadingButton,
} from "../../css components/Button";
import DeleteDialog from "../template list component/DeleteDailogue";

export default function CreateButton({ formikProps, editState, setEditState }) {
  const axios = useAxios({
    baseURL: "cpass_api",
  });
  const { alert } = useComponent();
  const navigation = useNavigate();

  const [dialogState, setDialogState] = useState({
    state: false,
    id: formikProps.values.ID,
  });

  const handleDelete = () => {
    axios({
      url: "/template/delete/" + dialogState.id,
      method: "DELETE",
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        alert.success(response.message.displayMessage);
        navigation(pages.templateList.route);
      }
    });
  };

  return (
    <Fragment>
      {Boolean(formikProps.values.ID) && (
        <Fragment>
          <CancelButton variant="outlined" onClick={() => navigation(-1)}>
            Cancel
          </CancelButton>

          <PrimaryLoadingButton
            variant="contained"
            style={{ background: "#d32f2f", color: "#fff" }}
            onClick={() =>
              setDialogState({
                state: true,
                id: formikProps.values.ID,
              })
            }
          >
            Delete Template
          </PrimaryLoadingButton>
        </Fragment>
      )}

      <DeleteDialog
        dialogState={dialogState}
        setDialogState={setDialogState}
        handleAgree={handleDelete}
      />
    </Fragment>
  );
}
