import * as Yup from "yup";

export const createCampaignObject = {
  brandID: "",
  audienceID: "",
  campaignID: "",
  startTime: "",
  previewImage: "",
  campaignStatus: "",
  brandDetails: {
    brandName: "",
    brandLogo: "",
  },

  file_name: {
    value: "",
  },

  campaign_name: {
    value: "",
  },

  about: {
    value: "",
  },

  destination_url: {
    value: "",
  },

  start_duration: {
    value: "",
  },

  end_duration: {
    value: "",
  },

  volume: {
    value: "",
  },

  budget_min: {
    value: "",
  },

  budget_max: {
    value: "",
  },

  mau: {
    value: "",
  },

  conversion_type: {
    value: "",
  },

  payout_percentage: {
    value: "",
  },

  payout_terms: {
    value: "",
  },

  trail_period: {
    value: "",
  },

  category: {
    value: [],
  },

  creatives: [
    {
      url: "",
      creativeName: "",
      banner_type: "",
      banner_size: "",
      banner_format: "",
      creative: "",
      upload_type: "",
      ad_tag: "",
    },
  ],
};

export function objectFromFormData(formData, activeBrand) {
  let sDate = new Date(formData.start_duration.value);
  let eDate = new Date(formData.end_duration.value);

  let fields = [
    { file_name: formData.file_name },
    {
      campaign_name: formData.campaign_name,
    },
    {
      about: formData.about,
    },
    {
      destination_url: formData.destination_url,
    },
    {
      start_duration: {
        value: sDate.toISOString(),
      },
    },
    {
      end_duration: { value: eDate.toISOString() },
    },
    {
      volume: formData.volume,
    },
    {
      budget_min: formData.budget_min,
    },
    {
      budget_max: formData.budget_max,
    },
    {
      mau: parseInt(formData.mau),
    },
    {
      category: formData.category,
    },
    {
      conversion_type: formData.conversion_type,
    },
    {
      payout_percentage: formData.payout_percentage,
    },
    {
      payout_terms: formData.payout_terms,
    },
    {
      trail_period: formData.trail_period,
    },
  ];

  if (!!formData.campaignID) {
    return {
      brand_id: parseInt(activeBrand.ID),
      audience_id: formData.audienceID,
      fields: fields,
    };
  } else {
    let data = new FormData();

    data.append("brand_id", parseInt(activeBrand.ID));
    data.append("audience_id", formData.audienceID);
    data.append("previewImage", formData.previewImage);
    data.append("fields", JSON.stringify(fields));
    return data;
  }
}

export function creative(formData, campaignID) {
  let data = new FormData();

  data.append("campaign_id", campaignID);
  data.append("banner_type", formData.creatives[0].banner_type);
  data.append("size", formData.creatives[0].banner_size);
  data.append("format", formData.creatives[0].format);
  if (formData.creatives[0].upload_type === "url") {
    data.append("link", formData.creatives[0].link);
  } else {
    data.append("creative", formData.creatives[0].creative);
    data.append("creativeName", formData.creatives[0].creativeName);
  }
  return data;
}

export function objectFromResponse(response, creatives) {
  let data = {};
  let creativesData = [];

  for (let key of response.campaignData) {
    data[Object.keys(key)[0]] = {
      value: Object.values(key)[0],
    };
  }

  return {
    ...data,
    creatives: creativesData,
    brandID: response.brand_id,
    campaignID: response.campaign_id,
    audienceID: response.audience.audience_id,
    previewImage: response.preview_image,
    fileName: response.fileName,
    campaignStatus: response.status,
    adminCampaignStatus: response?.admin_campaign_status,
    campaignLaunched: response?.campaign_launched,
  };
}

export const validationSchema = Yup.object().shape({
  campaign_name: Yup.object().shape({
    value: Yup.string().required("Campaign Name is required"),
  }),

  previewImage: Yup.mixed().required("Preview Image is required"),

  start_duration: Yup.object().shape({
    value: Yup.date().required("Start Date is required"),
  }),

  startTime: Yup.date(),

  end_duration: Yup.object().shape({
    value: Yup.date().required("End Date is required"),
  }),

  mau: Yup.object().shape({
    value: Yup.string()
      .matches(/^\d+$/, "Mau should be number")
      .required("Monthly active users is required"),
  }),

  budget_min: Yup.object().shape({
    value: Yup.string()
      .matches(/^\d+$/, "Budget should be number")
      .required("Minimum budget is required"),
  }),

  budget_max: Yup.object().shape({
    value: Yup.string()
      .matches(/^\d+$/, "Budget should be number")
      .required("Maximum budget is required"),
  }),

  conversion_type: Yup.object().shape({
    value: Yup.string().required("Conversion Type is required"),
  }),
});
