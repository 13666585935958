import styled from "styled-components/macro";
import { Grid, Typography } from "@mui/material";

import { Paper, Label, FileUpload } from "../../../components";

const MainHeading = styled(Typography)`
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  color: #02a0fc;
`;

export default function FormSixth({ formikProps, editState }) {
  return (
    <Paper style={{ marginTop: "25px" }}>
      <Grid item xs={12}>
        <MainHeading>Preview Image</MainHeading>
      </Grid>

      <Grid item xs={12}>
        <Label
          title="Preview Image"
          formikProps={formikProps}
          required={true}
          name={"previewImage.value"}
        />
      </Grid>

      <Grid item xs={10}>
        <FileUpload
          required
          disabled={!editState}
          name="previewImage"
          formikProps={formikProps}
          placeholder="Select Preview Image"
          allowedExtension=".jpg,.jpeg,.png,.gif"
          folderPath={"public/preview_images/" + new Date().getTime() + "_"}
        />
      </Grid>
    </Paper>
  );
}
