import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  Dialog as MUIDialog,
  DialogActions,
  DialogContent,
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import styled from "styled-components/macro";

import { useAlert, useAxios } from "../../hooks";
import Wallet from "../../assets/wallet.png";
import { TextField } from "../../components";
import { TextFifteenYellow } from "../css components/Style";
import { CancelButton, PrimaryLoadingButton } from "../css components/Button";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "400px" },
  div: {
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
  },
}));

const ImageDiv = styled.div`
  height: 100px;
  width: 100px;
  padding: 10px;
  margin: auto;
`;

export default function AddMoneyDialog({
  setCreditAdded,
  addMoney,
  setAddMoney,
}) {
  const axios = useAxios();
  const classes = useStyles();
  const { alert } = useAlert();
  const dispatch = useDispatch();

  const { activeBrand, email } = useSelector((state) => state.memberDetails);

  const [amount, setAmount] = useState();

  const handleClose = () => {
    setAddMoney(false);
  };

  useEffect(() => {
    const res = loadScript("https://checkout.razorpay.com/v1/checkout.js");
    if (!res) {
      console.log("Razorpay SDK failed to load. Are you online?");
      return;
    } else {
      console.log("script loaded successfully!");
    }
    window.onload = () => {
      window.callScript();
    };
  }, []);

  return (
    <React.Fragment>
      <MUIDialog
        classes={{ paper: classes.paper }}
        open={addMoney}
        onClose={handleClose}
      >
        <DialogContent>
          <Grid container spacing={8}>
            <Grid item xs={12}>
              <ImageDiv>
                <img
                  src={Wallet}
                  alt="Wallet"
                  style={{ height: "inherit", width: "inherit" }}
                />
              </ImageDiv>
            </Grid>

            <Grid item xs={12} style={{ textAlign: "center" }}>
              <TextFifteenYellow>Add Credits</TextFifteenYellow>
            </Grid>

            <Grid item xs={12}>
              <TextField
                placeholder="Enter Credit"
                value={amount}
                onChange={(e) => {
                  if (!isNaN(e.target.value)) {
                    setAmount(e.target.value);
                  }
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions style={{ padding: "24px 0" }}>
          <PrimaryLoadingButton
            onClick={handlePayment}
            variant="contained"
            color="primary"
            autoFocus
          >
            Add Credits
          </PrimaryLoadingButton>

          <CancelButton
            onClick={handleClose}
            variant="outlined"
            color="primary"
          >
            Cancel
          </CancelButton>
        </DialogActions>
      </MUIDialog>
    </React.Fragment>
  );

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  function handlePayment() {
    axios({
      url: "/create/order",
      method: "POST",
      data: {
        receipt: activeBrand?.brandName,
        amount: parseFloat(amount),
        currency: "INR",
        notes: {
          description: activeBrand?.brandName + "payment",
        },
      },
    }).then((response) => {
      if (response.status) {
        let resp = response.data;
        const options = {
          key: "rzp_test_YvrK73CThtiq9m",
          amount: resp.amount,
          currency: resp.currency,
          order_id: resp.transaction_id,
          name: activeBrand.brandName,
          description: "Please proceed your payment.",
          handler: function (responses) {
            //verification of signature
            if (responses) {
              axios({
                url: "/verify/order",
                method: "POST",
                data: {
                  order_id: responses.razorpay_order_id,
                  payment_id: responses.razorpay_payment_id,
                  signature: responses.razorpay_signature,
                },
              }).then((verify) => {
                if (verify.status) {
                  setCreditAdded(true);
                  handleClose();
                  dispatch(
                    alert({
                      type: "success",
                      message: "Credits added successfully!",
                    })
                  );
                }
              });
            }
          },
          prefill: {
            email: email,
            phone_number: "7905866225",
          },
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      }
    });
  }
}
