import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import {
  CancelButton,
  PrimaryButton,
  PrimaryLoadingButton,
} from "../pages/css components/Button";

export default function Buttons({
  ID = "",
  editState,
  setEditState,
  formikProps,
  route = "",
  condition = true,
}) {
  const navigate = useNavigate();

  function handleCancel() {
    if (editState && !!ID) {
      setEditState(!editState);
      formikProps.handleReset();
    } else {
      if (!!route) {
        navigate(-1, { state: route });
      } else {
        navigate(-1);
      }
    }
  }

  return (
    <Fragment>
      <CancelButton variant="outlined" onClick={handleCancel}>
        {editState ? "Cancel" : "Back"}
      </CancelButton>

      {editState ? (
        <PrimaryLoadingButton
          size="large"
          variant="contained"
          onClick={formikProps.handleSubmit}
          disabled={!formikProps.dirty || !formikProps.isValid || !condition}
          loading={formikProps.isSubmitting}
        >
          Save
        </PrimaryLoadingButton>
      ) : (
        <PrimaryButton
          size="large"
          variant="contained"
          onClick={() => setEditState(!editState)}
        >
          Edit
        </PrimaryButton>
      )}
    </Fragment>
  );
}
