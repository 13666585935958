import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Typography } from "@mui/material";

import { useAxios } from "../../hooks";
import Business from "../../assets/business-removebg.png";
import Logo from "../../assets/auth carousel pics/verismart-background.png";
import pages from "../../constants/pages";
import { alert } from "../../redux/slices/alertSlice";

export default function Form({ code }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const axios = useAxios({ disableAuthToken: true });

  return (
    <div className="wrapper-verify">
      <div className="outer-verify-div">
        <div className="line-box">
          <div className="green-line"></div>
          <div className="purple-line"></div>
          <div className="yellow-line"></div>
        </div>

        <Grid container spacing={3} padding={"0 10px 0"} display={"block"}>
          <Grid item xs={12}>
            <img src={Logo} className="image-verify-logo" alt="logo" />
          </Grid>

          <Grid item xs={12} textAlign={"start"} margin={"40px 0 40px 0"}>
            <div className="image-text-div">
              <div className="image-business">
                <img src={Business} className="business-image" alt="!" />
              </div>

              <Typography className="business-heading">
                <Typography className="congrat-text">
                  You are almost there, Welcome to Verismart Ad Tech Platform !
                </Typography>
                <Typography className="congrat-text">
                  Please Click the button below to verify your email account and
                  proceed for the next steps.
                </Typography>
                <Button onClick={handleVerify} variant="contained">
                  Verify Account
                </Button>
              </Typography>
            </div>
          </Grid>

          {/* <Grid item xs={12} display={"flex"} justifyContent={"end"}>
            <Copyright /> <Typography>Verismart</Typography>
          </Grid> */}
        </Grid>
      </div>
    </div>
  );

  function handleVerify() {
    axios({
      url: "/user/verifyEmail",
      method: "POST",
      data: {
        code: code,
      },
    }).then((res) => {
      if (res.status) {
        dispatch(
          alert({
            type: "success",
            message: res.message.displayMessage,
          })
        );
        navigate(pages.signIn.route);
      }
    });
  }
}
