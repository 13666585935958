import queryString from "query-string";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Loader } from "../../../components";

import Form from "./Form";
import { useAxios, useComponent } from "../../../hooks";
import { toInternationalFormat } from "../../../utils";
import {
  createCampaignObject,
  getAudienceDetails,
  objectFromResponse,
} from "../create campaign components/campaignObject";
import { objectFromBrandResponse } from "../brand details components/brandDetailsObject";
import { audienceObject } from "../create campaign components/audience details components/audienceObjects";

export default function SuperadminCampaignDetails() {
  const axios = useAxios();
  const params = useParams();
  const { loader } = useComponent();

  const campaignID = params.campaignID || "";
  const brandID = queryString.parse(window.location.search).brandID;

  const [retry, setRetry] = useState({});
  const [audiences, setAudiences] = useState([]);
  const [brandDetails, setBrandDetails] = useState({
    data: {},
    icon: "",
  });
  const [editState, setEditState] = useState(false);
  const [connections, setConnections] = useState([]);
  const [audienceSize, setAudienceSize] = useState();
  const [preferredChannels, setPreferedChannels] = useState([]);
  const [formData, setFormData] = useState(createCampaignObject);
  const [audienceData, setAudienceData] = useState(audienceObject);
  const { categories, userID } = useSelector((state) => state.memberDetails);

  useEffect(() => {
    loader.start(5);

    axios({
      url: "/getBrand/" + brandID,
      method: "GET",
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setBrandDetails({
          data: objectFromBrandResponse(response.data),
          icon: response.data.logo,
        });
        loader.apiComplete(5);
      }
    });

    axios({
      url: "/get/campaign/preferredChannels",
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setPreferedChannels(response.data);
        loader.apiComplete();
      } else {
        loader.apiComplete();
      }
    });

    axios({
      url: "/brand/getAllAudiences?brandId=" + brandID,
      disableRedirect: true,
    }).then((res) => {
      if (res.success) {
        let arr = [];

        for (let key of res.data) {
          let data = {};

          for (let fields of key.fields) {
            data[Object.keys(fields)[0]] = Object.values(fields)[0];
          }
          arr.push({
            ...data,
            audience_id: key.audience_id,
          });
        }
        setAudiences(arr);

        loader.apiComplete();
      }
    });

    if (!!campaignID) {
      let campaignData = {};

      axios({
        url:
          "/user/brand/getCampaign?brandId=" +
          parseInt(brandID) +
          "&campaignId=" +
          parseInt(campaignID),

        method: "GET",
      }).then(async (response) => {
        if (response.status) {
          axios({
            url:
              "/brand/getDetailsOfAudience/" +
              response.data.audience.audience_id,
          }).then(async (res) => {
            if (res.status) {
              await setAudienceData(getAudienceDetails(res.data));
              loader.apiComplete(1);
            }
          });

          axios({
            url: "/get/audienceSizing",
            method: "POST",
            data: {
              brand_id: parseInt(brandID),
              campaign_id: parseInt(campaignID),
              audience_id: parseInt(response.data.audience.audience_id),
            },
            disableRedirect: true,
            disableError: true,
          }).then((audienceSize) => {
            if (audienceSize.status && audienceSize.data !== null) {
              console.log(audienceSize);
              setAudienceSize(audienceSize.data);
            }
          });

          await axios({
            url: "/user/brand/campaign/creatives?campaignId=" + campaignID,
          }).then(async (creatives) => {
            if (creatives.status) {
              campaignData = {
                ...campaignData,
                ...response.data,
                creatives: creatives.data,
              };
              let connection = [];
              for (let con of response.data.connections) {
                if (con.userDbStatus === "FAILED" || con.status === "FAILED") {
                  setRetry({
                    [con.sender_brandId]: false,
                  });
                }
                connection.push({
                  requestStatus: con.requestStatus?.replace(/_/g, " "),
                  logo: con.receiver_brand_info?.logo,
                  monthly_active_user:
                    toInternationalFormat(
                      con.receiver_brand_info?.monthly_active_user
                    ) || "",
                  brandId: con.receiver_brand_info?.brandId,
                  category: con.receiver_brand_info?.category || [],
                  languages: con.receiver_brand_info?.languages || [],
                  locations: con.receiver_brand_info?.location || [],
                  name: con.receiver_brand_info?.brand_name || "",
                  usersCount: con.users_count || 0,
                  dbStatus: con.userDbStatus || "",
                  pidStatus: con.status || "",
                  senderUserId: con.sender_userId,
                  senderBrandId: con.sender_brandId,
                  senderCampaignId: con.sender_campaignId,
                  receiverUserId: con.receiver_userId,
                  receiverBrandId: con.receiver_brandId,
                });
              }

              setConnections(connection);
              await setFormData(objectFromResponse(campaignData));
              loader.apiComplete(2);
            }
          });
        }
      });
    } else {
      loader.apiComplete(3);
    }
  }, [brandID, axios, loader, campaignID, userID]);

  return (
    <Loader>
      <Form
        preferredChannels={preferredChannels}
        audienceSizing={audienceSize}
        setAudienceSizing={setAudienceSize}
        retry={retry}
        setRetry={setRetry}
        categories={categories}
        audiences={audiences}
        formData={formData}
        editState={editState}
        setEditState={setEditState}
        audienceData={audienceData}
        connections={connections}
        brandDetails={brandDetails}
        setBrandDetails={setBrandDetails}
        component={"superadmin"}
        setConnections={setConnections}
      />
    </Loader>
  );
}
