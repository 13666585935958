import { Grid } from "@mui/material";
import { Fragment, useEffect, useState } from "react";

import { useAxios } from "../../hooks";
import EmptyList from "../misc/EmptyList";
import List from "./unverified list components/List";
import { Loader, PageHeader } from "../../components";

export default function UnverifiedBrandList() {
  const axios = useAxios();
  const [brands, setBrands] = useState([]);
  const [brandStatus, setBrandStatus] = useState();

  useEffect(() => {
    axios({
      url: "/getUnverfiedBrands",
      method: "GET",
    }).then((response) => {
      if (response.status) {
        setBrands(response.data);
      }
    });
  }, [axios]);

  useEffect(() => {
    if (!!brandStatus) {
      axios({
        url: "/getUnverfiedBrands",
        method: "GET",
      }).then((response) => {
        if (response.status) {
          setBrands(response.data);
        }
      });
    }
  }, [axios, brandStatus]);

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PageHeader pageName={"New Brands"} heading={"Brands"} />
        </Grid>
      </Grid>

      <Loader height="75%">
        {brands.length > 0 ? (
          <List brands={brands} setBrandStatus={setBrandStatus} />
        ) : (
          <EmptyList title={"No new brands"} />
        )}
      </Loader>
    </Fragment>
  );
}
