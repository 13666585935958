import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import { Fragment, useState } from "react";
import { Grid, Typography } from "@mui/material";

import { PrimaryButton } from "../../css components/Button";
import { Label, Paper, Autocomplete } from "../../../components";
import AudienceForm from "./audience details components/AudienceForm";
import AudienceDetailsDialog from "./audience details components/AudienceDetailsDailog";
import { useNavigate } from "react-router-dom";
import pages from "../../../constants/pages";

const MainHeading = styled(Typography)`
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  color: #02a0fc;
`;

export default function FormTwo({
  audiences,
  brandIdentity,
  formikProps,
  editState,
  component,
  audienceData,
  setAudienceData,
  setAudienceCreated,
}) {
  const navigate = useNavigate();
  const { categories, location, languages } = useSelector(
    (state) => state.memberDetails
  );
  const [details, setDetails] = useState({
    id: formikProps.values.audience,
    state: false,
  });

  return (
    <Fragment>
      <Paper style={{ marginTop: "25px" }}>
        <Grid item xs={12}>
          <MainHeading>Audience Group Details </MainHeading>
        </Grid>

        {!formikProps.values?.campaignID || editState ? (
          <Fragment>
            <Grid item xs={12}>
              <Label
                title={"Select Audience"}
                formikProps={formikProps}
                required={true}
                name={"audienceID"}
                select={true}
              />
            </Grid>

            <Grid item xs={10}>
              <Autocomplete
                forcePopupIcon={!editState ? false : true}
                name="audienceID"
                placeholder="Select Audience"
                defaults={{
                  primaryKey: "audience_id",
                  displayLabel: "audience_group",
                }}
                sx={{
                  "& .MuiSvgIcon-root": {
                    display: !editState && "none",
                  },
                }}
                options={audiences}
                disabled={!editState}
                onChange={(_, value, type) => handleChangeAudience(value, type)}
                formikProps={formikProps}
              />
            </Grid>

            {formikProps.values.audienceID && (
              <Grid item xs={12}>
                <Typography
                  onClick={() =>
                    setDetails({
                      id: formikProps.values.audienceID,
                      state: true,
                      open: "detail",
                    })
                  }
                  style={{ color: "#02a0fc", cursor: "pointer" }}
                >
                  View Audience Group Details
                </Typography>
              </Grid>
            )}

            {!brandIdentity && editState && (
              <Grid item xs={12}>
                <Typography style={{ marginBottom: "8px" }}>
                  Can’t find your audience group, let’s create one.
                </Typography>

                <PrimaryButton
                  variant="contained"
                  disabled={!editState}
                  onClick={() => navigate(pages.createAudience.route)}
                >
                  Create Audience
                </PrimaryButton>
              </Grid>
            )}
          </Fragment>
        ) : (
          <AudienceForm
            audienceData={audienceData}
            categories={categories}
            location={location}
            languages={languages}
            formikProps={formikProps}
            component={component}
            setAudienceData={setAudienceData}
          />
        )}
      </Paper>

      <AudienceDetailsDialog
        categories={categories}
        location={location}
        languages={languages}
        details={details}
        setDetails={setDetails}
        component="dialog"
        setAudienceCreated={setAudienceCreated}
        formikProps={formikProps}
        campaignID={formikProps?.values?.campaignID}
      />
    </Fragment>
  );

  function handleChangeAudience(value, type) {
    if (type === "selectOption") {
      formikProps.setValues((prevVal) => ({
        ...prevVal,
        audienceID: value.audience_id,
      }));

      setDetails({
        id: value.audience_id,
        state: false,
        open: "detail",
      });
    } else {
      value = {
        audience_id: "",
        audience_group: "",
      };

      formikProps.setValues((prevVal) => ({
        ...prevVal,
        audienceID: value._id,
      }));
    }
  }
}
