import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";

import List from "../campaign list components/List";
import { useAxios, useComponent } from "../../../hooks";
import { Loader, PageHeader } from "../../../components";
import Search from "../campaign list components/superadmin list components/Search";
import SortBy from "../campaign list components/superadmin list components/SortBy";
import Categorize from "../campaign list components/superadmin list components/Categorize";
import BrandList from "../../superAdmin brand components/brand list components/BrandList";
import { toInternationalFormat } from "../../../utils";
import EmptyList from "../../misc/EmptyList";

export default function SuperAdminCampaignList() {
  const axios = useAxios();
  const params = useParams();
  const { loader } = useComponent();

  const [sortBy, setSortBy] = useState("ALL");
  const [campaigns, setCampaigns] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [categorize, setCategorize] = useState("Campaign Offers");

  const brandID = params.brandID || "";

  useEffect(() => {
    loader.start();

    if (categorize === "Campaign Offers") {
      let url;
      if (sortBy === "ALL") {
        url = "/user/brand/getAllCampaigns";
      } else {
        url = "/user/brand/getAllCampaigns?campaignStatus=" + sortBy;
      }
      axios({
        url: url,
        method: "GET",
      }).then((response) => {
        if (response.status) {
          let arr = [];

          for (let key of response.data) {
            let data = {};
            for (let fields of key.fields) {
              if (
                Object.keys(fields)[0] === "budget_min" ||
                Object.keys(fields)[0] === "budget_max" ||
                Object.keys(fields)[0] === "monthly_active_user" ||
                Object.keys(fields)[0] === "volume"
              ) {
                data[Object.keys(fields)[0]] = toInternationalFormat(
                  Object.values(fields)[0]
                );
              } else {
                data[Object.keys(fields)[0]] = Object.values(fields)[0];
              }
            }
            arr.push({
              ...data,
              brand: {
                name: key.brand.brand_name,
                logo: key.brand.brand_logo,
              },
              previewImage: !!key.preview_image
                ? key.preview_image
                : "https://dc-chat-media.s3.ap-south-1.amazonaws.com/NaN_placeholder.png",
              status: key.status,
              audience_id: key.audience_id,
              brand_id: key.brand_id,
              campaign_id: key.campaign_id,
            });
          }

          setCampaigns(arr);
          loader.stop();
        }
      });
    }

    if (categorize === "Unverified Campaigns") {
      axios({
        url: "/user/brand/getUnApprovedCampaigns",
      }).then((response) => {
        if (response.status) {
          let arr = [];

          for (let key of response.data) {
            let data = {};
            for (let fields of key.fields) {
              data[Object.keys(fields)[0]] = Object.values(fields)[0];
            }
            arr.push({
              ...data,
              brand: {
                name: key.brand.brand_name,
                logo: key.brand.brand_logo,
              },
              previewImage: !!key.preview_image
                ? key.preview_image
                : "https://dc-chat-media.s3.ap-south-1.amazonaws.com/NaN_placeholder.png",
              status: key.status,
              audience_id: key.audience_id,
              brand_id: key.brand_id,
              campaign_id: key.campaign_id,
            });
          }

          setCampaigns(arr);
          loader.stop();
        }
      });
    }

    if (categorize === "Brands") {
      axios({
        url: "/getAllBrands",
      }).then((response) => {
        if (response.status) {
          setBrandList(response.data);
          loader.apiComplete();
        } else {
          loader.stop();
        }
      });
    }

    if (categorize === "Rejected Campaigns") {
      axios({
        url: "/user/brand/getRejectedCampaigns",
      }).then((response) => {
        if (response.status) {
          let arr = [];

          for (let key of response.data) {
            let data = {};
            for (let fields of key.fields) {
              data[Object.keys(fields)[0]] = Object.values(fields)[0];
            }
            arr.push({
              ...data,
              brand: {
                name: key.brand.brand_name,
                logo: key.brand.brand_logo,
              },
              previewImage: !!key.preview_image
                ? +key.preview_image
                : "https://dc-chat-media.s3.ap-south-1.amazonaws.com/NaN_placeholder.png",
              status: key.status,
              audience_id: key.audience_id,
              brand_id: key.brand_id,
              campaign_id: key.campaign_id,
            });
          }

          setCampaigns(arr);
          loader.stop();
        }
      });
    }
  }, [axios, brandID, loader, categorize, sortBy]);

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PageHeader heading={"Campaigns"} pageName={"All Campaigns"} />
        </Grid>

        <Categorize categorize={categorize} setCategorize={setCategorize} />

        <SortBy sortBy={sortBy} setSortBy={setSortBy} categorize={categorize} />

        <Search />

        <Grid item xs={12} />
      </Grid>

      <Loader height="75%">
        {categorize === "Campaign Offers" && (
          <List
            categorize={categorize}
            campaigns={campaigns}
            component="superadmin"
          />
        )}

        {categorize === "Brands" && (
          <BrandList brandList={brandList} component="campaign" />
        )}

        {categorize === "Rejected Campaigns" && (
          <List
            categorize={categorize}
            campaigns={campaigns}
            component="superadmin"
          />
        )}

        {categorize === "Unverified Campaigns" && (
          <List
            categorize={categorize}
            campaigns={campaigns}
            component="superadmin"
          />
        )}

        {campaigns.length === 0 && (
          <EmptyList title={"No campaigns request."} />
        )}
      </Loader>
    </Fragment>
  );
}
