import { Grid } from "@mui/material";

import { Label, Paper, TextField } from "../../../components";
import FormThree from "./FormThree";

export default function FormTwo({ formikProps, editState }) {
  return (
    <Paper>
      <Grid item xs={12}>
        <Label
          title="Company Directors Name"
          formikProps={formikProps}
          required={true}
          name={"companyDirectorsName"}
        />
      </Grid>

      <Grid item xs={10}>
        <TextField
          name="companyDirectorsName"
          placeholder="Company Directors Name"
          formikProps={formikProps}
          disabled={!editState}
          inputProps={{ "aria-label": "Without label" }}
        />
      </Grid>

      <FormThree formikProps={formikProps} editState={editState} />
    </Paper>
  );
}
