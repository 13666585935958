import { Formik } from "formik";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { Grid, Checkbox, Typography } from "@mui/material";
import { ToggleOffOutlined, ToggleOnOutlined } from "@mui/icons-material";

import pages from "../../../../constants/pages";
import {
  AutocompleteMultiSelect,
  Label,
  Autocomplete,
  TextField,
} from "../../../../components";
import { colors } from "../../../css components/Style";
import { useAxios, useComponent } from "../../../../hooks";

const Heading = styled(Typography)`
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  align-self: center;
`;

export default function AudienceForm({ audienceData, component, formikProps }) {
  const axios = useAxios();
  const navigate = useNavigate();
  const { loader } = useComponent();
  const [fields, setFields] = useState([]);

  useEffect(() => {
    // loader.start(2);
    axios({
      url: "/adtech/getAllDynamicFields",
      method: "GET",
      disableRedirect: true,
    })
      .then((response) => {
        if (response.status) {
          setFields(response.data);
        }
      })
      .catch((err) => console.error(err));
  }, [axios, loader]);

  return (
    <Fragment>
      <Formik enableReinitialize initialValues={audienceData}>
        {(formikProps) => (
          <Fragment>
            <Grid item xs={10}>
              <Label
                title={"Audience Group Name"}
                formikProps={formikProps}
                required={true}
                name={"audience_group.value"}
              />
            </Grid>

            <Grid item xs={10}>
              <TextField
                formikProps={formikProps}
                name="audience_group.value"
                placeholder="Audience Group Name"
                inputProps={{ "aria-label": "Without label" }}
                disabled
              />
            </Grid>

            {fields?.map((field, index) => (
              <Fragment>
                {field.name in formikProps.values && (
                  <Fragment>
                    <Grid item xs={6}>
                      <Label
                        title={field.label}
                        formikProps={formikProps}
                        name={field.name}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={4}
                      textAlign="end"
                      style={{ display: "flex", justifyContent: "end" }}
                    >
                      <Heading
                        style={{
                          color: formikProps.values[field.name]?.mandatory
                            ? "red"
                            : "gray",
                        }}
                      >
                        Mandatory
                      </Heading>

                      <Checkbox
                        disabled
                        name={`${field.name}.mandatory`}
                        checked={formikProps.values[field.name]?.mandatory}
                        icon={<ToggleOffOutlined fontSize="large" />}
                        checkedIcon={
                          <ToggleOnOutlined
                            fontSize="large"
                            style={{ color: "red" }}
                          />
                        }
                      />
                    </Grid>
                  </Fragment>
                )}

                {field.control_id === 3 && field.name in formikProps.values && (
                  <Grid item xs={10}>
                    <TextField
                      formikProps={formikProps}
                      name={`${field.name}.value`}
                      placeholder={field.placeholder}
                      inputProps={{ "aria-label": "Without label" }}
                      disabled
                    />
                  </Grid>
                )}

                {field.control_id === 1 && field.name in formikProps.values && (
                  <Fragment>
                    <Grid item xs={5}>
                      <TextField
                        formikProps={formikProps}
                        name={`${field.name}.value.${field.options[0]["value"]}`}
                        placeholder={field.options[0].placeholder}
                        inputProps={{ "aria-label": "Without label" }}
                        disabled
                      />
                    </Grid>

                    <Grid item xs={5}>
                      <TextField
                        formikProps={formikProps}
                        name={`${field.name}.value.${field.options[1]["value"]}`}
                        placeholder={field.options[1].placeholder}
                        inputProps={{ "aria-label": "Without label" }}
                        disabled
                      />
                    </Grid>
                  </Fragment>
                )}

                {field.control_id === 2 &&
                  field.multi_select &&
                  field.name in formikProps.values && (
                    <Grid item xs={10}>
                      <AutocompleteMultiSelect
                        forcePopupIcon={false}
                        name={`${field.name}.value`}
                        formikProps={formikProps}
                        disabled
                        defaults={{
                          primaryKey: "value",
                          displayLabel: "label",
                        }}
                        options={field.options}
                        placeholder={field.placeholder}
                      />
                    </Grid>
                  )}

                {field.control_id === 2 &&
                  !field.multi_select &&
                  field.name in formikProps.values && (
                    <Grid item xs={10}>
                      <Autocomplete
                        forcePopupIcon={true}
                        name={`${field.name}.value`}
                        placeholder={field.placeholder}
                        defaults={{
                          primaryKey: "value",
                          displayLabel: "label",
                        }}
                        sx={{
                          "& .MuiSvgIcon-root": {
                            display: "none",
                          },
                        }}
                        options={field.options}
                        disabled
                        formikProps={formikProps}
                      />
                    </Grid>
                  )}

                {field.control_id === 4 && field.name in formikProps.values && (
                  <Grid item xs={10}>
                    <DesktopDatePicker
                      name={`${field.name}.value`}
                      formikProps={formikProps}
                      disabled
                      inputProps={{ "aria-label": "Without label" }}
                      value={formikProps.values[field.name]?.value}
                      renderInput={(params) => (
                        <TextField
                          disabled
                          name={`${field.name}.value`}
                          formikProps={formikProps}
                          {...params}
                        />
                      )}
                    />
                  </Grid>
                )}
              </Fragment>
            ))}

            {audienceData?.additionalFields?.map((_, index) => (
              <Fragment>
                <Grid item xs={10}>
                  <Label title={"Additional Field " + (index + 1)} />
                </Grid>

                <Grid item xs={5}>
                  <TextField
                    disabled
                    formikProps={formikProps}
                    name={"additionalFields[" + index + "].key"}
                    placeholder="key"
                    inputProps={{ "aria-label": "Without label" }}
                  />
                </Grid>

                <Grid item xs={5}>
                  <TextField
                    disabled
                    formikProps={formikProps}
                    name={"additionalFields[" + index + "].value"}
                    placeholder="Value"
                    inputProps={{ "aria-label": "Without label" }}
                  />
                </Grid>

                {/* {editState && (
                  <Grid item xs={2} alignSelf={"center"}>
                    <Tooltip title={"Delete additional field" + index}>
                      <IconButton
                        style={{ color: colors.error }}
                        onClick={() => handleDelete(index)}
                      >
                        <DeleteOutline />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )} */}
              </Fragment>
            ))}

            <Grid item xs={10} display={"flex"}>
              <Label
                title={"Non-Mandatory Field Counts"}
                formikProps={formikProps}
              />

              <Heading style={{ color: colors.info }}>
                {formikProps.values.percentage?.value}
              </Heading>
            </Grid>
          </Fragment>
        )}
      </Formik>

      {component === "" && !!formikProps.values.campaignID && (
        <Grid item xs={12}>
          <Typography
            onClick={() =>
              navigate(pages.audienceDetails.route + audienceData.ID)
            }
            style={{ color: "#02a0fc", cursor: "pointer" }}
          >
            Edit Audience Group Details
          </Typography>
        </Grid>
      )}
    </Fragment>
  );
}
