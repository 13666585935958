import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";

import pages from "../../../constants/pages";
import { Label, Paper, TextField } from "../../../components";
import { Fragment } from "react";

const MainHeading = styled(Typography)`
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  color: #02a0fc;
`;

export default function FormOne({ formikProps }) {
  const navigate = useNavigate();

  return (
    <Fragment>
      <Paper>
        <Grid item xs={12}>
          <MainHeading>Your Personal Profile</MainHeading>
        </Grid>

        <Grid item xs={12}>
          <Label title={"Display Name"} />
        </Grid>

        <Grid item xs={10}>
          <TextField
            name="displayName"
            placeholder="Display Name"
            disabled
            formikProps={formikProps}
            inputProps={{ "aria-label": "Without label" }}
          />
        </Grid>

        <Grid item xs={12}>
          <Label title={"Given Name"} />
        </Grid>

        <Grid item xs={10}>
          <TextField
            disabled
            name="givenName"
            placeholder="Given Name"
            formikProps={formikProps}
            inputProps={{ "aria-label": "Without label" }}
          />
        </Grid>

        <Grid item xs={12}>
          <Label title={"Family Name"} />
        </Grid>

        <Grid item xs={10}>
          <TextField
            disabled
            name="familyName"
            placeholder="Family Name"
            formikProps={formikProps}
            inputProps={{ "aria-label": "Without label" }}
          />
        </Grid>

        <Grid item xs={12}>
          <Label title={"Email"} />
        </Grid>

        <Grid item xs={10}>
          <TextField
            disabled
            name="email"
            placeholder="Email"
            formikProps={formikProps}
            inputProps={{ "aria-label": "Without label" }}
          />
        </Grid>

        <Grid item xs={12}>
          <Label title={"Role"} />
        </Grid>

        <Grid item xs={10}>
          <TextField
            placeholder="Role"
            disabled
            name="role"
            formikProps={formikProps}
            inputProps={{ "aria-label": "Without label" }}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography
            color="#02a0fc"
            variant="caption1"
            style={{ cursor: "pointer" }}
            onClick={() => navigate(pages.changePassword.route)}
          >
            Change Password
          </Typography>
        </Grid>
      </Paper>
    </Fragment>
  );
}
