import styled from "styled-components/macro";
import { People } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";

const MainHeading = styled(Typography)`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  color: #ffffff;
`;

const OuterDiv = styled.div`
  background: #34b53a;
  border-radius: 10px;
  padding: 20px 20px 0;
`;

const UserTypography = styled(Typography)`
  color: #fff;
  margin-top: 10px;
  font-size: 60px;
`;

const Snap = styled(Typography)`
  color: #fff;
  margin-top: 10px;
  font-size: 20px;
`;

export default function CampaignLiveUser({ liveUsers }) {
  return (
    <Grid container spacing={2} mt="20px">
      <Grid item xs={12}>
        <OuterDiv>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MainHeading>Users Count</MainHeading>

              {liveUsers.state ? (
                <UserTypography>{liveUsers.liveUser} </UserTypography>
              ) : (
                <Snap>Oh,Snap! , Something went wrong!</Snap>
              )}
            </Grid>

            <Grid item xs={12} textAlign="end" pr="50px">
              <People style={{ fontSize: "60px", color: "#fff" }} />
            </Grid>
          </Grid>
        </OuterDiv>
      </Grid>
    </Grid>
  );
}
