import { Fragment } from "react";
import { Grid } from "@mui/material";
import { useLocation } from "react-router-dom";

import FormOne from "./FormOne";
import FormTwo from "./FormTwo";
import { PageHeader } from "../../../components";
import Buttons from "../../../components/Buttons";

export default function FormDisplay({ formikProps, editState, setEditState }) {
  const { state } = useLocation();

  return (
    <Fragment>
      <Grid item xs={12}>
        <PageHeader
          heading={"Company Details"}
          twoText={true}
          firstText={"These"}
          secondText={"details are for the internal purpose only."}
          fontSize="25px"
          buttons={[
            <Buttons
              state={state}
              ID={formikProps.values.ID}
              editState={editState}
              setEditState={setEditState}
              formikProps={formikProps}
            />,
          ]}
        />
      </Grid>

      <Grid container spacing={8}>
        <Grid item xs={6}>
          <FormOne formikProps={formikProps} editState={editState} />
        </Grid>

        <Grid item xs={6}>
          <FormTwo formikProps={formikProps} editState={editState} />
        </Grid>
      </Grid>
    </Fragment>
  );
}
