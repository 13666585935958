import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components/macro";
import { DialogContent, Dialog, Grid } from "@mui/material";

import { useComponent } from "../../../../hooks";
import { ActiveTextEighteen } from "../../../css components/Style";
import {
  CancelButton,
  PrimaryLoadingButton,
} from "../../../css components/Button";

const Card = styled(Dialog)`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export default function LaunchCampaignConfirm({
  confirm,
  setConfirm,
  campaignID,
  setLaunchCampaign,
}) {
  const dispatch = useDispatch();
  const { axios, alert } = useComponent();

  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setConfirm({ state: false });
  };

  function handleLaunchCampaign() {
    setLoading(true);
    axios({
      url: "user/launchCampaign/",
      method: "POST",
      data: { campaign_id: parseInt(campaignID) },
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        dispatch(alert.success(response.message.displayMessage));
        setLaunchCampaign(false);
      } else {
        setLaunchCampaign(true);
      }
      setLoading(false);
      handleClose();
    });
  }

  return (
    <Card
      open={confirm.state}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "800px!important",
        },
      }}
    >
      <DialogContent>
        <Grid container spacing={3} style={{ padding: "20px" }}>
          <Grid item xs={12} display="flex">
            <ActiveTextEighteen>
              Do you want to launch this campaign?
            </ActiveTextEighteen>
          </Grid>

          <Grid item xs />

          <Grid item>
            <CancelButton variant="outlined" onClick={handleClose}>
              Cancel
            </CancelButton>
          </Grid>

          <Grid item>
            <PrimaryLoadingButton
              variant="contained"
              onClick={handleLaunchCampaign}
              loading={loading}
            >
              Confirm
            </PrimaryLoadingButton>
          </Grid>
        </Grid>
      </DialogContent>
    </Card>
  );
}
