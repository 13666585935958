import { useState } from "react";
import Html5QrcodePlugin from "./Plugin";

const Camera = (props) => {
  const [text, setText] = useState("");
  const onNewScanResult = (decodedText, decodedResult) => {
    setText(decodedText, decodedResult);
  };

  return (
    <div className="App">
      <Html5QrcodePlugin
        fps={10}
        qrbox={250}
        disableFlip={false}
        qrCodeSuccessCallback={onNewScanResult}
      />

      {text}
    </div>
  );
};

export default Camera;
