import { Grid } from "@mui/material";

import { Label, Paper, TextField } from "../../../components";

export default function FormOne({ formData }) {
  return (
    <Paper>
      <Grid item xs={12}>
        <Label title={"Company Name"} />
      </Grid>

      <Grid item xs={10}>
        <TextField
          name="companyName"
          placeholder="Company Name"
          disabled
          value={formData.companyName}
          inputProps={{ "aria-label": "Without label" }}
        />
      </Grid>

      <Grid item xs={12}>
        <Label title="Company Address" />
      </Grid>

      <Grid item xs={10}>
        <TextField
          placeholder="Company Address"
          name="companyAddress"
          rows={5}
          multiline
          disabled
          value={formData.companyAddress}
          inputProps={{ "aria-label": "Without label" }}
        />
      </Grid>

      <Grid item xs={6}>
        <Label title={"Company Contact"} />
      </Grid>

      <Grid item xs={10}>
        <TextField
          name="companyContact"
          placeholder="Company Contact"
          disabled
          value={formData.companyContact}
          inputProps={{ "aria-label": "Without label" }}
        />
      </Grid>

      <Grid item xs={12}>
        <Label title={"Business Nature"} />
      </Grid>

      <Grid item xs={10}>
        <TextField
          name="companyNatureBusiness"
          placeholder="Business Nature"
          disabled
          value={formData.companyNatureBusiness}
          inputProps={{ "aria-label": "Without label" }}
        />
      </Grid>
    </Paper>
  );
}
