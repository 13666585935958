import React, { useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import { Button, Menu, MenuItem } from "@mui/material";

import { titleCase } from "change-case-all";

import { FaVideo, FaImage, FaFile } from "react-icons/fa";
import { BiText, BiBlock } from "react-icons/bi";

import { KeyboardArrowDown } from "@mui/icons-material";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function HeaderButtons({ formikProps, addSample }) {
  const [anchorEl, setAnchorEl] = useState(false);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    var headerType = event.target.getAttribute("value");
    if (Boolean(headerType)) {
      if (headerType === "none") headerType = null;
      formikProps.setValues((prevVal) => ({
        ...prevVal,
        templateHeader: setHeaderType(prevVal.templateHeader),
      }));
      function setHeaderType(templateHeader) {
        if (
          headerType === "image" ||
          headerType === "video" ||
          headerType === "document"
        ) {
          templateHeader.count_of_variable = 1;
        }
        templateHeader.type = headerType;
        return templateHeader;
      }
    } else {
      formikProps.setValues((prevVal) => ({
        ...prevVal,
        templateHeader: setHeaderType(prevVal.templateHeader, prevVal),
      }));
      function setHeaderType(templateHeader, prevVal) {
        if (
          headerType === "image" ||
          headerType === "video" ||
          headerType === "document"
        ) {
          templateHeader.count_of_variable = 1;
        }
        templateHeader = prevVal.templateHeader;
        return templateHeader;
      }
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event, value) => {
    var headerType = event.target.getAttribute("value");
    if (Boolean(headerType)) {
      if (headerType === "none") headerType = null;
      formikProps.setValues((prevVal) => ({
        ...prevVal,
        templateHeader: setHeaderType(prevVal.templateHeader),
      }));
      function setHeaderType(templateHeader) {
        if (
          headerType === "image" ||
          headerType === "video" ||
          headerType === "document"
        ) {
          templateHeader.count_of_variable = 1;
        }
        templateHeader.type = headerType;
        return templateHeader;
      }
    } else {
      formikProps.setValues((prevVal) => ({
        ...prevVal,
        templateHeader: setHeaderType(prevVal.templateHeader, prevVal),
      }));
      function setHeaderType(templateHeader, prevVal) {
        if (
          headerType === "image" ||
          headerType === "video" ||
          headerType === "document"
        ) {
          templateHeader.count_of_variable = 1;
        }
        templateHeader = prevVal.templateHeader;
        return templateHeader;
      }
    }
    setAnchorEl(false);
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="outlined"
        disableElevation
        disabled={Boolean(formikProps.values.ID) || Boolean(addSample)}
        onClick={handleClick}
        endIcon={<KeyboardArrowDown />}
      >
        {Boolean(formikProps.values?.templateHeader?.type)
          ? titleCase(formikProps.values?.templateHeader?.type)
          : "None"}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          value={"text"}
          onClick={(e, v) => handleClose(e, v)}
          disableRipple
        >
          <div style={{ width: "25px" }}>
            <BiText color="#4782da" />
          </div>
          Text
          <br />
          There will be text header in your template.
        </MenuItem>
        <MenuItem value={"video"} onClick={handleClose} disableRipple>
          <div style={{ width: "25px" }}>
            <FaVideo color="#4782da" />
          </div>
          Video
          <br />
          There will be video header in your template.
        </MenuItem>
        <MenuItem value={"image"} onClick={handleClose} disableRipple>
          <div style={{ width: "25px" }}>
            <FaImage color="#4782da" />
          </div>
          Image
          <br />
          There will be image header in your template.
        </MenuItem>
        <MenuItem value={"document"} onClick={handleClose} disableRipple>
          <div style={{ width: "25px" }}>
            <FaFile color="#4782da" />
          </div>
          Document
          <br />
          There will be document header in your template.
        </MenuItem>
        <MenuItem
          value={"none"}
          onClick={(e, v) => handleClose(e, v)}
          disableRipple
          selected
        >
          <div style={{ width: "25px" }}>
            <BiBlock color="#4782da" />
          </div>
          None
          <br />
          There will be no header in your template.
        </MenuItem>
      </StyledMenu>
    </div>
  );
}
