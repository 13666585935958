import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";

import { useAxios } from "../../hooks";
import { Loader } from "../../components";
import Form from "./template details component/Form";
import {
  templateDetailsObject,
  objectFromResponse,
} from "./template details component/templateDetailsObject";
import { apiComplete, startLoader } from "../../redux/slices/loaderSlice";

export default function TemplateDetails() {
  const axios = useAxios({
    baseURL: "cpass_api",
  });
  const params = useParams();
  const dispatch = useDispatch();

  const templateID = params.templateID || "";

  const [category, setCategory] = useState([]);
  const [language, setLanguage] = useState([]);
  const [formData, setFormData] = useState(templateDetailsObject);
  const { agent } = useSelector((state) => state.memberDetails);

  useEffect(() => {
    dispatch(startLoader(4));
    if (Boolean(templateID)) {
      var url = "/template/" + agent[0].agent_id + "?template_id=" + templateID;
      axios({
        method: "GET",
        url: url,
      }).then((response) => {
        if (response.status === true) {
          if (Boolean(templateID)) {
            setFormData(objectFromResponse(response.data));
          }
          dispatch(apiComplete());
        }
      });
    } else {
      dispatch(apiComplete());
    }

    axios({
      method: "GET",
      url: "/template/category",
    }).then((response) => {
      if (response.status === true) {
        setCategory(response.data);
        dispatch(apiComplete());
      }
    });

    axios({
      method: "GET",
      url: "/template/language",
    }).then((response) => {
      if (response.status === true) {
        setLanguage(response.data);
        dispatch(apiComplete(2));
      }
    });
  }, [templateID, axios, dispatch]);

  return (
    <Loader>
      <Form
        formData={formData}
        setFormData={setFormData}
        category={category}
        language={language}
        agentID={"ef660be2f36a487db08e53ac864370ad"}
      />
    </Loader>
  );
}
