import React, { Fragment, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { DeleteOutline } from "@mui/icons-material";
import { Grid, Button, MenuItem, IconButton, Tooltip } from "@mui/material";

import { colors } from "../../../css components/Style";
import { TextField, Label } from "../../../../components";

export default function TemplateCallToActionButton({ formikProps, addSample }) {
  const [validPhoneNumber, setValidPhoneNumber] = useState(false);

  var buttons = {
    button_type: "",
    text: "",
    payload: "",
    url_type: "",
  };

  const handleChangePhoneNumber = (value, country, e, index) => {
    if (value.length <= 3) {
      setValidPhoneNumber(true);
    } else {
      setValidPhoneNumber(false);
    }
    if (
      e.target.className === "country" ||
      e.target.className === "country-name"
    ) {
      formikProps.setValues((prevVal) => ({
        ...prevVal,
        templateButtons: setValidPhoneNumber(prevVal.templateButtons),
      }));
      function setValidPhoneNumber(templateButton) {
        templateButton.buttons[index].payload = "+" + country.dialCode + "";
        return templateButton;
      }
    } else {
      formikProps.setValues((prevVal) => ({
        ...prevVal,
        templateButtons: setValidPhoneNumber(prevVal.templateButtons),
      }));
      function setValidPhoneNumber(templateButton) {
        templateButton.buttons[index].payload = value;
        return templateButton;
      }
    }
  };

  const handleChangeType = (event, index) => {
    var urlType = event.target.value;
    formikProps.setValues((prevVal) => ({
      ...prevVal,
      templateButtons: setTemplateButtons(prevVal.templateButtons),
    }));
    function setTemplateButtons(templateButtons) {
      if (urlType === "static") {
        templateButtons.buttons[index].url_type = urlType;
        templateButtons.buttons[index].payload = "";
        return templateButtons;
      } else if (urlType === "dynamic") {
        templateButtons.buttons[index].url_type = urlType;
        templateButtons.count_of_variable = 1;
        return templateButtons;
      }
    }
  };

  const handleRemoveButtons = (index) => {
    formikProps.setValues((prevVal) => ({
      ...prevVal,
      ...prevVal.templateButtons?.buttons?.splice(index, 1),
    }));
  };

  const addButton = () => {
    formikProps.setValues((prevVal) => ({
      ...prevVal,
      templateButtons: addTemplateButtons(prevVal.templateButtons),
    }));
    function addTemplateButtons(templateButtons) {
      if (Boolean(templateButtons.buttons)) {
        templateButtons.buttons.push(buttons);
        return templateButtons;
      } else {
        templateButtons.buttons = [];
        templateButtons.buttons.push(buttons);
        return templateButtons;
      }
    }
  };
  return (
    <Grid container spacing={2}>
      {formikProps.values?.templateButtons?.buttons?.map((button, index) => (
        <React.Fragment key={index}>
          <Grid item xs={3}>
            <Label
              title={"Action Type"}
              formikProps={formikProps}
              required={true}
              name={"templateButtons.buttons[" + index + "].button_type"}
            />
          </Grid>

          <Grid item xs={3}>
            <Label
              title={"Button Text"}
              formikProps={formikProps}
              required={true}
              name={"templateButtons.buttons[" + index + "].text"}
            />
          </Grid>

          {Boolean(
            formikProps.values.templateButtons?.buttons[index]?.button_type ===
              "url"
          ) && (
            <Grid item xs={3}>
              <Label
                title={"URL Type"}
                formikProps={formikProps}
                required={true}
                name={"templateButtons.buttons[" + index + "].url_type"}
              />
            </Grid>
          )}

          {Boolean(
            formikProps.values.templateButtons?.buttons[index]?.button_type ===
              "phone_number"
          ) && (
            <Grid item xs={3}>
              <Label
                title={"Phone Number"}
                formikProps={formikProps}
                required={true}
                name={"templateButtons.buttons[" + index + "].payload"}
              />
            </Grid>
          )}

          {Boolean(
            formikProps.values.templateButtons?.buttons[index]?.url_type
          ) && (
            <Grid item xs={3}>
              <Label
                title={"URL"}
                formikProps={formikProps}
                required={true}
                name={"templateButtons.buttons[" + index + "].payload"}
              />
            </Grid>
          )}

          {Boolean(
            !formikProps.values.templateButtons?.buttons[index]?.button_type
          ) ? (
            <Grid item xs={6} />
          ) : (
            Boolean(
              formikProps.values.templateButtons?.buttons[index]
                ?.button_type === "phone_number"
            ) && <Grid item xs={3} />
          )}

          <Grid item xs={3}>
            <TextField
              select
              inputProps={{
                "aria-label": "Without label",
              }}
              name={"templateButtons.buttons[" + index + "].button_type"}
              formikProps={formikProps}
              disabled={Boolean(formikProps.values.ID) || Boolean(addSample)}
            >
              <MenuItem
                value="phone_number"
                disabled={formikProps.values?.templateButtons?.buttons?.some(
                  (buttons) => buttons.button_type === "phone_number"
                )}
              >
                Phone Number
              </MenuItem>
              <MenuItem
                value="url"
                disabled={formikProps.values?.templateButtons?.buttons?.some(
                  (buttons) => buttons.button_type === "url"
                )}
              >
                Website
              </MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={3}>
            <TextField
              name={"templateButtons.buttons[" + index + "].text"}
              inputProps={{
                "aria-label": "Without label",
              }}
              formikProps={formikProps}
              disabled={Boolean(formikProps.values.ID) || Boolean(addSample)}
            />
          </Grid>

          {Boolean(
            formikProps.values.templateButtons?.buttons[index]?.button_type ===
              "url"
          ) && (
            <Fragment>
              <Grid item xs={2}>
                <TextField
                  select
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  name={"templateButtons.buttons[" + index + "].url_type"}
                  formikProps={formikProps}
                  onChange={(event) => handleChangeType(event, index)}
                  disabled={
                    Boolean(formikProps.values.ID) || Boolean(addSample)
                  }
                >
                  <MenuItem value="static">Static</MenuItem>
                  <MenuItem value="dynamic">Dynamic</MenuItem>
                </TextField>
              </Grid>
            </Fragment>
          )}

          {Boolean(
            formikProps.values.templateButtons?.buttons[index]?.button_type ===
              "phone_number"
          ) && (
            <Fragment>
              <Grid item xs={5}>
                <PhoneInput
                  value={
                    formikProps.values.templateButtons?.buttons[index]?.payload
                  }
                  onChange={(value, country, e) =>
                    handleChangePhoneNumber(value, country, e, index)
                  }
                  disabled={
                    Boolean(formikProps.values.ID) || Boolean(addSample)
                  }
                  inputProps={{
                    name: "mobileNumber",
                  }}
                  onBlur={formikProps.handleBlur}
                  inputStyle={{
                    width: "100%",
                    fontSize: "12px",
                    color:
                      Boolean(formikProps.values.ID) || Boolean(addSample)
                        ? "rgba(0,0,0,0.38)"
                        : "currentColor",
                    borderColor:
                      Boolean(formikProps.values.ID) || Boolean(addSample)
                        ? "rgba(0,0,0,0.38)"
                        : !validPhoneNumber
                        ? colors.info
                        : "#4076d2",
                    padding: "22.5px 14px 21.5px 58px",
                    cursor: "default",
                  }}
                />
                {validPhoneNumber && (
                  <span className="validationError">Number is Required</span>
                )}
              </Grid>
            </Fragment>
          )}

          {Boolean(
            formikProps.values.templateButtons?.buttons[index]?.url_type
          ) && (
            <Fragment>
              <Grid item xs={3}>
                <TextField
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  name={"templateButtons.buttons[" + index + "].payload"}
                  formikProps={formikProps}
                  disabled={
                    Boolean(formikProps.values.ID) || Boolean(addSample)
                  }
                />
              </Grid>
            </Fragment>
          )}

          {!Boolean(formikProps.values.ID) && (
            <Grid textAlign={"end"} item xs={1}>
              <Tooltip title="Delete button">
                <IconButton
                  disabled={
                    Boolean(formikProps.values.ID) || Boolean(addSample)
                  }
                  onClick={() => handleRemoveButtons(index)}
                  style={{
                    color:
                      Boolean(formikProps.values.ID) || Boolean(addSample)
                        ? "rgba(0,0,0,0.38)"
                        : "#ff0000",
                  }}
                >
                  <DeleteOutline />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </React.Fragment>
      ))}

      {Boolean(formikProps.values?.templateButtons?.type) &&
        !Boolean(formikProps.values.ID) &&
        formikProps.values.templateButtons?.buttons?.length <= 1 && (
          <React.Fragment>
            <Grid item xs={10} />
            <Grid textAlign={"end"} item xs={2}>
              <Button
                disabled={Boolean(formikProps.values.ID) || Boolean(addSample)}
                variant="contained"
                size="small"
                onClick={addButton}
              >
                Add Buttons
              </Button>
            </Grid>
          </React.Fragment>
        )}
    </Grid>
  );
}
