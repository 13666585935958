import { useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";

import { Paper, TextField, Label } from "../../../components";
import { PrimaryLoadingButton } from "../../css components/Button";
import Logo from "../../../assets/auth carousel pics/verismart.png";

export default function FormDisplay({ formikProps }) {
  const navigate = useNavigate();

  return (
    <Paper style={{ width: "650px", margin: "auto" }}>
      <Grid item xs={12} textAlign={"center"}>
        <img style={{ height: "50px" }} src={Logo} alt="logo" />
        <Typography
          style={{
            fontSize: "14px",
            fontWeight: 500,
          }}
        >
          Get your reset link on email.
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Label title={"Enter registered email"} />
      </Grid>

      <Grid item xs={12}>
        <TextField
          required
          name="email"
          formikProps={formikProps}
          inputProps={{ "aria-label": "Without label" }}
          placeholder="Enter registered email"
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="caption1">
          Go Back to{" "}
          <Typography
            variant="button"
            className="info"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/sign-in")}
          >
            Sign In!
          </Typography>
        </Typography>
      </Grid>

      <Grid item xs />

      <Grid item display={"flex"} justifyContent={"end"} alignItems={"center"}>
        <PrimaryLoadingButton
          type="submit"
          variant="contained"
          onClick={formikProps.handleSubmit}
          disabled={!formikProps.isValid}
          loading={formikProps.isSubmitting}
        >
          Reset
        </PrimaryLoadingButton>
      </Grid>
    </Paper>
  );
}
