import React from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components/macro";
import { CssBaseline } from "@mui/material";
import PerfectScrollBar from "react-perfect-scrollbar";

import GlobalStyle from "../components/GlobalStyle";

import Banner from "../assets/auth carousel pics/banner-crop.gif";

const Root = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

const ScrollBar = styled(PerfectScrollBar)`
  margin: 4px;
  height: calc(100% - 10px) !important;
  flex: 1;
`;

const ImageDiv = styled.div`
  flex: 1;
`;

export default function AuthLayout() {
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />

      <ImageDiv id="gif">
        <img
          style={{ width: "100%", height: "100%" }}
          alt="banner"
          src={Banner}
        />
      </ImageDiv>

      <ScrollBar>
        <Outlet />
      </ScrollBar>
    </Root>
  );
}
