import styled from "styled-components";
import { Typography } from "@mui/material";
import { useEffect, useState } from "react";

import SelectRole from "./SelectRole";
import { Loader } from "../../components";
import { useAxios, useComponent } from "../../hooks";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 0 20px;
`;

export default function Welcome() {
  const axios = useAxios();
  const { loader } = useComponent();

  const [brands, setBrands] = useState([]);

  const userID = sessionStorage.getItem("userID");
  const authToken = sessionStorage.getItem("authToken");
  const businessEntityID = sessionStorage.getItem("businessEntityId");

  useEffect(() => {
    loader.start(1);
    axios({
      url: "/brand?businessEntityId=" + businessEntityID,
    }).then((response) => {
      if (response.status) {
        let brandList = [];
        for (const brand of response?.data) {
          brandList.push({
            ID: brand.brand_id,
            about: brand.about,
            brandName: brand.brand_name,
            logo: !!brand.logo
              ? brand.logo
              : "https://dolphinchat-chat.s3.ap-south-1.amazonaws.com/NaN_logo.png",
            languages: brand.languages,
            locations: brand.audience_location,
            category: brand.site_category,
            mau: brand.mau,
            onlineStore: brand.online_store,
            website: brand.website,
            socialMedia: brand.social_media_handles,
            brandType: brand.brand_type_id,
          });
        }

        setBrands(brandList);
        loader.apiComplete();
      }
    });
  }, [axios, businessEntityID, loader]);

  return (
    <Wrapper>
      <div className="nav-div">
        <Typography className="choose-brand">
          Please choose your preferred brand and indicate your role for seamless
          processing of your selection.
        </Typography>
      </div>

      <Loader height={"75%"}>
        <SelectRole
          brands={brands}
          authToken={authToken}
          businessEntityID={businessEntityID}
          userID={userID}
        />
      </Loader>
    </Wrapper>
  );
}
