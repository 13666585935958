/*
Contains all the routes pertaining to Dashboard Layout
Use the following structure: 
export const {name}Route = {
  ID: "",
  path: "/",
  element: "",
  index: true, (if the path is default for some folder)
}
*/

//Misc Pages
import pages from "../../constants/pages";
import BrandDetails from "../../pages/brand components/BrandDetails";
import CompanyDetails from "../../pages/company components/CompanyDetails";
import PermissionError from "../../pages/misc/PermissionError";
import Welcome from "../../pages/welcome page components/Welcome";
import WelcomeUnverified from "../../pages/welcome page components/WelcomeUnverified";

const emptyLayoutRoutes = {
  //create brand page

  welcomePageRoute: {
    ID: pages.welcomePage.ID,
    path: pages.welcomePage.route,
    element: Welcome,
  },

  welcomePageUnverifiedRoute: {
    ID: pages.createWelcomePage.ID,
    path: pages.createWelcomePage.route,
    element: WelcomeUnverified,
  },

  companyDetailsCreateEmptyRoute: {
    ID: pages.companyDetailsCreateEmpty.ID,
    path: pages.companyDetailsCreateEmpty.route,
    element: CompanyDetails,
  },

  companyDetailsEmptyRoute: {
    ID: pages.companyDetailsEmpty.ID,
    path: pages.companyDetailsEmpty.route,
    element: CompanyDetails,
  },

  createBrandEmptyRoute: {
    ID: pages.brandDetailCreateEmpty.ID,
    path: pages.brandDetailCreateEmpty.route,
    element: BrandDetails,
  },

  createBrandDetilsEmptyRoute: {
    ID: pages.brandDetailEmpty.ID,
    path: pages.brandDetailEmpty.route,
    element: BrandDetails,
  },

  permissionRoute: {
    ID: pages.permissionDenied.ID,
    path: pages.permissionDenied.route,
    element: PermissionError,
  },
};

export default emptyLayoutRoutes;
