import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import { Fragment, useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import React from "react"; // Import React if not already imported

import { Label, Paper, Autocomplete } from "../../../components";
import { useAlert, useAxios } from "../../../hooks";

const MainHeading = styled(Typography)`
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  color: #02a0fc;
`;

export default function FormEighth({ formikProps, editState }) {
  const axios = useAxios();
  const { alert } = useAlert();
  const dispatch = useDispatch(); // Fix typo: Change `disptach` to `dispatch`

  const [templateDetails, setTemplateDetails] = useState([]);
  const { activeBrand, agent } = useSelector((state) => state.memberDetails);

  useEffect(() => {
    axios({
      url:
        "/GetAllTemplates?brand_id=" +
        (formikProps.values.brandID
          ? formikProps.values.brandID
          : activeBrand.ID),
      method: "GET",
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setTemplateDetails(response.data.templates);
      } else {
        dispatch(
          alert({
            message: response.message,
            type: "error",
          })
        );
      }
    });
  }, [axios, formikProps.values.brandID, activeBrand.ID, dispatch, alert]);

  function handleChangeAudience(value, name, primaryKey) {
    formikProps.setValues((prevVal) => ({
      ...prevVal,
      [name]: {
        value: value[primaryKey],
      },
    }));
  }

  return (
    <Fragment>
      <Paper style={{ marginTop: "25px" }}>
        <Grid item xs={12}>
          <MainHeading>Select WhatsApp Agent</MainHeading>
        </Grid>

        <Fragment>
          <Grid item xs={12}>
            <Label
              title={"WhatsApp Agent Details"}
              formikProps={formikProps}
              required={true}
              name={"whatsapp_template"}
              select={true}
            />
          </Grid>

          <Grid item xs={10}>
            <Autocomplete
              forcePopupIcon={!editState ? false : true}
              name="whatsapp_agent.value"
              placeholder="WhatsApp Agent Details"
              defaults={{
                primaryKey: "agent_id",
                displayLabel: "mobile_number",
              }}
              sx={{
                "& .MuiSvgIcon-root": {
                  display: !editState && "none",
                },
              }}
              options={agent}
              disabled={!editState}
              onChange={(_, value, type) =>
                handleChangeAudience(value, "whatsapp_agent", "agent_id")
              }
              formikProps={formikProps}
            />
          </Grid>
        </Fragment>
      </Paper>

      <Paper style={{ marginTop: "25px" }}>
        <Grid item xs={12}>
          <MainHeading>Select WhatsApp Template</MainHeading>
        </Grid>

        <Fragment>
          <Grid item xs={12}>
            <Label
              title={"WhatsApp Template Details"}
              formikProps={formikProps}
              required={true}
              name={"whatsapp_template"}
              select={true}
            />
          </Grid>

          <Grid item xs={10}>
            <Autocomplete
              forcePopupIcon={!editState ? false : true}
              name="whatsapp_template.value"
              placeholder="WhatsApp Template Details"
              defaults={{
                primaryKey: "id",
                displayLabel: "name",
              }}
              sx={{
                "& .MuiSvgIcon-root": {
                  display: !editState && "none",
                },
              }}
              options={templateDetails}
              disabled={!editState}
              onChange={(_, value, type) =>
                handleChangeAudience(value, "whatsapp_template", "id")
              }
              formikProps={formikProps}
            />
          </Grid>
        </Fragment>
      </Paper>
    </Fragment>
  );
}
